<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-input :value="value" type="textarea" :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)" :rows="rows === undefined ? 5 : rows" :disabled="disabled" @input="v => { this.$emit('input', v); this.$emit('on-change', v); }" />
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';

export default Base.extend({
  name: 'NFieldTextarea',
  props: {
    value: String,
    rows: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    isModified() {
      return this.showModifyIcon && this.initValue !== this.value;
    },
  },
  created() {
    this.initValue = this.value;
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
