<template>
  <n-modal v-bind="$props" :custom-title="$t('label.operatorSetting')" :width="700">
    <Form ref="modalForm" :model="modelData" :rules="rules" :label-width="120" @submit.native.prevent>
      <!-- prettier-ignore -->
      <Tabs type="card" :animated="false">
        <TabPane :label="$t('label.basic')" name="basic" class="n-modal-container">
          <n-row-col2>
            <n-field-number v-model="modelData.maxTabSize" field-name="maxTabSize" :error="error.maxTabSize" label="tabLimit" placeholder="maxTabSizePlaceholder" :disabled="disabled" />
            <n-field-select v-model="modelData.pageSize" :options="pageOptions" label="pageSize" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane v-if="hasOperatorNoticeReadPrivilege" :label="$t('label.notice')" name="notice" class="n-modal-container">
          <n-row-col2>
            <n-field-boolean v-model="modelData.popup" label="popup" true-label="on" false-label="off" :disabled="disabled" />
            <n-field-boolean v-model="modelData.sound" label="sound" true-label="on" false-label="off" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>

<script>
import _ from 'lodash-es';
import NModal from '@/components/common/NModal';
import { mapGetters, mapMutations } from 'vuex';
import { DEFAULT_PAGE_OPTIONS } from '@/mixins/paginate-mixin';
import { shouldInRange, shouldBeInteger } from '@/helpers/validate';

export default NModal.extend({
  name: 'ModalSetting',
  components: { NModal },

  data() {
    return {
      modelData: {
        popup: true,
        sound: true,
        maxTabSize: 10,
        pageSize: this.$store.state.pageSize,
      },

      rules: {
        maxTabSize: [shouldInRange(1, 16), shouldBeInteger],
      },

      pageOptions: DEFAULT_PAGE_OPTIONS.map(v => ({ name: v, value: v })),
    };
  },

  mounted() {
    const { preference } = this.$store.state;
    _.merge(this.modelData, preference);
  },

  computed: {
    ...mapGetters('menu', ['hasOperatorNoticeReadPrivilege']),
  },

  methods: {
    ...mapMutations('preference', ['setPreference']),

    doValidate() {
      return Promise.resolve({ type: 'SUCCESS' });
    },

    doSubmit() {
      const size = +this.modelData.maxTabSize;
      this.modelData.maxTabSize = size;
      this.setPreference(this.modelData);
      return Promise.resolve({ type: 'SUCCESS' });
    },
  },
});
</script>
