const isDevelopment = process.env.NODE_ENV === 'development';

export function isJsError(err) {
  return err instanceof Error;
}

// unexpected
export function jsErrorHandler(err, vm, info) {
  if (!isJsError(err)) return true;

  console.error(err, vm, info);
  vm.$Sentry?.captureException(err);

  // Internal Error, only show in development. WON'T show in production
  if (isDevelopment) {
    // vm.$Message.error({ content: err.message } ?? { content: 'Unknown Error' });
  }
  return false;
}
