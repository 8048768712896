<template>
  <div class="n-field-step-decimal" :class="{ 'step-disabled': disabled }">
    <n-field-decimal :class="{ 'hide-label': hideLabel }" v-bind="attrs" v-on="listeners" />
    <div class="steps">
      <div class="step-up" :class="{ disabled: reachMax }" @click="onStepUp">
        <Icon type="ios-arrow-up" />
      </div>
      <div class="step-down" :class="{ disabled: reachMin }" @click="onStepDown">
        <Icon type="ios-arrow-down" />
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import { Decimal } from 'decimal.js';
import { numberScale, clearDecimalZero } from '@/helpers/number';
import { adds, subs } from '@/helpers/big-number';
import { isEmpty } from '@/helpers/utils';

export default {
  name: 'NFieldStepNumber',

  props: {
    nScale: Number,
    disabled: Boolean,
    min: { type: [String, Number], default: undefined },
    max: { type: [String, Number], default: undefined },
    step: { type: [String, Number], default: 0 },
  },

  data() {
    return {
      isFocus: false,
      emitValue: null,
    };
  },

  computed: {
    attrs() {
      return {
        ...this.$attrs,
        value: this.isFocus ? this.emitValue : this.$attrs.value,
        noModifiedIcon: true,
        disabled: this.disabled,
      };
    },

    listeners() {
      return {
        ...this.$listeners,
        'on-focus': this.onFocus,
        'on-blur': this.onBlur,
        input: this.onInput,
      };
    },

    hideLabel() {
      return !this.$attrs.label && !this.$attrs.customLabel;
    },

    reachMin() {
      return this.min !== undefined && this.min === this.$attrs.value;
    },

    reachMax() {
      return this.max !== undefined && this.max === this.$attrs.value;
    },
  },

  watch: {
    min() {
      const value = this.getMinValue(this.$attrs.value);
      if (value !== this.$attrs.value) this.$emit('input', value);
    },

    max() {
      const value = this.getMaxValue(this.$attrs.value);
      if (value !== this.$attrs.value) this.$emit('input', value);
    },
  },

  methods: {
    getMinValue(value) {
      value = value instanceof BigNumber ? value : new BigNumber(value);
      const v = value.lt(this.min) ? this.min : value.toString();
      return numberScale(v, this.nScale, Decimal.ROUND_HALF_UP, true);
    },

    getMaxValue(value) {
      value = value instanceof BigNumber ? value : new BigNumber(value);
      const v = value.gt(this.max) ? this.max : value.toString();
      return numberScale(v, this.nScale, Decimal.ROUND_HALF_UP, true);
    },

    onFocus() {
      this.isFocus = true;
      this.emitValue = this.$attrs.value;
      this.$emit('focus');
    },

    onBlur() {
      this.isFocus = false;
      if (isEmpty(this.emitValue) && !isEmpty(this.min))
        this.$emit('input', clearDecimalZero(numberScale(this.min, this.nScale, Decimal.ROUND_HALF_UP, true)));
      else {
        const ret = this.getMaxValue(this.getMinValue(this.emitValue));
        this.$emit('input', clearDecimalZero(ret));
      }
      this.$emit('blur');
    },

    onInput(v) {
      this.emitValue = v;
      if (this.isFocus) return;
      const ret = this.getMaxValue(this.getMinValue(v));
      this.$emit('input', ret);
    },

    onStepUp() {
      if (this.disabled && this.step !== 0) return;
      const value = adds(this.$attrs.value, this.step);
      const ret = clearDecimalZero(this.getMaxValue(value));
      this.$emit('input', ret);
      this.$emit('step-change', { side: 1, value: ret });
    },

    onStepDown() {
      if (this.disabled && this.step !== 0) return;
      const value = subs(this.$attrs.value, this.step);
      const ret = clearDecimalZero(this.getMinValue(value));
      this.$emit('input', ret);
      this.$emit('step-change', { side: -1, value: ret });
    },
  },
};
</script>

<style lang="less">
.n-field-step-decimal {
  position: relative;

  .hide-label {
    label.ivu-form-item-label {
      display: none;
    }

    .ivu-form-item-content {
      margin-left: 0 !important;
      padding-left: 0;
    }
  }

  input {
    padding-right: 28px;
  }

  .steps {
    position: absolute;
    height: 32px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 22px;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 4px 4px 0;
    font-size: 8px;
    cursor: pointer;

    .step-up,
    .step-down {
      width: 100%;
      height: 50%;
      flex-wrap: wrap;

      i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        color: #007bff;
        font-size: 15px;
        font-weight: bold;
      }

      &.disabled {
        color: #ccc;
        cursor: not-allowed !important;
      }
    }

    .step-up {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &.step-disabled {
    .steps {
      cursor: not-allowed;

      .step-up,
      .step-down {
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
</style>
