import Vue from 'vue';
import _ from 'lodash-es';
import { isEmpty } from '@/helpers/utils';

export default Vue.extend({
  inject: {
    nModal: {
      from: 'nModal',
      default: null,
    },
  },
  props: {
    fieldName: String,
    label: String,
    customLabel: String,
    placeholder: String,
    autocomplete: String,
    disabled: Boolean,
    error: String,
    noModifiedIcon: Boolean,
  },
  data() {
    return {
      initValue: null,
      modifiedIcon: false,
    };
  },
  computed: {
    isCreate() {
      return _.get(this.nModal, 'isCreate', false);
    },
    isEdit() {
      return _.get(this.nModal, 'isEdit', false);
    },
    isPreview() {
      return _.get(this.nModal, 'preview', false);
    },
    isDetail() {
      return _.get(this.nModal, 'detail', false);
    },
    showModifyIcon() {
      return this.isEdit && this.isPreview && this.modifiedIcon;
    },
  },
  mounted() {
    if (!isEmpty(this.nModal) && !this.noModifiedIcon) this.modifiedIcon = true;
  },
});
