/**
 * @Author Ken
 * @CreateDate 2020-09-03 10:50
 * @desc
 * <X show /> : true
 * <X show='abc' /> : true
 * <X :show='true' /> : true
 * <X :show='false' /> : false
 * @params Vue Component this.$attrs & key
 * @return
 */
export function getBoolean(target = {}, key, defaultValue = false) {
  if (target.hasOwnProperty(key)) {
    return target[key] !== false;
  } else {
    return defaultValue;
  }
}
