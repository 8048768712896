<template>
  <!-- prettier-ignore -->
  <Form ref="sc" label-position="right" class="search-condition" :style="{ display: 'grid', gridTemplateRows: `repeat(${rows}, 42px)`, gridTemplateColumns: `repeat(${cols}, ${gridTemplateColumns})`, gridRowGap: '10px', gridColumnGap: '12px' }">
    <slot />
    <n-button v-if="searchable" size="large" custom-icon="fas fa-search" text="search" :loading="loading" class="n-search-btn" :style="{ gridRowStart: rows, gridRowEnd: rows + 1, gridColumnStart: cols, gridColumnEnd: cols + 1 }" @on-click="$emit('do-search')" />
  </Form>
</template>
<script>
import { isEmpty } from '@/helpers/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash-es';

export default {
  name: 'SearchCondition',
  props: {
    labelWidth: {
      type: Number,
      default: 0,
    },
    more: Object, // {items: [4, 5, 6, 7], expand: false}
    searchable: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      count: 0,
      rows: 0,
      cols: 0,
      gridTemplateColumns: 0,
    };
  },
  computed: {
    ...mapGetters('layout', ['collapsed', 'innerWidth']),
    isExpand() {
      if (isEmpty(this.more)) return true;
      return this.more.expand;
    },
  },
  watch: {
    collapsed() {
      this.init();
    },
    innerWidth() {
      this.init();
    },
    isExpand() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // count
      if (isEmpty(this.more) || this.more.expand) this.count = this.$refs.sc.$children.length;
      else this.count = this.$refs.sc.$children.length - this.more.items.length;

      // cols
      let contentEl = document.getElementsByClassName('p-content-card')[0];
      let offsetWidth = contentEl.offsetWidth;
      let clientWidth = contentEl.clientWidth;
      let deviation = offsetWidth - clientWidth;
      let width = offsetWidth - 40 - deviation;
      if (width >= 1100) this.cols = 4;
      else if (width >= 820) this.cols = 3;
      else if (width >= 560) this.cols = 2;
      else this.cols = 1;

      // rows
      this.rows = Math.ceil(this.count / this.cols);

      // row expand
      let items = this.$refs.sc.$children.filter(f => f.$children[0].$el.className.indexOf('n-search-btn') === -1);
      if (!isEmpty(this.more)) {
        _.forEach(items, (item, i) => {
          if (this.more.items.find(f => f === i)) {
            if (this.more.expand) {
              item.$children[0].$el.style.display = '';
            } else {
              item.$children[0].$el.style.display = 'none';
            }
          }
        });
      }

      // row first item label
      let displayItems = items.filter(f => f.$children[0].$el.style.cssText !== 'display: none;');
      _.forEach(displayItems, (item, i) => {
        if (i % this.cols === 0) {
          item.$children[0].$el.classList.add('n-first-item');
        } else item.$children[0].$el.classList.remove('n-first-item');
      });

      // gridTemplateColumns
      this.gridTemplateColumns = this.cols > this.count ? `${(width - 12 * (this.cols - 1)) / this.cols}px` : 'auto';
    },
  },
};
</script>

<style lang="scss">
.search-condition {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;

  .ivu-form-item {
    margin-bottom: 0;

    .ivu-form-item-label {
      width: 35%;
    }

    .ivu-form-item-content {
      width: 65%;
      min-width: 168px;
      margin-left: 35%;
    }
  }

  .n-search-btn {
    width: 100px;
    justify-self: end;
  }

  .n-first-item .ivu-form-item-label {
    text-align: left;
  }
}
</style>
