<template>
  <n-field-select v-bind="attrs" v-on="listeners" />
</template>

<script>
import _ from 'lodash-es';
import BaseCompany from './BaseCompany';
import { getSelection } from '@/store';
import { filter, map } from 'lodash-es';

const SELECTION_NAME = 'company.product';
export default BaseCompany.extend({
  name: 'NsProduct',
  props: {
    mobileType: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    attrs() {
      return {
        label: 'product',
        options: this.options,
        ...this.$attrs,
      };
    },
    options() {
      return map(
        filter(getSelection(SELECTION_NAME), item => item.extra.$group === this.companyId && item.extra.$group1 === this.mobileType),
      );
    },
  },

  watch: {
    mobileType(to, from) {
      let value = this.$attrs.value;
      // Ken 2019-12-06 10:38 当不强制更新时, 则优先从options里查找, 找到了, 则不变化
      if (!this.isForceUpdate && _.find(this.options, { value })) {
        return;
      }
      if (this.allOption) {
        // 置为All
        value = 0;
      } else {
        // 置为options第一个
        value = _.get(this.options, '[0].value', 0);
      }

      this.$emit('input', value);
      if (value !== this.$attrs.value) {
        this.$emit('on-change', value);
      }
    },
  },
});
</script>
