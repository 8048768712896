<template>
  <Tooltip :content="title" class="nav-action nav-action-setting">
    <div
      :class="[
        'fas',
        isConnected || isReconnecting ? 'fa-link' : 'fa-unlink text-danger',
        isPing ? 'ws-indicator' : '',
        isReconnecting ? 'ws-reconnecting' : '',
      ]"
    ></div>
  </Tooltip>
</template>

<script>
import Vue from 'vue';
import i18n from '@/locale/i18n';
import { realtime, STATE } from '@nextop/admin-utils';

export default Vue.extend({
  data() {
    return {
      isPing: false,
      isConnected: realtime.state === STATE.OPEN,
      isReconnecting: false,
      pingTimer: null,
    };
  },

  computed: {
    title() {
      const status = this.isConnected ? 'ws_online' : 'ws_offline';
      return i18n.t(`tooltip.${this.isReconnecting ? 'ws_reconnecting' : status}`);
    },
  },

  created() {
    realtime.on('open', this.openHandler);
    realtime.on('ping', this.pingHandler);
    realtime.on('reconnect', this.reconnectHandler);
  },

  beforeDestroy() {
    this.isPing = false;
    this.isConnected = false;
    this.isReconnecting = false;

    clearTimeout(this.pingTimer);

    realtime.off('open', this.openHandler);
    realtime.off('ping', this.pingHandler);
    realtime.off('reconnect', this.reconnectHandler);
  },

  methods: {
    openHandler() {
      this.isConnected = true;
      this.isReconnecting = false;
    },

    pingHandler() {
      this.isPing = true;
      this.pingTimer = setTimeout(() => {
        this.isPing = false;
      }, 500);
    },

    reconnectHandler() {
      this.isConnected = false;
      this.isReconnecting = true;
    },
  },
});
</script>

<style lang="scss" scoped>
$green1: #52c41a;
$green2: #237804;
$yellow1: #fffb8f;
$yellow2: #d4b106;

.ws-indicator {
  -webkit-animation: sparkle 0.5s ease-in-out 1;
  animation: sparkle 0.5s ease-in-out 1;
}

.ws-reconnecting {
  -webkit-animation: reconnecting 0.5s ease-in-out 1;
  animation: reconnecting 0.5s ease-in-out 1;
  animation-iteration-count: infinite;
}

@-webkit-keyframes sparkle {
  0% {
    color: $green1;
  }
  100% {
    color: $green2;
  }
}

@keyframes sparkle {
  0% {
    color: $green1;
  }
  100% {
    color: $green2;
  }
}

@-webkit-keyframes reconnecting {
  0% {
    color: $yellow1;
  }
  100% {
    color: $yellow2;
  }
}

@keyframes reconnecting {
  0% {
    color: $yellow1;
  }
  100% {
    color: $yellow2;
  }
}
</style>
