import qs from 'qs';
import _ from 'lodash-es';
import axios from 'axios';
import { routerTo } from '@/router';
import { isEmpty } from '@/helpers/utils';
import { LEVEL, RESULT } from '@/constant/enums';
import { BOOTSTRAP_URL } from '@/api/utility/utility';

const config = {
  withCredentials: true,
  baseURL: '/rest',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-version': window.X_VERSION ? window.X_VERSION : 'NA',
  },
};
const request = axios.create(config);
Object.assign(axios.defaults, config);

const ERROR = {
  UNAUTHORIZED: 'error.unauthorized',
  UN_TRACK: 'error.login.password.incorrect',
  502: 'message.error.502',
};

function onResolve(showErrorMsg = true) {
  return async response => {
    let ret;
    // eslint-disable-next-line
    const {
      data,
      config: { url, baseURL },
    } = response;
    let { type, level, key = '' } = data;
    switch (type) {
      case RESULT.MESSAGE:
        if (LEVEL.ERROR === level && ERROR.UNAUTHORIZED === key) {
          // Ken 2020-01-16 11:50 goto login page if this is bootstrap request, otherwise will show error message
          if (url === baseURL + BOOTSTRAP_URL) {
            routerTo('/login');
            // return to prevent from error message showing
            return;
          }
        }
        // if (LEVEL.ERROR === level) {
        //   if (showErrorMsg) {
        //     key = key.toLowerCase();
        //     // Ken 2019-11-28 01:22 有些key自动带error.的前缀
        //     const label = key.startsWith('error.') ? key : 'error.' + key;
        //     NMessage.error(i18n.t(label));
        //   }
        // }
        ret = response;
        break;
      case RESULT.CSV:
        ret = response;
        break;
      case RESULT.REPORT:
        ret = response;
        break;
      case RESULT.DATA:
        ret = data.data;
        break;
      case RESULT.SUCCESS:
        ret = data;
        break;
      case RESULT.VALIDATION:
        ret = data;
        break;
      case RESULT.APPLICATION_JSON:
        throw await new Response(data).json(); // report download failed, resource not found?
      default:
        /**
         * For csv download, content-type: text/csv; charset=MS932
         *   In Chrome/Firefox/Edge, type = text/csv
         *   In Safari, type = text/csv; charset=MS932
         * So we need to handle it
         * */
        if (type.includes(RESULT.CSV)) {
          ret = response;
        } else {
          console.warn(`Unhandled response, type = ${type}`);
        }
        break;
    }
    return ret;
  };
}

function onReject(error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        routerTo('/login');
        break;
      case 500:
        routerTo('/login');
        break;
      case 502:
        routerTo('/login');
        break;
      default:
        routerTo('/login');
        break;
    }
  }

  let rejectData;
  if (error.message === 'Network Error') {
    rejectData = { key: 'error.network' };
  } else if (error.code === 'ECONNABORTED') {
    rejectData = error;
  } else if (error.response && error.response.data) {
    rejectData = error.response.data;
  } else {
    routerTo('/service/unavailable');
    rejectData = error.response;
  }
  return Promise.reject(rejectData);
}

/** Axios response interceptor */
axios.interceptors.response.use(onResolve(), onReject);

/** Axios response interceptor */
request.interceptors.response.use(onResolve(false), onReject);

/**
 * GET base method
 * @param url
 * @param params (not necessary)
 * @param strict (whether pass 0 or undefined, default true)
 */
export function $fetch(url, params, strict = true) {
  if (strict) {
    params = paramFilter(params);
  }
  return !isEmpty(params) ? axios.get(`${url}?${qs.stringify(params)}`) : axios.get(url);
}

/**
 * Ken 2019-11-25 15:37
 *  no enhanced error handler
 * */
export const $request = {
  get: function(url, params) {
    params = paramFilter(params);
    return !isEmpty(params) ? request.get(`${url}?${qs.stringify(params)}`) : request.get(url);
  },
  post: function(url, data) {
    data = data ? qs.stringify(data) : null;
    return request.post(url, data);
  },
};

/**
 * POST base method
 * @param url
 * @param data (not necessary)
 */
export function $post(url, data) {
  data = data ? qs.stringify(data) : null;
  return axios.post(url, data);
}

/**
 * PUT base method
 * @param url
 * @param data (not necessary)
 */
export function $put(url, data) {
  data = data ? qs.stringify(data) : null;
  return axios.put(url, data);
}

/**
 * DELETE base method
 * @param url
 */
export function $delete(url) {
  return axios.delete(url);
}

export function paramFilter(params = {}) {
  const ret = {};
  Object.keys(params).forEach(key => {
    let value = params[key];
    // Ken 2019-12-06 14:35 统一处理数组
    if (_.isArray(value)) {
      value = _.join(value, ',');
    }
    if (!isEmpty(value) && value !== 0) {
      ret[key] = value;
    }
  });
  return ret;
}
