<template>
  <!-- prettier-ignore -->
  <Row :gutter="50">
    <i-col><slot /></i-col>
  </Row>
</template>

<script>
export default {
  name: 'NRowCol1',
};
</script>

<style lang="scss" scoped>
@import 'style';
</style>
