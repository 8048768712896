<template>
  <div style="position: relative;">
    <Tooltip v-if="type === 'number'" :content="errorMsg" placement="right" :disabled="toolTipDisabled">
      <n-field-decimal
        :value="value"
        class="p-number"
        :no-modified-icon="noModifiedIcon"
        :scale="scale"
        :disabled="disabled"
        @input="v => $emit('input', v)"
      />
    </Tooltip>
    <div v-if="!toolTipDisabled" class="p-error" />
  </div>
</template>

<script>
export default {
  name: 'NTableNumber',
  props: {
    errorMsg: String,
    errorDisabled: Boolean,
    noModifiedIcon: {
      type: Boolean,
      default: true,
    },
    value: [String, Number],
    scale: Number,
    disabled: Boolean,
    type: {
      type: String,
      default: 'number',
      validator: v => ['text', 'number'].includes(v),
    },
  },
  computed: {
    toolTipDisabled() {
      return this.errorDisabled || !this.errorMsg;
    },
  },
};
</script>

<style scoped lang="scss">
.p-number {
  margin: 0;

  ::v-deep .n-input-item-icon {
    width: 100%;
  }
}

.p-error {
  position: absolute;
  left: -5px;
  top: -2px;
  width: 0;
  height: 0;
  border-top: 5px solid red;
  border-right: 5px solid transparent;
}
</style>
