<template>
  <div id="n-panel-box">
    <compoment :is="item.name" v-for="item in list" :id="item.id" :key="item.id" :extra="item.extra"></compoment>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'NPanelBox',

  computed: {
    ...mapState('panel', ['list']),
  },
};
</script>
