<template>
  <!-- prettier-ignore -->
  <Dropdown transfer trigger="click" :placement="placement" :transfer-class-name="isUserInfo? 'user-info-dropdown' : ''" @on-click="v => { this.$emit('on-click', v); }">
    <a v-if="type === 'text'" href="javascript:void(0)" :style="{ color: n_color, padding }" :disabled="disabled">
      <Icon :type="n_icon" :custom="n_customIcon" :disabled="disabled" :style="{ marginRight: (text !== undefined && text !== '') || (customText !== undefined && customText !== '') ? '4px' : '' }" />
      {{ text !== undefined && text !== '' ? $t(`label.${text}`) : customText !== undefined && customText !== '' ? customText : '' }}
      <Icon type="ios-arrow-down"></Icon>
    </a>
    <Button v-else :type="type" :size="n_size" :class="type === 'text' ? 'dropdown-text buttonClass' : 'buttonClass'" :ghost="ghost" :disabled="disabled">
      {{ text !== undefined && text !== '' ? $t(`label.${text}`) : customText !== undefined && customText !== '' ? customText : '' }}
      <Icon type="ios-arrow-down"></Icon>
    </Button>
    <DropdownMenu slot="list">
      <DropdownItem v-for="item in items" :key="item.key" :name="item.key" :style="{ color: item.color }" :disabled="item.disabled">
        <Row v-if="itemSelected">
          <Icon :type="item.selected ? 'md-checkmark' : ''" style="min-width: 14px;" />
          <span v-if="item.text !== undefined && item.text !== ''" class="pl-4">{{ $t(`label.${item.text}`) }}</span>
          <span v-else-if="item.customText !== undefined && item.customText !== ''" class="pl-4">{{ customText }}</span>
        </Row>
        <Row v-else-if="itemIcon">
          <Icon :type="item.icon !== undefined ? item.icon : ''" :custom="item.customIcon !== undefined ? item.customIcon : ''" style="min-width: 14px;" />
          <span v-if="item.text !== undefined && item.text !== ''" class="pl-4">{{ $t(`label.${item.text}`) }}</span>
          <span v-else-if="item.customText !== undefined && item.customText !== ''" class="pl-4">{{ customText }}</span>
        </Row>
        <span v-else>{{ item.text !== undefined && item.text !== '' ? $t(`label.${item.text}`) : item.customText !== undefined && item.customText !== '' ? item.customText : '' }}</span>
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
</template>

<script>
import Base from '../base';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NDropdown',
  props: {
    customText: String,
    color: String,
    items: Array,
    padding: String,
    placement: {
      type: String,
      default: 'bottom-end',
    },
    isUserInfo: Boolean,
  },
  data() {
    return {
      n_icon: '',
      n_customIcon: '',
      n_color: '#464c5b',
    };
  },
  computed: {
    itemSelected() {
      if (isEmpty(this.items)) return false;
      return this.items.filter(v => v.selected).length > 0;
    },
    itemIcon() {
      if (isEmpty(this.items)) return false;
      return this.items.filter(v => !isEmpty(v.icon) || !isEmpty(v.customIcon)).length > 0;
    },
  },
  watch: {
    type() {
      this.init();
    },
    text() {
      this.init();
    },
    color() {
      this.init();
    },
    icon() {
      this.init();
    },
    customIcon() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.type === 'text') {
        /* icon */
        if (!isEmpty(this.icon)) this.n_icon = this.icon;
        else this.n_icon = '';
        /* customIcon */
        if (!isEmpty(this.customIcon)) this.n_customIcon = this.customIcon;
        else this.n_customIcon = '';
        /* color */
        if (!isEmpty(this.color)) this.n_color = this.color;
        else this.n_color = '#464c5b';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
