<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="200" @submit.native.prevent>
      <!-- prettier-ignore -->
      <Tabs type="card" name="modalGroup" :animated="false">
        <TabPane tab="modalGroup" name="basic" :label="this.$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <!-- name -->
            <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
            <!-- status -->
            <n-field-enum v-model="model.status" field-name="status" enum-name="GroupStatus" :error="error.status" label="status" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- companyId -->
            <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" :error="error.companyId" label="company" :disabled="isEdit || disabled" />
            <!-- version -->
            <n-field-id v-model="model.version" label="version" disabled />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalGroup" name="restriction" :label="this.$t('label.restriction')" class="n-modal-container">
          <n-row-col2>
            <!-- loginRestriction -->
            <n-field-enum v-model="model.loginRestriction" field-name="loginRestriction" enum-name="LoginRestriction" :error="error.loginRestriction" label="loginRestriction" :disabled="disabled" />
            <!-- tradingRestriction -->
            <n-field-enum v-model="model.tradingRestriction" field-name="tradingRestriction" enum-name="TradingRestriction" :error="error.tradingRestriction" label="tradingRestriction" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- editingRestriction -->
            <n-field-enum v-model="model.editingRestriction" field-name="editingRestriction" enum-name="EditingRestriction" :error="error.editingRestriction" label="editingRestriction" :disabled="disabled" />
            <!-- transferRestriction -->
            <n-field-enum v-model="model.transferRestriction" field-name="transferRestriction" enum-name="TransferRestriction" :error="error.transferRestriction" label="transferRestriction" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import NModal from '@/components/common/NModal';
import { getSelectionByCompanyId } from '@/store';
import { CURRENCY_SCALE_NAME } from '@/constant/enums';
import { getLeverageSequence } from '@nextop/admin-utils';
import { validate, edit, add } from '@/api/company/company-group';
import { setLeverageValue, setCurrencyScale } from '@/helpers/operators';

export default NModal.extend({
  name: 'ModalCompanyGroup',
  components: { NModal },

  beforeMount() {
    // for leverage field edit icon
    this.$set(this.model, 'groupId', this.model.id); // setCurrencyScale use groupId
    setLeverageValue(this.model);
    setCurrencyScale(CURRENCY_SCALE_NAME.DEPOSIT)(this.model);
  },

  methods: {
    doSubmit(model) {
      this.$delete(this.model, 'groupId'); // Skipping URI variable warning for server side
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },

    selectLeverage(selected) {
      let r = 0;
      let sequence = getLeverageSequence(selected);
      let mask = 1 << sequence;
      r |= mask;
      this.model.leverages = r;
    },
  },

  data() {
    return {
      freeMarginRatioScale: 2,
      hedgedMarginRatioScale: 2,
      marginRatioWarningScale: 2,
      marginRatioLosscutScale: 2,
    };
  },

  computed: {
    ...mapGetters('profile', ['isSuper']),
    leverageOptions: function() {
      return getSelectionByCompanyId('company.leverage', this.model.companyId);
    },
  },
});
</script>
