<template>
  <div>
    <Modal
      :width="width"
      :value="visible"
      :draggable="false"
      :fullscreen="false"
      :closable="closable"
      :mask-closable="false"
      :transition-names="[]"
      :class="cancelDisabled ? 'cancel-disabled' : ''"
      :styles="styles"
      @on-cancel="close"
    >
      <p slot="header">
        <span class="n-sub-head">{{ title }}</span>
        <Icon v-if="!closable" type="ios-close" class="n-modal-close" size="30" @click="close" />
      </p>
      <slot></slot>
      <div slot="footer">
        <slot v-if="$slots.footer" name="footer"></slot>
        <div v-else>
          <n-button v-show="readonly" type="info" size="large" icon="md-close" text="close" @on-click="close" />
          <n-button
            v-show="!readonly && !preview"
            type="default"
            size="large"
            icon="md-close"
            text="cancel"
            :disabled="cancelDisabled"
            @on-click="close"
          />
          <!-- prettier-ignore -->
          <n-button v-show="!readonly && !preview" type="info" size="large" icon="md-checkmark-circle-outline" text="preview" :loading="loading" :disabled="previewDisabled" @on-click="validate" />
          <n-button
            v-show="!readonly && preview"
            type="default"
            size="large"
            icon="ios-arrow-back"
            text="back"
            @on-click="back"
          />
          <!-- prettier-ignore -->
          <n-button v-show="!readonly && preview" type="error" size="large" icon="md-send" :text="submitBtnText" :loading="loading" :disabled="saveDisabled" @on-click="submit" />
        </div>
      </div>
    </Modal>
    <div class="p-mask" />
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash-es';
import { mapGetters } from 'vuex';
import { isEmpty } from '@/helpers/utils';
import { ResultMixin, PrivilegeMixin } from '@/mixins';
import { SUCCESS_MUTE } from '@/constant/config';
import { findComponentsDownward } from '@/helpers/component';

export default Vue.extend({
  name: 'NModal',

  mixins: [ResultMixin, PrivilegeMixin],

  provide() {
    return {
      nModal: this,
    };
  },

  props: {
    //
    model: Object,
    width: { type: Number, default: 900 },
    detail: Boolean,
    subName: String,
    visible: Boolean,
    customMsg: String,
    customTitle: String,
    saveDisabled: Boolean,
    cancelDisabled: Boolean,
    previewDisabled: Boolean,
    // default support ESC key close
    escapable: { type: Boolean, default: undefined },
    autoClose: { type: Boolean, default: true },
    labelWidth: { type: Number, default: 160 },
    submitBtnText: { type: String, default: 'save' },
    privilegeSensitive: { type: Boolean, default: true }, // for change password modal
  },

  data() {
    return {
      error: {},
      payload: {},
      preview: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('access', ['activeRoute']),

    autoClosable: function() {
      let error = this.error;
      let autoClose = this.autoClose;
      return autoClose && isEmpty(error);
    },

    closable: function() {
      let isEdit = this.isEdit;
      let isCreate = this.isCreate;
      let escapable = this.escapable;
      return !!escapable || !isCreate;
    },

    readonly: function() {
      return this.privilegeSensitive ? (this.hasRO && !this.hasRW) || this.detail : false;
    },

    disabled() {
      return this.detail || this.preview || this.readonly;
    },

    isEdit: function() {
      return _.get(this.model, 'id') > 0;
    },

    isCreate: function() {
      return !this.isEdit;
    },

    title: function() {
      if (this.customTitle) return this.customTitle;
      //
      let subName = this.subName;
      let name = subName ? this.$t(`label.${subName}`) : this.$t(`menu.${this.activeRoute.name}`);
      if (this.readonly) {
        return this.$t(`modal.detail`, { name });
      } else if (this.isEdit) {
        return this.$t(`modal.edit`, { name });
      } else {
        return this.$t(`modal.add`, { name });
      }
    },

    styles: function() {
      return { maxWidth: 'calc(100% - 20px)' };
    },
  },

  watch: {
    saveDisabled(to) {
      if (to) this.loading = false;
    },
  },

  methods: {
    // This is VUE anti-pattern
    // Sync property state between base modal instance and sub modal instance.
    _sync(property, value) {
      this[property] = value;
      this.$parent[property] = value;
    },

    reset() {
      this.loading = false;
      this._sync('error', {});
      this._sync('preview', false);
      if (this.$parent.rules) {
        this.$parent.$refs['modalForm'].resetFields();
      }
    },

    close() {
      this.reset();
      this.$parent.$emit('modal-close');
    },

    back() {
      this._sync('error', {});
      this._sync('preview', false);
      let onBack = this.$listeners['on-back'];
      if (onBack) {
        onBack();
      }
    },

    getTabPanes() {
      return _.filter(
        _.get(this, '$children[0].$slots.default[0].componentInstance.$children[0].$children'),
        tab => tab.$options._componentTag === 'TabPane',
      );
    },

    setTabError(switchToFirstErrorTab = true) {
      // Ken 2019-11-26 12:12 前两个是back, forward, 从第三个开始是真正的tab
      const tabPanes = this.getTabPanes();
      let autoFocused = false;
      _.forEach(tabPanes, (tabPane, i) => {
        const items = findComponentsDownward(tabPane, 'FormItem');
        const errorCount = _.size(_.filter(items, item => item.error));
        const tabDom = tabPane.$el.parentElement.parentElement.querySelectorAll('.ivu-tabs-tab')[i];
        if (errorCount > 0) {
          tabDom.style.color = 'red';
          if (!autoFocused && switchToFirstErrorTab) {
            tabDom.click();
            autoFocused = true;
          }
        } else {
          tabDom.style.removeProperty('color');
        }
        // Ken 2019-11-26 14:18 切换tab会重置class, 所以先不考虑class方案
        // const actionName = errorCount > 0 ? 'add' : 'remove';
        // tabDom.classList[actionName]('n-tab-error');
      });
    },

    localValidate() {
      return this.$parent.rules ? this.$parent.$refs['modalForm'].validate() : Promise.resolve(true);
    },

    validate() {
      if (this.loading) return;

      const doValidate =
        this.listeners && this.$listeners['on-validate'] ? this.$listeners['on-validate'] : this.$parent.doValidate;
      this.localValidate().then(isValid => {
        if (!isValid) {
          console.error('isValid', isValid, this.model);
        } else {
          if (doValidate) {
            this.loading = true;
            //
            doValidate(this.model)
              .then(r => {
                if (this.isSuccess(r)) {
                  this._sync('error', {});
                  this._sync('preview', true);
                } else if (this.isValidation(r)) {
                  // merge validation and set errors
                  let error = this.merge(r);
                  this._sync('error', error);
                  this.$nextTick(() => this.setTabError());
                } else {
                  this.showErrorMsg(r);
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
        // Ken 2019-11-26 14:34 处理本地检验
        if (!doValidate) {
          this.$nextTick(() => {
            this.setTabError();
          });
        }
      });
    },

    submit() {
      if (this.loading) return;

      let doSubmit = this.listeners && this.$listeners['on-submit'] ? this.$listeners['on-submit'] : this.$parent.doSubmit;
      if (doSubmit) {
        //
        this.loading = true;
        let params = isEmpty(this.payload) ? this.model : this.payload;
        let autoClosable = this.autoClosable;
        doSubmit(params)
          .then(r => {
            if (this.isSuccess(r)) {
              if (!SUCCESS_MUTE) {
                this.$Message.success(this.customMsg);
              }
            } else if (this.isValidation(r)) {
              // guoqiang: BAL-1067
              this.back();
              this._sync('error', this.merge(r));
              this.$nextTick(() => this.setTabError());
              autoClosable = false;
            } else {
              this.showErrorMsg(r);
              autoClosable = false;
            }
          })
          .finally(() => {
            this.loading = false;
            if (autoClosable) {
              this.close();
              this.$parent.$emit('load');
            }
          });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal-body {
  padding: 12px;
}

.n-modal-fullscreen {
  float: right;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 8px;
}

.n-modal-close {
  float: right;
  cursor: pointer;
  margin-top: 9px;
  margin-right: 16px;
}

.cancel-disabled .n-modal-close {
  display: none;
}

::v-deep .ivu-modal-header {
  margin: 0;
  padding: 0;
  height: 46px;
}

::v-deep .ivu-modal-header p {
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
}

// Ken 2019-12-12 18:31 透明层(有些modal, 如System/Digest detail, 是在modal内ajax get数据, 等数据得到后, 才显示modal, 会有一定的延迟
// 在延迟时间内, 如果用户快速的点击, 可以弹出两个modal, 所以用此透明层罩住
// Ken 2020-01-08 12:11 Update: 目前Modal全局只能有一个, 所以此方案无效了
.p-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000; // same as iview modal default z-index
}
</style>
