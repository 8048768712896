<template>
  <div :class="[{ 'n-info': clickable }, 'n-ellipsis']">
    <span :class="{ 'n-link': clickable }" :title="label" @click.stop="clickable ? onClick() : null">
      {{ label }}
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { find } from 'lodash-es';
import { getSelection } from '@/store';
import { hasReadPrivilege } from '@/store/module/menu';

let METAS = {
  'dealing.panel': { menu: 'dealing.panel', selection: 'company.symbol', handler: 'createDealingPanel' },
};

export default {
  name: 'NPanelLink',

  props: {
    value: [String, Number],
    params: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    name: { type: String, default: 'dealing.panel' },
  },

  computed: {
    panel() {
      return METAS[this.name];
    },

    selections() {
      return getSelection(this.panel?.selection);
    },

    label() {
      let key = this.panel?.selection;
      let label = key ? find(this.selections, s => s.value === this.value)?.name : this.value;
      if (!label || label === '0') label = '-';
      return label;
    },

    hasRO() {
      return this.panel ? hasReadPrivilege(this.panel.menu) : false;
    },

    clickable() {
      return !this.disabled && this.hasRO && +this.value !== 0 && this.label !== '-';
    },
  },

  methods: {
    ...mapActions('panel', ['createDealingPanel']),

    onClick() {
      const handler = this.panel.handler;
      if (typeof this?.[handler] === 'function') {
        this?.[handler](this.params);
      }
    },
  },
};
</script>
