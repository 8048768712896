import i18n from '@/locale/i18n';

export class MessageModel {
  constructor({ type, config, extra, isReport = false }) {
    this.type = type;
    this.config = config;
    this.extra = extra;
    this.isReport = isReport;
  }
}

export class WarnMessage extends MessageModel {
  constructor(config, extra) {
    super({ type: 'warn', config, extra });
  }
}

export class RequiredMessage extends WarnMessage {
  constructor(text) {
    super({ content: i18n.t('warn.required', { name: i18n.t('label.' + text) }) });
  }
}

export class ErrorMessage extends MessageModel {
  constructor(config, extra, isReport = false) {
    super({ type: 'error', config, extra, isReport });
  }
}
