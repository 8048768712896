export default {
  /* error */
  error: {
    invalid: 'Invalid',
    overlap: 'Overlap',
    expired: 'Expired',
    timeout: 'Service Timeout',
    unknown: 'Internal Error',
    rejected: 'Service Rejected',
    overflow: 'Overflow',
    required: 'Required',
    readonly: 'Read Only',
    underflow: 'Underflow',
    not_found: 'Resource Not Found',
    overlength: 'Over Length',
    duplicated: 'Duplicated',
    restricted: 'Service Restricted',
    server_busy: 'Server is busy',
    no_privilege: 'No Privilege',
    unauthorized: 'Unauthorized Access',
    incompatible: 'Incompatible',
    intact_order: 'Intact Order',
    invalid_swap: 'Invalid Swap',
    inter_mission: 'Intermission',
    type_mismatch: 'Type Mismatch',
    stale_version: 'Stale Data',
    invalid_order: 'Invalid Order',
    invalid_group: 'Invalid Group',
    internal_error: 'Internal Error',
    invalid_symbol: 'Invalid Symbol',
    invalid_product: 'Invalid Product',
    invalid_account: 'Invalid Account',
    invalid_session: 'Invalid Session',
    invalid_request: 'Invalid Request',
    service_timeout: 'Service Timeout',
    invalid_trigger: 'Invalid Trigger',
    account_required: 'Account Required',
    invalid_currency: 'Invalid Currency',
    service_rejected: 'Service Rejected',
    invalid_contract: 'Invalid Contract',
    invalid_execution: 'Invalid Execution',
    invalid_deviation: 'Invalid Deviation',
    invalid_liquidity: 'Invalid Liquidity',
    invalid_portfolio: 'Invalid Portfolio',
    overflow_deviation: 'Overflow Deviation',
    invalid_allocation: 'Invalid Allocation',
    resource_not_found: 'Resource Not Found',
    service_restricted: 'Service Restricted',
    insufficient_funds: 'Insufficient Funds',
    must_choose_master: 'Must Choose Master',
    underflow_deviation: 'Underflow Deviation',
    insufficient_margin: 'Insufficient Margin',
    total_share_invalid: 'Sum must be equal to 1',
    service_unavailable: 'Service Unavailable',
    untradable_deviation: 'Untradable Deviation',
    invalid_counterparty: 'Invalid Counterparty',
    insufficient_liquidity: 'Insufficient Liquidity',
    invalid_company_cas_url: 'Invalid Company CAS URL.',
    password: {
      mismatch: 'Password mismatch.',
      incorrect: 'Invalid login ID or Password.',
      invalid_length: 'Password length invalid [6-16]',
      almost_expire: 'Password is about to expire in {n} days.',
    },
    editable: {
      required: 'This field is required',
      number_required: 'Number required',
    },
    // 以上是与后端同步的, 以下是前端定义的
    scaleOverflow: 'Scale Overflow',
    network: 'Network Error',
  },

  /* validation */
  validation: {
    invalid: 'Invalid',
    required: 'Required',
    mustJson: 'Must be json',
    notInRange: 'Not in range: {start} ~ {end}',
    notInteger: 'Not integer',
    validationError: 'Validation Error',
  },

  /* notice */
  notice: {
    success: {
      title: 'Success',
      content: 'Execution Succeed.',
    },
    fail: {
      title: 'Failure',
      content: 'Execution failed',
    },
    warning: {
      title: 'Warning',
    },
    modified: '{name} successfully modified',
  },

  message: {
    success: 'Success',
    failure: 'Failure',
    tabLimitation: 'Reach the limitation: {count} tabs',
    invalidFile: 'Invalid file',
  },

  warn: {
    warning: 'Warning',
    required: '{name} Required',
    reachDealingPanelCountLimitation: 'Reach the limitation: {count} panels',
    exceedMaxBridgingVolume: 'Exceed max bridging volume: {volume}',
    invalidVolumeScale: 'Invalid volume(scale <= {scale})',
    invalidVolume: 'Invalid volume(volume >= {step})',
    invalidVolumeStep: 'Invalid volume(multiple of {step})',
  },

  /* modal */
  modal: {
    add: 'Add {name}',
    edit: 'Edit {name}',
    detail: '{name} Detail',
    subscriptions: '{name} Subscriptions',
  },

  /* poptip */
  poptip: {
    accept: 'Are you sure to accept?',
    reject: 'Are you sure to reject?',
    confirm: 'Are you sure to {action}? ',
    remove: 'Are you sure to remove {id}?',
    allocate: 'Are you sure to allocate {id}?',
  },

  tooltip: {
    ws_online: 'WebSocket Connected',
    ws_offline: 'WebSocket Connection broken',
  },
};
