export default {
  menu: {
    lab: {
      self: 'Lab',
      document: 'Document',
      example: 'Example',
    },
    market: {
      self: 'Market',
      icon: 'System Icon',
      market: 'Market',
      country: 'Country',
      currency: 'Currency',
    },
    company: {
      self: 'Company',
      news: 'News',
      group: 'Group',
      mobile: 'Mobile',
      product: 'Product',
      company: 'Company',
      category: 'Category',
      assignment: 'Assignment',
      translation: 'Translation',
      landing_page: 'Landing Page',
    },
    operator: {
      self: 'Operator',
      menu: 'Menu',
      role: 'Role',
      notice: 'Notice',
      notice_template: 'Template',
      operator: 'Operator',
      privilege: 'Privilege',
      operator_config: 'Operator Config',
      operator_notice: 'Operator Notice',
      operator_access: 'Operator Access',
      operator_session: 'Operator Session',
    },
    monetization: {
      self: 'Monetization',
    },
    transaction: {
      self: 'Transaction',
    },
    system: {
      self: 'System',
      alert: 'Alert',
      batch: 'Batch',
      debug: 'Debug',
      config: 'Config',
      digest: 'Digest',
      monitor: 'Monitor',
      status: 'Status',
      service: 'Service',
      applications: 'Application',
    },

    customer: {
      self: 'Customer',
      profile: 'Profile',
      account: 'Account',
      config: 'Config',
      customer_mail: 'Customer Email',
      customer_order: 'Customer Order',
      customer_offer: 'Customer Offer',
      customer_message_device: 'Device',
      customer_budget: 'Customer Budget',
      customer_report: 'Customer Report',
      customer_report_summary: 'Summary',
      customer_access: 'Customer Access',
      customer_mail_template: 'Template',
      customer_session: 'Customer Session',
      customer_message: 'Customer Message',
      customer_message_template: 'Template',
      customer_category: 'Customer Category',
      customer_transaction: 'Customer Transaction',
    },
  },
};
