import Vue from 'vue';
/* button */
import NbAdd from './button/NbAdd';
import NbSort from './button/NbSort';
import NbLock from './button/NbLock';
import NbMute from './button/NbMute';
import NbPanel from './button/NbPanel';
import NbMore from './button/NbMore';
import NbModal from './button/NbModal';
import NbReset from './button/NbReset';
import NbCustom from './button/NbCustom';
import NbRefresh from './button/NbRefresh';
import NbConfirm from './button/NbConfirm';
import NbSetting from './button/NbSetting';
import NbSchedule from './button/NbSchedule';
import NbDownload from './button/NbDownload';
import NbDropdown from './button/NbDropdown';
import NbIconConfirm from './button/NbIconConfirm';
import NbDropdownAdd from './button/NbDropdownAdd';
/* select */
import NsCurrency from './select/NsCurrency';
import NsBr from './select/NsBr';
import NsPp from './select/NsPp';
import NsGroup from './select/NsGroup';
import NsProduct from './select/NsProduct';
import NsCompany from './select/NsCompany';
/* poptip */
import NpInput from './poptip/NpInput';
import NpSelect from './poptip/NpSelect';
import NpBoolean from './poptip/NpBoolean';
/* image */
import NImage from './NImage';
import NImageDoc from './NImageDoc';
/* modal link */
import NModalLink from './NModalLink';
/* table */
import NTable from './NTable';
import NTableNumber from './table/NTableNumber';
/* search condition */
import SearchCondition from './SearchCondition';
/* refresh info */
import NRefreshInfo from './NRefreshInfo';
/* panel */
import NPanel from './panel/NPanel';
import NPanelBox from './panel/NPanelBox';
/* modal */
import NMenuModal from './NMenuModal';
/* NHelpPopover */
import NHelpPopover from './NHelpPopover';
/* tooltip */
import NtField from './tooltip/NtField';
import './NNumber';
import NTextEditor from './NTextEditor';
import NPanelLink from './NPanelLink';
import NTreeTable from './NTreeTable';

export const components = {
  /* Button */
  NbAdd,
  NbSort,
  NbLock,
  NbMute,
  NbMore,
  NbPanel,
  NbModal,
  NbReset,
  NbCustom,
  NbRefresh,
  NbConfirm,
  NbSetting,
  NbSchedule,
  NbDownload,
  NbDropdown,
  NbIconConfirm,
  NbDropdownAdd,
  /* select */
  NsCurrency,
  NsBr,
  NsPp,
  NsGroup,
  NsCompany,
  NsProduct,
  /* poptip */
  NpInput,
  NpSelect,
  NpBoolean,
  /* image */
  NImage,
  NImageDoc,
  /* modal link */
  NModalLink,
  /* table */
  NTable,
  NTableNumber,
  /* search condition */
  SearchCondition,
  /* refresh info */
  NRefreshInfo,
  /* panel */
  NPanel,
  NPanelBox,
  /* modal */
  NMenuModal,
  /* NHelpPopover */
  NHelpPopover,
  /* tooltip */
  NtField,
  NTextEditor,
  NPanelLink,
  NTreeTable,
};

for (const key in components) {
  Vue.component(key, components[key]);
}
