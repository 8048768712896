<template>
  <span :class="{ 'p-error': refreshError || response.refreshError }">
    <i class="far fa-fw fa-clock"></i> {{ `${refreshTime || response.refreshTime || Date.now()}` | datetime }}
  </span>
</template>

<script>
export default {
  props: {
    refreshTime: [String, Number],
    refreshError: {
      type: Boolean,
      default: false,
    },
    response: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import '~@/style/color.scss';

span {
  color: #212529;
}

.p-error {
  color: $color-red;
}
</style>
