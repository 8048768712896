import _ from 'lodash-es';
import { realtime } from '@nextop/admin-utils';
import EventBus from '../eventbus';
import { CoverageChannel } from '@/services';
import { WindowMessage, InitDataMessage, MSG } from './WindowMessage';

export function bind(WindowManager) {
  if (!WindowManager.isMainWindow) {
    return;
  }

  EventBus.$on(MSG.GET_DEALING_PANEL_INIT_DATA, params => {
    const windowObj = _.find(WindowManager.list, { handler: params._source });
    if (!windowObj) {
      console.error('does not find sub window', params);
      return;
    }
    WindowManager.send(params._source, new InitDataMessage());
  });

  EventBus.$on(MSG.WEBSOCKET_ACT, ({ action, channel, params }) => {
    realtime[action]?.(channel, params);
  });

  EventBus.$on(MSG.SUB_WINDOW_CLOSE, params => {
    const windowObj = _.find(WindowManager.list, { handler: params._source });
    if (windowObj?.onCoverage) {
      console.error('coverage is still subscribing coverage');
    }
    _.remove(WindowManager.list, { handler: params._source });

    // TODO: Ken 会trigger一连串的 SUB_WINDOW_CLOSE 消息
    setTimeout(() => {
      params._source?.close();
    }, 0);
  });

  EventBus.$on(MSG.SUBSCRIBE_COVERAGE, params => {
    const windowObj = _.find(WindowManager.list, { handler: params._source });
    if (!windowObj) {
      console.error('does not find sub window', params);
      return;
    }
    if (!windowObj.onCoverage) {
      windowObj.onCoverage = function(data) {
        WindowManager.send(params._source, new WindowMessage('ON_COVERAGE', data));
      };
    }
    CoverageChannel.subscribe({ book: params.book, symbol: params.symbol }, windowObj.onCoverage);
  });

  EventBus.$on(MSG.UNSUBSCRIBE_COVERAGE, params => {
    const windowObj = _.find(WindowManager.list, { handler: params._source });
    if (!windowObj) {
      console.error('does not find sub window', params);
      return;
    }
    if (windowObj.onCoverage) {
      CoverageChannel.unsubscribe({ book: params.book, symbol: params.symbol }, windowObj.onCoverage);
      delete windowObj.onCoverage;
    }
  });
}
