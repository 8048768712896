<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" @submit.native.prevent>
      <Tabs name="customerAccount" type="card" :animated="false">
        <TabPane tab="customerAccount" name="basic" :label="$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <n-field-id v-model="model.id" field-name="id" label="accountId" :error="error.id" disabled />
            <ns-company v-model="model.companyId" :error="error.companyId" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" disabled />
            <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-enum v-model="model.status" field-name="status" enum-name="CustomerAccountStatus" label="status" :error="error.status" disabled />
            <n-field-selection v-model="model.currency" field-name="currency" selection-name="market.currency" :error="error.currency" label="currency" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-id v-model="model.sourceId" label="sourceId" disabled />
            <n-field-selection v-model="model.icon" field-name="icon" selection-name="market.icon" :error="error.icon" label="icon" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="customerAccount" name="detail" :label="$t('label.detail')" class="n-modal-container">
          <n-row-col2>
            <!-- balance -->
            <n-field-decimal :value="model.balance" :scale="model.currencyScale" label="balance" disabled />
            <!-- tradingDate -->
            <n-field-date :value="model.tradingDate" label="tradingDate" disabled />
          </n-row-col2>
          <n-row-col2>
            <!-- frzBalance -->
            <n-field-decimal :value="model.frzBalance" :scale="model.currencyScale" label="frzBalance" disabled />
            <!-- fltBalance -->
            <n-field-decimal :value="model.fltBalance" :scale="model.currencyScale" label="fltBalance" disabled />
          </n-row-col2>
          <n-row-col2>
            <!-- ytdBalance -->
            <n-field-decimal :value="model.ytdBalance" :scale="model.currencyScale" label="ytdBalance" disabled />
            <!-- dbyBalance -->
            <n-field-decimal :value="model.dbyBalance" :scale="model.currencyScale" label="dbyBalance" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-date v-model="model.openingDate" field-name="openingDate" label="openingDate" :error="error.openingDate" :disabled="true" />
            <n-field-datetime v-model="model.openingTime" field-name="openingTime" label="openingTime" :error="error.openingTime" :disabled="true" />
          </n-row-col2>
          <n-row-col2>
            <n-field-date v-model="model.closingDate" field-name="closingDate" label="closingDate" :error="error.closingDate" :disabled="true" />
            <n-field-datetime v-model="model.closingTime" field-name="closingTime" label="closingTime" :error="error.closingTime" :disabled="true" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="customerAccount" name="restriction" :label="$t('label.restriction')" class="n-modal-container">
          <n-row-col2>
            <n-field-enum v-model="model.loginRestriction" field-name="loginRestriction" enum-name="LoginRestriction" label="loginRestriction" :error="error.loginRestriction" :disabled="disabled" />
            <n-field-enum v-model="model.tradingRestriction" field-name="tradingRestriction" enum-name="TradingRestriction" label="tradingRestriction" :error="error.tradingRestriction" :disabled="disabled" />

          </n-row-col2>
          <n-row-col2>
            <n-field-enum v-model="model.editingRestriction" field-name="editingRestriction" enum-name="EditingRestriction" label="editingRestriction" :error="error.editingRestriction" :disabled="disabled" />
            <n-field-enum v-model="model.transferRestriction" field-name="transferRestriction" enum-name="TransferRestriction" label="transferRestriction" :error="error.transferRestriction" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { update, validate } from '@/api/customer/customer-account';

export default NModal.extend({
  name: 'ModalCustomerAccount',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return update(model);
    },
  },
});
</script>
