<template>
  <n-button v-rw v-bind="attrs" :loading="loading" ghost @on-click="onClick" />
</template>

<script>
import { isFunction, isUndefined } from 'lodash-es';
import ICON from '@/constant/icon';

export default {
  name: 'NbAdd',
  props: {
    size: String,
    icon: String,
    long: Boolean,
    ghost: Boolean,
    disabled: Boolean,
    text: { type: String, default: 'add' },
    type: { type: String, default: 'error' },
    customIcon: { type: String, default: 'fas fa-plus' },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    attrs() {
      return {
        ...this.$props,
        customIcon: isUndefined(this.customIcon) ? ICON[this.text] || ICON.info : this.customIcon,
      };
    },
  },
  methods: {
    onClick() {
      if (this.loading) return;
      const cb = this.$listeners['on-click'];
      if (!isFunction(cb)) return;

      this.loading = true;
      const ret = cb() || {};
      if (isFunction(ret.finally)) {
        ret.finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
