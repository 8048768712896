/**
 * Ken 2019-11-27 16:49
 *
 * 设计思路:
 *   coSymbol, itSymbol 数据从selection里都可以取到, 但会每次都会重复循环查找
 *   在table里这么用, 就会有多层循环, 所以这里做一层hash映射
 */

import Vue from 'vue';
import { getSelectionGetter } from '@/store';
import { toCoSymbolId, toItSymbolId } from '@nextop/admin-utils';
import _ from 'lodash-es';

// Ken 2019-12-18 16:00 为了能响应式的同步selection的变化
const Map = new Vue({
  name: 'ScaleMap',
  computed: {
    currencyScaleMap() {
      const map = {};
      const currencyExtraMap = getSelectionGetter('currencyExtraMap');
      _.keys(currencyExtraMap).forEach(key => {
        map[key] = currencyExtraMap[key].scale;
      });
      return map;
    },
    depositCurrencyScaleMap() {
      const map = {};
      const groupExtraMap = getSelectionGetter('groupExtraMap');
      _.keys(groupExtraMap).forEach(key => {
        const groupExtra = groupExtraMap[key];
        map[key] = this.currencyScaleMap[groupExtra.depositCurrency];
      });
      return map;
    },
    companyCurrencyScaleMap() {
      const map = { assessCurrency: {}, bridgeCurrency: {} };
      const companyExtraMap = getSelectionGetter('companyExtraMap');
      _.keys(companyExtraMap).forEach(key => {
        const companyExtra = companyExtraMap[key];
        map.assessCurrency[key] = this.currencyScaleMap[companyExtra.assessCurrency];
        map.bridgeCurrency[key] = this.currencyScaleMap[companyExtra.bridgeCurrency];
      });
      return map;
    },
  },
});

/**
 * baseCurrency | profitCurrency
 * */
export function getCurrencyScale(currencyId) {
  const scale = Map.currencyScaleMap[currencyId];
  return scale === undefined ? -1 : scale;
}

/**
 * Group:
 * depositCurrency
 * */
export function getGroupDepositCurrencyScale(groupId) {
  const scale = Map.depositCurrencyScaleMap[groupId];
  return scale === undefined ? -1 : scale;
}

/**
 * Company:
 * assessCurrency, bridgeCurrency
 * */
export function getCompanyCurrencyScale(key, companyId) {
  const scale = Map.companyCurrencyScaleMap[key][companyId];
  return scale === undefined ? -1 : scale;
}
