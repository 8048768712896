<template>
  <div :style="style">
    <div class="box" :class="levelText">
      {{ levelText }}
    </div>
  </div>
</template>

<script>
import { evaluatePassword } from '@/api/utility/utility';
import { debounce } from 'lodash-es';
import { isEmpty } from '@/helpers/utils';

export default {
  name: 'PasswordStrengthMeter',
  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      level: 0,
      evaluate: null,
    };
  },
  computed: {
    levelText() {
      let text = '';
      if (this.level >= 9) {
        text = 'strong';
      } else if (this.level >= 5) {
        text = 'medium';
      } else if (this.level > 0) {
        text = 'weak';
      }
      return text;
    },
    style() {
      return {
        marginTop: !isEmpty(this.levelText) && isEmpty(this.error) ? '-13px' : '0',
        marginRight: '0',
        marginBottom: !isEmpty(this.levelText) ? '15px' : '10px',
        marginLeft: `${this.labelWidth}px`,
      };
    },
  },
  watch: {
    value() {
      if (this.value.length === 0) {
        this.level = 0;
      } else {
        this.evaluate();
      }
    },
  },
  mounted() {
    this.evaluate = debounce(() => {
      evaluatePassword({ password: this.value }).then(level => {
        this.level = level;
      });
    }, 50);
  },
};
</script>

<style scoped lang="scss">
@import '~@/style/color.scss';
.box {
  background-color: $color-red;
  transition: background-color 0.3s, width 0.3s;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
}
.weak {
  width: 33%;
  background-color: $color-red;
}
.medium {
  width: 66%;
  background-color: $color-yellow;
}
.strong {
  width: 100%;
  background-color: $color-green;
}
</style>
