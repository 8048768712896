<template>
  <n-button-icon :custom-icon="value ? 'fa fa-volume-off' : 'fa fa-volume-up'" ghost style="width: 35px;" @on-click="onClick" />
</template>

<script>
export default {
  name: 'NbMute',
  props: {
    value: Boolean,
  },
  methods: {
    onClick() {
      let v = !this.value;
      this.$emit('input', v);
      this.$emit('on-change', v);
    },
  },
};
</script>
