<template>
  <Poptip transfer confirm :title="popTitle" :disabled="loading" placement="top-end" @on-ok="onOk">
    <Icon v-bind="attrs" style="cursor: pointer; vertical-align: baseline;" />
  </Poptip>
</template>

<script>
import { I18N_POPTIP } from '@/locale/i18n';
import { isFunction } from 'lodash-es';

export default {
  name: 'NbIconConfirm',
  props: {
    type: String,
    size: String,
    custom: String,
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    attrs() {
      return {
        ...this.$props,
      };
    },
    popTitle() {
      return this.title || this.$t(`${I18N_POPTIP}.confirm`, { action: 'apply' });
    },
  },
  methods: {
    onOk() {
      if (this.loading) return;
      const cb = this.$listeners['on-ok'];
      if (!isFunction(cb)) return;

      this.loading = true;
      const ret = cb() || {};
      if (isFunction(ret.finally)) {
        ret.finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
