<template>
  <np-select :field="field" :records="records" :options="options" v-on="listeners" />
</template>

<script>
import NpSelect from './NpSelect';

export default {
  name: 'NpBoolean',
  components: { NpSelect },
  props: {
    field: String,
    records: Array,
  },
  data() {
    return {
      value: undefined,
      defaultValue: undefined,
    };
  },
  computed: {
    options: function() {
      return [{ name: 'ON', value: 'true' }, { name: 'OFF', value: 'false' }];
    },
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.defaultValue = this.options[0].value;
        this.value = this.defaultValue;
      },
    },
  },
};
</script>
