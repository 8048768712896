import { isEmpty } from '@/helpers/utils';
import numeral from 'numeral';
import { Decimal } from 'decimal.js';
import BigNumber from 'bignumber.js';

export const decimalRegex = /^-?\d+\.?\d*$/;
const validValueRegex = /^(-?[0-9]*\.?[0-9]*?)0*$/;
const formatRegex = /\B(?=(\d{3})+(?!\d))/g;

export function numberFormat(value, scale, round = Decimal.ROUND_HALF_UP) {
  if (isEmpty(value)) return value;
  if (isEmpty(scale) || scale < 0) scale = undefined;
  let fValue = `${new Decimal(`${value}`).toFixed(scale, round)}`;
  let arr = isEmpty(scale) ? `${value}`.split('.') : fValue.split('.');
  let before = fValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.')[0];
  let after = arr.length === 1 ? '' : arr[1];
  return arr.length === 1 ? before : `${before}.${after}`;
}

export function numberScale(value, scale, round = Decimal.ROUND_HALF_UP, clearZero = false) {
  if (isEmpty(value)) return value;
  if (isEmpty(scale) || scale < 0) scale = undefined;
  if (isEmpty(scale)) return clearZero ? clearDecimalZero(value) : value;
  return `${new Decimal(`${value}`).toFixed(scale, round)}`;
}

export function clearDecimalZero(value) {
  if (isEmpty(value)) return value;
  if (`${value}`.split('.').length === 1) return value;
  return new Decimal(`${value}`).toFixed();
}

export function percent(value, pattern = '0.000 %') {
  return numeral(`${value}`).format(pattern);
}

export function getDecimalInfo(value = '', scale) {
  // for '1e-8'
  if (typeof value === 'number') value = new BigNumber(value).toString();
  else {
    value = String(value);
    // For: '-000'
    if (new BigNumber(value).toString() === '0') value = '0';
  }
  if (!decimalRegex.test(value)) return null;
  let [, validValue] = validValueRegex.exec(value);
  // '123.' => '123'
  if (validValue[validValue.length - 1] === '.') validValue = validValue.substring(0, validValue.length - 1);

  const [integerValue, validDecimalValue = ''] = validValue.split('.');
  const validScale = validDecimalValue?.length ?? 0;

  const [, decimalValue] = value.split('.');
  const decimalScale = decimalValue?.length ?? 0;

  scale = scale ?? decimalScale;
  scale = Math.max(validScale, scale); // won't round number ever

  /**
   /\B(?=(\d{3})+(?!\d))/g：正则匹配边界\B，边界后面必须跟着(\d{3})+(?!\d);
   (\d{3})+：必须是1个或多个的3个连续数字;
   (?!\d)：第2步中的3个数字不允许后面跟着数字;
   (\d{3})+(?!\d)：所以匹配的边界后面必须跟着3*n（n>=1）的数字。
   ?=: 前瞻
   */
  const formattedInteger = integerValue.replace(formatRegex, ',');
  const formattedValidValue = `${formattedInteger}${validScale > 0 ? '.' : ''}${validDecimalValue}`;
  const formattedValue = `${formattedInteger}${scale > 0 ? '.' : ''}${validDecimalValue.padEnd(scale, '0')}`;
  const scaledValue = formattedValue.replace(/,/g, '');

  return { scale, validValue, validScale, formattedValidValue, formattedValue, scaledValue };
}
