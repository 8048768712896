import Vue from 'vue';
import Vuex from 'vuex';
import menu from './module/menu';
import enums from './module/enums';
import access from './module/access';
import config from './module/config';
import layout from './module/layout';
import profile from './module/profile';
import selection from './module/selection';
import preference from './module/preference';
import panel from './module/panel';
import ENUMS, { LOCAL_ENUMS } from '@/constant/enums';
import _ from 'lodash-es';

Vue.use(Vuex);

let store = new Vuex.Store({
  modules: {
    menu,
    enums,
    access,
    config,
    layout,
    profile,
    selection,
    preference,
    panel,
  },

  actions: {
    setup({ commit }, raw) {
      // merge local enums
      Object.assign(raw.enums, LOCAL_ENUMS);
      // set enums
      for (const key in raw.enums) {
        raw.enums[key].forEach(_enum => {
          _.set(ENUMS, `${key}.${_enum.name}`, _enum.value);
        });
      }

      // Ken 2020-02-25 16:14 加个测试company
      // const companies = raw.selection['company.company'];
      // const newCompany = _.cloneDeep(companies[0]);
      // newCompany.value = 9;
      // newCompany.name = 'Test Company';
      // companies.push(newCompany);

      commit('menu/setup', raw.menu);
      commit('enums/setup', raw.enums);
      commit('config/setup', raw.config);
      commit('profile/setup', raw.profile);
      commit('selection/setup', raw.selection);
      commit('access/setup');
      commit('preference/setup');
      commit('layout/setup', {
        collapsed: false,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    },
  },
});

export default store;
export const STORAGE_TAB_KEY = 'tabs';
export const STORAGE_PREFERENCE_KEY = 'preference';
/**
 *
 */
export function getEnum(type, value) {
  let object = store.state.enums.data[type];
  return object ? (value === undefined ? object : object.find(e => e.value === value)) : undefined;
}

/**
 *
 */
export function getSelection(type, value) {
  let object = store.state.selection.data[type];
  return object ? (value === undefined ? object : object.find(e => e.value === value)) : undefined;
}

export function getSelectionGetter(name) {
  return store.getters[`selection/${name}`];
}

export function getSelectionByCompanyId(type, companyId, value) {
  let selections = getSelection(type, value);
  return selections.filter(s => s.extra.$group === companyId);
}
