<template>
  <div class="login">
    <Card>
      <p slot="title" class="login-title">MyExpense Admin</p>
      <Form ref="loginForm" :model="model" :rules="rules" @keydown.enter.native="login">
        <Alert v-show="response" type="error" show-icon>{{ $t(response) }}</Alert>
        <FormItem :error="error.loginId" prop="loginId">
          <i-input
            v-model="model.loginId"
            type="text"
            size="large"
            placeholder="Login ID"
            prefix="ios-contact"
            :disabled="loading"
          />
        </FormItem>
        <FormItem :error="error.password" prop="password">
          <i-input
            v-model="model.password"
            type="password"
            size="large"
            placeholder="Password"
            prefix="ios-key"
            :disabled="loading"
          />
        </FormItem>
        <FormItem>
          <Button long type="success" size="large" icon="ios-log-in" class="login-button" :loading="loading" @click="login">
            Login
          </Button>
        </FormItem>
      </Form>
    </Card>
  </div>
</template>

<script>
import { routerTo } from '@/router';
import Model, { login } from './model';
import { RESULT } from '@/constant/enums';
import { DEFAULT_PORTAL } from '@/constant/config';
import _ from 'lodash-es';
import { boot } from '@/api/utility/utility';

export default {
  data() {
    return {
      ...Model,
      loading: false,
      response: '',
    };
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    async login() {
      this.response = '';
      const isValid = await this.$refs.loginForm.validate();
      if (!isValid) {
        return;
      }

      this.loading = true;
      login(this.model.loginId, this.model.password)
        .then(r => {
          if (RESULT.SUCCESS === r.type) {
            return boot();
          } else {
            throw r.data;
          }
        })
        .then(r => {
          // check privilege
          const activeRoute = this.$store.getters['access/activeRoute'];
          let toUrl = _.get(activeRoute, 'url');
          if (!toUrl) {
            const flatMenus = _.flatMap(this.$store.getters['menu/menus'], 'subs');
            let toMenu = _.find(flatMenus, { url: DEFAULT_PORTAL });
            // check access privilege for DEFAULT_PORTAL or any menu
            toUrl = toMenu ? toMenu.url : _.get(flatMenus, '[0].url');
          }

          if (!toUrl) {
            throw { key: 'error.no_privilege' };
          }
          routerTo(toUrl);
        })
        .catch(e => {
          this.response = _.get(e, 'key', 'error.service_unavailable');
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'style';
</style>
