import { filter } from 'lodash-es';
import { WebsocketChannel } from '@nextop/admin-utils';

const CommonChannel = new WebsocketChannel('COMMON');
const NoticeChannel = new WebsocketChannel('NOTICE');
const QuoteChannel = new WebsocketChannel('QUOTE');
const CoQuoteChannel = new WebsocketChannel('CO_QUOTE');
const LpQuoteChannel = new WebsocketChannel('BR_QUOTE');

const CoverageChannel = new WebsocketChannel('COVERAGE', function(params, data) {
  // subscribe/unsubscribe confirm message DOSEN'T have data
  if (!data) {
    return null;
  }
  const filteredOrders = filter(data.orders, { symbolId: params.symbol, bookId: params.book });
  return filteredOrders.length > 0 ? { orders: filteredOrders, type: data.type } : null;
});

export { CommonChannel, NoticeChannel, QuoteChannel, CoQuoteChannel, LpQuoteChannel, CoverageChannel };
