<template>
  <!-- prettier-ignore -->
  <Button 
    :type="type"
    :icon="icon"
    :size="n_size"
    :title="title"
    :ghost="ghost"
    :loading="loading"
    :class="buttonClass"
    :disabled="disabled"
    :custom-icon="customIcon"
    :shape="circle ? 'circle' : null"
    @click="$emit('on-click')" />
</template>

<script>
import Base from '../base';

export default Base.extend({
  name: 'NButtonIcon',
  props: {
    title: String,
    circle: Boolean,
    loading: Boolean,
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
