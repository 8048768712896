// import Affix from 'view-design/src/components/affix';
import Alert from 'view-design/src/components/alert';
// import Anchor from 'view-design/src/components/anchor';
// import AnchorLink from 'view-design/src/components/anchor-link';
// import AutoComplete from 'view-design/src/components/auto-complete';
// import Avatar from 'view-design/src/components/avatar';
// import BackTop from 'view-design/src/components/back-top';
// import Badge from 'view-design/src/components/badge';
import Breadcrumb from 'view-design/src/components/breadcrumb';
import Button from 'view-design/src/components/button';
import Card from 'view-design/src/components/card';
// import Carousel from 'view-design/src/components/carousel';
// import Cascader from 'view-design/src/components/cascader';
// import Cell from 'view-design/src/components/cell';
import Checkbox from 'view-design/src/components/checkbox';
// import Circle from 'view-design/src/components/circle';
import Collapse from 'view-design/src/components/collapse';
import ColorPicker from 'view-design/src/components/color-picker';
import Content from 'view-design/src/components/content';
import DatePicker from 'view-design/src/components/date-picker';
import Divider from 'view-design/src/components/divider';
import Drawer from 'view-design/src/components/drawer';
import Dropdown from 'view-design/src/components/dropdown';
import Footer from 'view-design/src/components/footer';
import Form from 'view-design/src/components/form';
import Header from 'view-design/src/components/header';
import Icon from 'view-design/src/components/icon';
import Input from 'view-design/src/components/input';
import InputNumber from 'view-design/src/components/input-number';
import Scroll from 'view-design/src/components/scroll';
// import Split from 'view-design/src/components/split';
import Layout from 'view-design/src/components/layout';
import List from 'view-design/src/components/list';
// import LoadingBar from 'view-design/src/components/loading-bar';
import Menu from 'view-design/src/components/menu';
import Message from 'view-design/src/components/message';
import Modal from 'view-design/src/components/modal';
import Notice from 'view-design/src/components/notice';
import Page from 'view-design/src/components/page';
// import Poptip from 'view-design/src/components/poptip';
// import Progress from 'view-design/src/components/progress';
import Radio from 'view-design/src/components/radio';
import Rate from 'view-design/src/components/rate';
import Sider from 'view-design/src/components/sider';
// import Slider from 'view-design/src/components/slider';
// import Spin from 'view-design/src/components/spin';
// import Steps from 'view-design/src/components/steps';
// import Switch from 'view-design/src/components/switch';
// import Table from 'view-design/src/components/table';
import Tabs from 'view-design/src/components/tabs';
// import Tag from 'view-design/src/components/tag';
// import Time from 'view-design/src/components/time';
// import Timeline from 'view-design/src/components/timeline';
import TimePicker from 'view-design/src/components/time-picker';
// import Tooltip from 'view-design/src/components/tooltip';
import Transfer from 'view-design/src/components/transfer';
import Tree from 'view-design/src/components/tree';
import Upload from 'view-design/src/components/upload';
import { Row, Col } from 'view-design/src/components/grid';
import { Option, OptionGroup } from 'view-design/src/components/select';
import locale from 'view-design/src/locale/index';

import Poptip from './poptip';
import Tooltip from './tooltip';
import Select from './select/select';

const components = {
  // Affix,
  Alert,
  // Anchor,
  // AnchorLink,
  // AutoComplete,
  // Avatar,
  // BackTop,
  // Badge,
  Breadcrumb,
  BreadcrumbItem: Breadcrumb.Item,
  Button,
  ButtonGroup: Button.Group,
  Card,
  // Carousel,
  // CarouselItem: Carousel.Item,
  // Cascader,
  // Cell,
  // CellGroup: Cell.Group,
  Checkbox,
  CheckboxGroup: Checkbox.Group,
  Col,
  Collapse,
  ColorPicker,
  Content: Content,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  DropdownItem: Dropdown.Item,
  DropdownMenu: Dropdown.Menu,
  Footer: Footer,
  Form,
  FormItem: Form.Item,
  Header: Header,
  Icon,
  Input,
  InputNumber,
  Scroll,
  Sider: Sider,
  // Split,
  Submenu: Menu.Sub,
  Layout: Layout,
  List,
  ListItem: List.Item,
  ListItemMeta: List.Item.Meta,
  // LoadingBar,
  Menu,
  MenuGroup: Menu.Group,
  MenuItem: Menu.Item,
  Message,
  Modal,
  Notice,
  Option: Option,
  OptionGroup,
  Page,
  Panel: Collapse.Panel,
  Poptip,
  // Progress,
  Radio,
  RadioGroup: Radio.Group,
  Rate,
  Row,
  Select,
  // Slider,
  // Spin,
  // Step: Steps.Step,
  // Steps,
  // Table,
  Tabs: Tabs,
  TabPane: Tabs.Pane,
  // Tag,
  // Time,
  // Timeline,
  // TimelineItem: Timeline.Item,
  TimePicker,
  Tooltip,
  Transfer,
  Tree,
  Upload,
};

export const iview = {
  ...components,
  iButton: Button,
  // iCircle: Circle,
  iCol: Col,
  iContent: Content,
  iForm: Form,
  iFooter: Footer,
  iHeader: Header,
  iInput: Input,
  iMenu: Menu,
  iOption: Option,
  // iProgress: Progress,
  iSelect: Select,
  // iSwitch: Switch,
  // iTable: Table,
  // iTime: Time,
};

const install = function(Vue, opts = {}) {
  if (install.installed) return;
  locale.use(opts.locale);
  locale.i18n(opts.i18n);

  Object.keys(iview).forEach(key => {
    Vue.component(key, iview[key]);
  });

  Vue.prototype.$IVIEW = {
    size: opts.size || '',
    transfer: 'transfer' in opts ? opts.transfer : '',
    capture: 'capture' in opts ? opts.capture : true,
    select: {
      arrow: opts.select ? (opts.select.arrow ? opts.select.arrow : '') : '',
      customArrow: opts.select ? (opts.select.customArrow ? opts.select.customArrow : '') : '',
      arrowSize: opts.select ? (opts.select.arrowSize ? opts.select.arrowSize : '') : '',
    },
    cell: {
      arrow: opts.cell ? (opts.cell.arrow ? opts.cell.arrow : '') : '',
      customArrow: opts.cell ? (opts.cell.customArrow ? opts.cell.customArrow : '') : '',
      arrowSize: opts.cell ? (opts.cell.arrowSize ? opts.cell.arrowSize : '') : '',
    },
    menu: {
      arrow: opts.menu ? (opts.menu.arrow ? opts.menu.arrow : '') : '',
      customArrow: opts.menu ? (opts.menu.customArrow ? opts.menu.customArrow : '') : '',
      arrowSize: opts.menu ? (opts.menu.arrowSize ? opts.menu.arrowSize : '') : '',
    },
    tree: {
      arrow: opts.tree ? (opts.tree.arrow ? opts.tree.arrow : '') : '',
      customArrow: opts.tree ? (opts.tree.customArrow ? opts.tree.customArrow : '') : '',
      arrowSize: opts.tree ? (opts.tree.arrowSize ? opts.tree.arrowSize : '') : '',
    },
    cascader: {
      arrow: opts.cascader ? (opts.cascader.arrow ? opts.cascader.arrow : '') : '',
      customArrow: opts.cascader ? (opts.cascader.customArrow ? opts.cascader.customArrow : '') : '',
      arrowSize: opts.cascader ? (opts.cascader.arrowSize ? opts.cascader.arrowSize : '') : '',
      itemArrow: opts.cascader ? (opts.cascader.itemArrow ? opts.cascader.itemArrow : '') : '',
      customItemArrow: opts.cascader ? (opts.cascader.customItemArrow ? opts.cascader.customItemArrow : '') : '',
      itemArrowSize: opts.cascader ? (opts.cascader.itemArrowSize ? opts.cascader.itemArrowSize : '') : '',
    },
    colorPicker: {
      arrow: opts.colorPicker ? (opts.colorPicker.arrow ? opts.colorPicker.arrow : '') : '',
      customArrow: opts.colorPicker ? (opts.colorPicker.customArrow ? opts.colorPicker.customArrow : '') : '',
      arrowSize: opts.colorPicker ? (opts.colorPicker.arrowSize ? opts.colorPicker.arrowSize : '') : '',
    },
    datePicker: {
      icon: opts.datePicker ? (opts.datePicker.icon ? opts.datePicker.icon : '') : '',
      customIcon: opts.datePicker ? (opts.datePicker.customIcon ? opts.datePicker.customIcon : '') : '',
      iconSize: opts.datePicker ? (opts.datePicker.iconSize ? opts.datePicker.iconSize : '') : '',
    },
    timePicker: {
      icon: opts.timePicker ? (opts.timePicker.icon ? opts.timePicker.icon : '') : '',
      customIcon: opts.timePicker ? (opts.timePicker.customIcon ? opts.timePicker.customIcon : '') : '',
      iconSize: opts.timePicker ? (opts.timePicker.iconSize ? opts.timePicker.iconSize : '') : '',
    },
    tabs: {
      closeIcon: opts.tabs ? (opts.tabs.closeIcon ? opts.tabs.closeIcon : '') : '',
      customCloseIcon: opts.tabs ? (opts.tabs.customCloseIcon ? opts.tabs.customCloseIcon : '') : '',
      closeIconSize: opts.tabs ? (opts.tabs.closeIconSize ? opts.tabs.closeIconSize : '') : '',
    },
    modal: {
      maskClosable: opts.modal ? ('maskClosable' in opts.modal ? opts.modal.maskClosable : '') : '',
    },
  };

  // Vue.prototype.$Loading = LoadingBar;
  Vue.prototype.$Message = Message;
  Vue.prototype.$Modal = Modal;
  Vue.prototype.$Notice = Notice;
  // Vue.prototype.$Spin = Spin;
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const API = {
  version: process.env.VERSION, // eslint-disable-line no-undef
  locale: locale.use,
  i18n: locale.i18n,
  install,
  // Circle,
  // Switch,
  ...components,
};

API.lang = code => {
  const langObject = window['iview/locale'].default;
  if (code === langObject.i.locale) locale.use(langObject);
  else console.log(`The ${code} language pack is not loaded.`); // eslint-disable-line no-console
};

export { Icon, Checkbox };

export default API;
