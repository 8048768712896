<template>
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-input v-if="disabled && millisecond" :value="n_value" icon="ios-calendar-outline" disabled />
      <DatePicker
        v-else
        v-model="n_value"
        transfer
        type="datetime"
        :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)"
        :clearable="clearable"
        :format="n_format === null ? '' : n_format.DETAIL"
        placement="bottom-end"
        :disabled="disabled"
        :options="options"
        @input.native="onInput"
        @on-change="changeDatetime"
      />
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { isEmpty } from '@/helpers/utils';
import moment from 'moment';
import 'moment-timezone';
import { DATE_FORMAT } from '@/constant/format';
import { getSelection } from '@/store';
import { findComponentDownward } from '@/helpers/component';

export default Base.extend({
  name: 'NFieldDatetime',
  props: {
    value: String,
    format: Object,
    millisecond: Boolean,
    clearable: Boolean,
  },
  data() {
    return {
      n_value: null,
      n_format: null,
      n_date: null,
      n_time: null,
    };
  },
  computed: {
    timezone() {
      return getSelection('market.market')[0].extra.timezone;
    },
    options() {
      const r = {
        shortcuts: [
          {
            text: 'NOW',
            value() {
              let timezone = getSelection('market.market')[0].extra.timezone;
              return moment
                .unix(new Date().getTime() / 1000)
                .utcOffset(timezone)
                .format(this.n_format);
            },
          },
          {
            text: 'SOD',
            value() {
              let sod = getSelection('market.market')[0].extra.sod;
              let timezone = getSelection('market.market')[0].extra.timezone;
              return moment
                .unix(Number(sod) / 1000)
                .utcOffset(timezone)
                .format(this.n_format);
            },
          },
          {
            text: 'EOD',
            value() {
              let eod = getSelection('market.market')[0].extra.eod;
              let timezone = getSelection('market.market')[0].extra.timezone;
              return moment
                .unix(Number(eod) / 1000)
                .utcOffset(timezone)
                .format(this.n_format);
            },
          },
        ],
      };

      if (this.$attrs['disabled-date']) {
        r.disabledDate = this.$attrs['disabled-date'];
      }

      return r;
    },
    isModified() {
      return (
        this.showModifyIcon && this.initValue !== (isEmpty(this.value) ? null : this.valueFormat(DATE_FORMAT.YMD_HMS_3.MOMENT))
      );
    },
  },
  watch: {
    value() {
      this.init();
    },
  },
  created() {
    this.initValue = isEmpty(this.value) ? null : this.valueFormat(DATE_FORMAT.YMD_HMS_3.MOMENT);
    this.initFormat();
    this.init();
  },
  methods: {
    init() {
      if (isEmpty(this.value) || this.value === '0') {
        this.n_value = null;
        return;
      }
      if (this.disabled && this.millisecond) {
        this.n_value = this.valueFormat(DATE_FORMAT.YMD_HMS_S_3.MOMENT);
        return;
      }
      this.n_date = this.valueFormat(DATE_FORMAT.YMD_3.MOMENT);
      this.n_time = this.valueFormat(DATE_FORMAT.HMS.MOMENT);
      this.n_value = `${this.n_date} ${this.n_time}`;
    },
    initFormat() {
      if (this.disabled && this.millisecond) this.n_format = DATE_FORMAT.YMD_HMS_S_3;
      else if (isEmpty(this.format)) this.n_format = DATE_FORMAT.YMD_HMS_3;
      else this.n_format = this.format;
    },
    changeDatetime(value) {
      let v = null;
      if (!isEmpty(value)) {
        let date = moment(new Date(value)).format(DATE_FORMAT.YMD_3.MOMENT);
        let time = moment(new Date(value)).format(DATE_FORMAT.HMS.MOMENT);
        if (this.n_date !== date) {
          this.n_date = date;
          if (this.n_time !== time && time !== '00:00:00') {
            this.n_time = time;
          }
        } else this.n_time = time;
        value = `${this.n_date} ${isEmpty(this.n_time) ? time : this.n_time}`;
        let timestamp = moment(new Date(value)).valueOf();
        let offset = new Date().getTimezoneOffset() * 60000;
        let tzOffset = this.timezone * 3600 * 1000;
        v = String(timestamp - offset - tzOffset);
      }
      this.$emit('input', v);
      this.$emit('on-change', v);
    },
    valueFormat(format) {
      return moment
        .unix(Number(this.value) / 1000)
        .utcOffset(this.timezone)
        .format(format);
    },

    // Handle enter event.
    // e.g. Input date manually then press enter key twice,
    // it is incorrect if not reset focusedDate.
    onInput(e) {
      const input = e.target.value;
      const m = moment(input, DATE_FORMAT.YMD_HMS_3.MOMENT, true);
      if (m.isValid()) {
        const picker = findComponentDownward(this, 'CalendarPicker');
        picker.focusedDate = m.toDate();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
