<template>
  <!-- prettier-ignore -->
  <div>
    <n-page v-if="searched && hasResult" :total="total" :page-no="pageNo" :page-size="pageSize" @on-page-no="v => $emit('on-page-no', v)" @on-page-size="v => $emit('on-page-size', v)" />
    <div class="table-responsive">
      <slot v-if="searched && !searching && hasResult"></slot>
      <div v-if="searched && !searching && !hasResult">
        <Alert type="warning" show-icon>No data found.</Alert>
      </div>
    </div>
    <n-page v-if="searched && hasResult" :total="total" :page-no="pageNo" :page-size="pageSize" @on-page-no="v => $emit('on-page-no', v)" @on-page-size="v => $emit('on-page-size', v)" />
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE } from '@/mixins/paginate-mixin';

export default {
  name: 'NTable',
  props: {
    total: { type: Number, default: 0 },
    pageNo: { type: Number, default: 1 },
    pageSize: { type: Number, default: DEFAULT_PAGE_SIZE },
    onPageNo: Function,
    onPageSize: Function,
    searched: {
      type: Boolean,
      default: false,
    },
    searching: {
      type: Boolean,
      default: false,
    },
    hasResult: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
