<template>
  <draggable v-bind="attrs" v-on="listeners">
    <slot />
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'NDraggable',

  components: { draggable },

  computed: {
    attrs() {
      return {
        animation: 200,
        tag: 'tbody',
        ...this.$attrs,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: v => {
          this.$emit('input', v);
          this.$emit('on-change', v);
        },
      };
    },
  },
};
</script>
