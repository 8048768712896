const state = {
  data: {
    collapsed: false,
    innerWidth: '',
    innerHeight: '',
  },
  tabsHeight: 0,
};
const getters = {
  collapsed: state => state.data.collapsed,
  innerWidth: state => state.data.innerWidth || 0,
  innerHeight: state => state.data.innerHeight || 0,
  tabsHeight: state => state.tabsHeight || 0,
  mini: state => state.data.innerWidth < 830, // 830: same value in mobile.scss
};

const mutations = {
  setup(state, value) {
    state.data = value;
  },

  resize(state) {
    state.data.innerWidth = window.innerWidth;
    state.data.innerHeight = window.innerHeight;
  },

  toggleMenu(state, value) {
    state.data.collapsed = value;
  },

  setTabsHeight(state, value) {
    state.tabsHeight = value;
  },
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
