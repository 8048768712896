import Base from './base';
import _ from 'lodash-es';
import ForceUpdateMixin from './ForceUpdateMixin';

/**
 * @Author Ken
 * @CreateDate 2019-12-06 10:43
 * @LastUpdateDate 2019-12-06 10:43
 * @desc 基于companyId变化的组件的基类
 * @params
 * @return
 */
export default Base.extend({
  mixins: [ForceUpdateMixin],
  props: {
    companyId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  watch: {
    companyId(to, from) {
      let value = this.$attrs.value;
      // Ken 2019-12-06 10:38 当不强制更新时, 则优先从options里查找, 找到了, 则不变化
      if (!this.isForceUpdate && _.find(this.options, { value })) {
        return;
      }
      if (this.allOption) {
        // 置为All
        value = 0;
      } else {
        // 置为options第一个
        value = _.get(this.options, '[0].value', 0);
      }

      this.$emit('input', value);
      if (value !== this.$attrs.value) {
        this.$emit('on-change', value);
      }
    },
  },
});
