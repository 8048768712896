export default [
  {
    path: '/market/market',
    meta: {
      name: 'market.market',
      cacheName: 'MarketMarket',
    },
    component: () => import('@/views/index/market/MarketMarket'),
  },
  {
    path: '/market/market/holidays',
    meta: {
      name: 'market.market',
      submenu: true,
      cacheName: ['MarketMarket', 'MarketMarketHoliday'],
    },
    component: () => import('@/views/index/market/MarketMarketHoliday'),
  },
  {
    path: '/market/market/sessions',
    meta: {
      name: 'market.market',
      submenu: true,
      cacheName: ['MarketMarket', 'MarketMarketSession'],
    },
    component: () => import('@/views/index/market/MarketMarketSession'),
  },
  {
    path: '/market/currency',
    meta: {
      name: 'market.currency',
      cacheName: 'MarketCurrency',
    },
    component: () => import('@/views/index/market/MarketCurrency'),
  },
  {
    path: '/market/country',
    meta: {
      name: 'market.country',
      cacheName: 'MarketCountry',
    },
    component: () => import('@/views/index/market/MarketCountry'),
  },
  {
    path: '/market/icon',
    meta: {
      name: 'market.icon',
      cacheName: 'MarketIcon',
    },
    component: () => import('@/views/index/market/MarketIcon'),
  },
];
