<script>
import _ from 'lodash-es';
import { capitalize } from '@nextop/admin-utils';
import i18n from '@/locale/i18n';
import { RESULT } from '@/constant/enums';

export default {
  name: 'ResultMixin',

  methods: {
    isError(r) {
      return r && r.hasOwnProperty('key') && r.level === 'ERROR';
    },

    isData(r) {
      return r && r.hasOwnProperty('data') && r.type === RESULT.DATA;
    },

    isMessage(r) {
      return r && r.hasOwnProperty('type') && r.type === RESULT.MESSAGE;
    },

    isSuccess(r) {
      return r && r.hasOwnProperty('type') && r.type === RESULT.SUCCESS;
    },

    isValidation(r) {
      return r && r.hasOwnProperty('data') && r.type === RESULT.VALIDATION;
    },

    merge(result) {
      if (result) {
        let r = {};
        let error = result.data;
        if (_.isArray(error)) {
          // Ken 2020-03-02 16:40 关于array的validation, 目前是被各个业务自行处理的, 而没有统一处理
          // support array, display all errors
          // r[k] = { validation: [this.$t(error[k].key)] };
        } else if (_.isObject(error)) {
          Object.keys(error).forEach(k => {
            if (error[k].key.startsWith('error.typeMismatch')) {
              error[k].key = 'error.type_mismatch';
            }
            r[k] = this.$t(error[k].key);
          });
        } else {
          console.warn('unsupported validation data');
        }
        return r;
      }
    },

    /**
     * Remove error prefix
     */
    trim(key) {
      return key.startsWith('error.') ? key.substring(6) : key;
    },

    /**
     * Fomat response key
     * INVALID_CONTRACT ==> Invalid Contract
     */
    format(msg = '') {
      let words = msg.split('_');
      return words.map(w => capitalize(this.trim(w.toLowerCase()))).join(' ');
    },

    /**
     * Preferred display is i18n resource.
     *
     * format-1: {"key":"INVALID_CONTRACT","level":"ERROR","type":"MESSAGE"}
     * format-2: {"key": "error.overlength", level: "ERROR", "type": "MESSAGE"}
     * format-3: {"data":{"CORRECT_TYPE":"INVALID"},"type":"VALIDATION"}
     * format-4: {"data":{"key":"error.invalid_account","level":"ERROR","type":"MESSAGE"},"type":"DATA"}
     * format-5: {"data":{"contractId":{"args":[],"key":"error.invalid","level":"INFO","type":"MESSAGE"}},"type":"VALIDATION"}
     */
    showErrorMsg(res = {}) {
      let data = res && res.data ? res.data : res;

      // format 1,2
      if (this.isError(data)) {
        let key = data.key || '';
        key = key.toLowerCase();
        if (i18n.te(key, 'en')) {
          this.$Message.error(i18n.t(key));
        } else if (i18n.te(`error.${key}`, 'en')) {
          this.$Message.error(i18n.t(`error.${key}`));
        } else {
          this.$Message.error(this.format(key));
        }
      } else {
        // format 3,4,5
        let obj = this.merge(res);
        if (_.isEqual(obj, {})) {
          this.$Message.error(); // default
        } else {
          let arr = Object.keys(obj).map(k => [capitalize(k), this.format(obj[k])].join(' '));
          this.$Message.error(arr.join(', '));
        }
      }
    },
  },
};
</script>
