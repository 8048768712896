<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <input :value="originalValue" style="display: none;" />
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-input v-if="n_hideZero" value="" type="text" disabled>
        <span v-if="suffixText !== undefined" slot="append">{{ $t(`label.${suffixText}`) }}</span>
        <span v-else-if="customSuffixText !== undefined" slot="append">{{ customSuffixText }}</span>
      </i-input>
      <i-input v-else-if="n_showZero" value="0" type="text" disabled>
        <span v-if="suffixText !== undefined" slot="append">{{ $t(`label.${suffixText}`) }}</span>
        <span v-else-if="customSuffixText !== undefined" slot="append">{{ customSuffixText }}</span>
      </i-input>
      <i-input v-else v-model="displayValue" type="text" :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)" :disabled="disabled" @on-change="onChange" @on-focus="focusIn" @on-blur="focusOut">
        <span v-if="suffixText !== undefined" slot="append">{{ $t(`label.${suffixText}`) }}</span>
        <span v-else-if="customSuffixText !== undefined" slot="append">{{ customSuffixText }}</span>
      </i-input>
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { Decimal } from 'decimal.js';
import { numberFormat, numberScale } from '@/helpers/number';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldDecimal',
  props: {
    value: [String, Number],
    scale: Number,
    suffixText: String,
    customSuffixText: String,
    showZero: Boolean,
    noFormat: Boolean,
  },
  data() {
    return {
      originalValue: null,
      displayValue: null,
      isFocusing: false,
    };
  },
  computed: {
    n_hideZero() {
      return this.isDetail && !this.showZero && (this.value === 0 || parseFloat(this.value) === 0);
    },
    n_showZero() {
      return this.isDetail && this.showZero && (isEmpty(this.value) || this.value === 0 || parseFloat(this.value) === 0);
    },
    isModified() {
      return this.showModifyIcon && this.initValue !== this.originalValue;
    },
  },
  watch: {
    value() {
      if (!this.isFocusing) this.init();
    },
    originalValue() {
      if (this.originalValue !== this.stringValue(this.value)) {
        this.$emit('input', this.originalValue);
        this.$emit('on-change', this.originalValue);
      }
    },
    isFocusing(to, from) {
      if (to && !from) {
        this.$nextTick(() => {
          this.$emit('on-focus');
        });
      } else if (!to && from) {
        this.$nextTick(() => {
          this.$emit('on-blur');
        });
      }
    },
  },
  created() {
    let v = this.stringValue(this.value);
    if (this.isCreate) {
      this.originalValue = v;
      this.displayValue = this.originalValue;
    } else {
      if (this.noFormat) this.originalValue = v;
      else this.originalValue = numberScale(v, this.scale, Decimal.ROUND_HALF_UP, true);
      this.displayValue = numberFormat(this.originalValue);
    }
    this.initValue = this.originalValue;
  },
  methods: {
    init() {
      this.originalValue = this.stringValue(this.value);
      if (isEmpty(this.originalValue)) {
        this.displayValue = this.originalValue;
        return;
      }
      if (this.originalValue !== '-' && isNaN(this.originalValue)) return;
      if (this.isCreate) {
        this.displayValue = this.originalValue;
        return;
      }
      if (!this.noFormat) this.originalValue = numberScale(this.originalValue, this.scale, Decimal.ROUND_HALF_UP, true);
      this.displayValue = numberFormat(this.originalValue);
    },
    onChange() {
      this.displayValue = this.displayValue.trim();
      if (this.displayValue === this.originalValue) return;
      if (isEmpty(this.displayValue)) {
        this.originalValue = null;
        this.displayValue = null;
        return;
      }
      let isNumber = this.displayValue === '-' || !isNaN(this.displayValue);
      if (isNumber) {
        this.$nextTick(() => {
          this.originalValue = this.displayValue;
        });
      } else {
        this.$nextTick(() => {
          this.displayValue = this.originalValue;
        });
      }
    },
    focusIn() {
      this.isFocusing = true;
      if (this.isCreate) return;
      this.displayValue = this.originalValue;
    },
    focusOut() {
      if (this.originalValue === '-') {
        this.originalValue = null;
        this.displayValue = null;
        this.isFocusing = false;
        return;
      }
      if (this.isCreate || isEmpty(this.originalValue)) {
        this.isFocusing = false;
        return;
      }
      if (this.originalValue.split('.').length === 1 || this.originalValue.indexOf('.') === this.originalValue.length - 1)
        this.originalValue = `${new Decimal(`${this.originalValue}`).toFixed()}`;
      if (this.displayValue === '.0') {
        this.originalValue = '0';
        this.displayValue = '0';
        this.isFocusing = false;
        return;
      }
      this.displayValue = numberFormat(this.originalValue);
      this.isFocusing = false;
    },
    stringValue(v) {
      if (isEmpty(v) || isNaN(v)) return '';
      return typeof v === 'string' ? v.trim() : v.toString().trim();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
