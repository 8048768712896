import Vue from 'vue';
import { get } from 'lodash-es';
import { getEnum } from '@/store';
import { ENUM_COLOR } from '@/constant/color';

Vue.directive('enums', function(el, binding, vnode) {
  const index = 6;
  const k = binding.arg;
  const v = binding.value;

  // name format: "NORMAL"
  const enumName = Vue.filter('enumName');
  const { name } = getEnum(k, parseInt(v)) || {};
  const color = name ? get(ENUM_COLOR, `${k}.${name}`) : undefined;

  if (name) {
    let n = enumName(`enums.${k}.${name}`);
    el.title = n;
    el.textContent = n;
  } else {
    el.textContent = '-';
  }

  if (color) {
    el.style.color = color;
  }

  const colorKey = el.getAttribute('data-color-enum');
  const colorValue = el.getAttribute('data-color-value');
  if (colorValue && colorKey) {
    const { name } = getEnum(colorKey, parseInt(colorValue)) || {};
    const c = get(ENUM_COLOR, name.slice(index));
    if (c) {
      el.style.color = c;
    }
  }
});
