import i18n from '@/locale/i18n';
import { eventBus } from '@nextop/admin-utils';
import { setConfig } from '@nextop/admin-glue';

setConfig({
  i18n,
  eventBus,
  projectName: 'balrog',
  adminName: 'MyExpense Admin',
});
