import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';
import i18n from '@/locale/i18n';
import errorRouters from '@/routers/error';
import Login from './views/login/index.vue';
import Index from './views/index/index.vue';
import marketRouters from '@/routers/market';
import systemRouters from '@/routers/system';
import utilityRouters from '@/routers/utility';
import companyRouters from '@/routers/company';
import { destroyModal } from '@/helpers/modal';
import customerRouters from '@/routers/customer';
import operatorRouters from '@/routers/operator';
import { DEFAULT_PORTAL } from '@/constant/config';

// Ken 2020-05-15 14:26 avoid register router in unit test, otherwise we can NOT use $route mock
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: DEFAULT_PORTAL,
      component: Index,
      // prettier-ignore
      children: [
        ...marketRouters,
        ...systemRouters,
        ...utilityRouters,
        ...companyRouters,
        ...customerRouters,
        ...operatorRouters,
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    ...errorRouters,
    {
      path: '*',
      redirect: DEFAULT_PORTAL,
    },
  ],
});

router.beforeResolve((to, from, next) => {
  setTitle(to.meta && to.meta.name ? to.meta.name : undefined);
  // BAL-296
  // root cause: iview Submenu 中对click事件做了阻止传播, 所以dropdown等组件的clickoutside功能失效
  document.body.click();
  destroyModal();
  next();
});

export default router;

export function routerTo(path) {
  if (router.history.current.fullPath !== path) {
    return router.push(path).catch(() => {});
  }
}

export function routerReplace(path) {
  if (router.history.current.fullPath !== path) {
    return router.replace(path).catch(() => {});
  }
}

function setTitle(name) {
  if (name === undefined) {
    document.title = 'MyExpense Admin';
  } else {
    let current = i18n.t(`menu.${name}`);
    let { environment } = store.state.config;
    document.title = `${current} - MyExpense Admin - ${environment}`;
  }
}
