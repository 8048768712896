<template>
  <Poptip v-if="hasRW" transfer confirm :title="popTitle" :disabled="loading" placement="top-end" @on-ok="onOk">
    <n-button v-if="text" v-bind="attrs" :loading="loading" />
    <n-button-icon v-else v-bind="attrs" :loading="loading" />
  </Poptip>
</template>

<script>
import { I18N_POPTIP } from '@/locale/i18n';
import _, { isFunction, isUndefined } from 'lodash-es';
import ICON from '@/constant/icon';
import { SUCCESS_MUTE } from '@/constant/config';
import { RESULT } from '@/constant/enums';
import { hasInheritedRW } from '@/helpers/privilege';
import ResultMixin from '@/mixins/result-mixin';

export default {
  name: 'NbConfirm',
  mixins: [ResultMixin],
  inject: {
    baseView: {
      from: 'baseView',
      default: {},
    },
  },
  props: {
    type: String,
    size: String,
    icon: String,
    text: String,
    long: Boolean,
    title: String,
    ghost: Boolean,
    action: String,
    customIcon: String,
    popContent: String,
    reSearch: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      hasRW: false,
    };
  },
  computed: {
    attrs() {
      return {
        ...this.$props,
        type: this.type || 'error',
        customIcon: isUndefined(this.customIcon) ? ICON[this.text] : this.customIcon,
      };
    },
    popTitle() {
      return this.popContent || this.$t(`${I18N_POPTIP}.confirm`, { action: this.action ? this.action : this.text });
    },
  },
  created() {
    this.hasRW = hasInheritedRW(this);
  },
  methods: {
    onOk() {
      if (this.loading) return;
      const cb = this.$listeners['on-ok'];
      /**
       * Ken 2020-01-07 15:25 $listeners内的函数被Vue包了一层, 其实是invoker函数, 其中的fns才是原函数
       * invoker函数在调用时, 如果内部的promise有error, 会被vue内部拦截到, 并打印出来, 我们想自己拦截, 所以直接使用函数
       */
      if (!isFunction(cb) || !isFunction(cb.fns)) return;

      this.loading = true;
      const ret = cb.fns() || {};
      if (isFunction(ret.finally)) {
        ret
          .then(r => {
            if (this.isSuccess(r)) {
              if (!SUCCESS_MUTE) {
                this.$Message.success();
              }
              if (isFunction(this.baseView.doLoad)) {
                this.baseView.load();
              } else if (this.reSearch && isFunction(this.baseView.doSearch)) {
                // operator session no need reSearch if kickout myself
                const reset = true; // reset pageNo = 1
                this.baseView.search(reset);
              }
            } else {
              this.showErrorMsg(r);
            }
          })
          .catch(e => {
            // console.error('NbConfirm: uncaught promise error', e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
