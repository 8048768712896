<template>
  <Sider class="sider" collapsible :value="collapsed" :collapsed-width="55" :hide-trigger="true">
    <Menu v-show="!collapsed" ref="menu" accordion width="auto" :open-names="openNames" :active-name="activeName">
      <Submenu v-for="menu in menus" :key="menu.name" :name="menu.name" class="p-parent-menu">
        <template slot="title">
          <Row>
            <Col span="4" class="p-icon-col">
              <Icon :custom="menu.icon" />
            </Col>
            <Col span="17" push="1">
              <span>{{ $t(`menu.${menu.name}.self`) }}</span>
            </Col>
          </Row>
        </template>
        <MenuItem
          v-for="sub in menu.subs"
          :key="sub.name"
          :ref="`menuItem-${menu.name}-${sub.name}`"
          :name="sub.name"
          @click.native="onClickMenu(sub)"
        >
          <span>{{ $t(`menu.${sub.name}`) }}</span>
        </MenuItem>
      </Submenu>
    </Menu>

    <div v-show="collapsed">
      <Dropdown
        v-for="menu in menus"
        :key="menu.name"
        transfer
        placement="right-start"
        transfer-class-name="p-collapsed-menu-mini-dropdown"
        class="p-collapsed-menu-mini"
      >
        <Icon :custom="menu.icon" />
        <DropdownMenu slot="list">
          <DropdownItem>{{ $t(`menu.${menu.name}.self`) }}</DropdownItem>
          <DropdownItem v-for="sub in menu.subs" :key="sub.name" @click.native="onClickMenu(sub)">
            {{ $t(`menu.${sub.name}`) }}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  </Sider>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { createModal } from '@/helpers/modal';

export default {
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      openNames: [],
      nCollapsed: false,
      activeName: '',
    };
  },

  computed: {
    ...mapGetters('access', ['activeRoute']),
    ...mapGetters('layout', ['mini']),
    collapsed() {
      return this.mini || this.nCollapsed;
    },
  },

  watch: {
    activeRoute() {
      this.openNames = this.activeNames();
      this.activeName = this.activeRoute ? this.activeRoute.name : '';
    },
    openNames() {
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
        this.$refs.menu.updateActiveName();
      });
    },
  },

  mounted() {
    this.openNames = this.activeNames();
    this.activeName = this.activeRoute ? this.activeRoute.name : '';
    this.$eventBus.$on('side-menu-collapse', () => {
      this.nCollapsed = !this.nCollapsed;
      if (this.nCollapsed) {
        this.openNames = [];
      } else {
        this.openNames = this.activeNames();
      }
    });
  },

  methods: {
    ...mapMutations('access', ['pushMenu']),
    ...mapActions('panel', ['createDealingPanel']),
    activeNames() {
      return [this.activeRoute ? '' + this.activeRoute.parent : ''];
    },
    onClickMenu(menu) {
      // Ken 2019-12-02 18:04 目前有两个menu(dealing/ book-transfer & emergency-cover, 点击时弹出modal
      if (menu.modal) {
        createModal(menu.component);
        // 以下是让active menu保持为上一个menu
        const previousName = this.activeName;
        this.activeName = menu.name;
        this.$nextTick(() => {
          this.activeName = previousName;
        });
      } else if (menu.meta?.panel) {
        // only dealing panel in menu for now
        this.createDealingPanel();
        const previousName = this.activeName;
        this.activeName = menu.name;
        this.$nextTick(() => {
          this.activeName = previousName;
        });
      } else {
        this.pushMenu({ name: menu.name });
      }
    },
    onClickCollapsedMenu(menu) {
      // 只有折叠的时候能点到
      this.openNames = [menu.name];
      this.collapsed = false;
    },
  },
};
</script>
<style lang="scss">
@import 'style';
</style>
