<template>
  <RadioGroup class="n-radio-group n-flex" type="button" v-bind="attrs" v-on="listeners">
    <Radio v-for="item in mItems" :key="item.value" :label="item.value" class="n-col-1 n-radio-group-item">{{ item.name }}</Radio>
  </RadioGroup>
</template>

<script>
export default {
  name: 'NRadioGroup',

  props: {
    items: Array,
  },

  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },

    listeners() {
      return {
        ...this.$listeners,
      };
    },

    mItems() {
      return this.items?.map(o => {
        let name, value;
        if (typeof o === 'object') {
          value = o.value;
          name = o.name ?? o.value;
        } else name = value = o;
        return { name, value };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'style';
</style>
