import _ from 'lodash-es';
import { forEach } from 'lodash-es';
import { CURRENCY_SCALE_NAME } from '@/constant/enums';
import { getSelection, getSelectionByCompanyId } from '@/store';
import { getCurrencyScale, getGroupDepositCurrencyScale } from '@/helpers/scale';
import { getLeverageSequence } from '@nextop/admin-utils';

export function Parser(records, opts) {
  forEach(records, r => forEach(opts, opt => opt(r)));
  return records;
}

export const optional = ([name, value]) => ({ name, value });

export function setLeverageValue(r = {}) {
  const { companyId, leverages } = r;
  let all = getSelectionByCompanyId('company.leverage', companyId);
  let l = all.find(l => {
    let mask = 1 << getLeverageSequence(l.value);
    return (leverages & mask) != 0;
  });
  r.leverageValue = l ? l.value : 0;
}

export function setLeverage(r = {}) {
  if (r.leverageId) {
    let selection = getSelection('company.leverage', r.leverageId);
    if (selection) {
      r.leverage = selection.extra.value;
    }
  }
}

export function setProfileExtra(r = {}) {
  if(r) {
    r.isIndividual = r.type === 1;
    r.twoFaChangable = r.twoFaOn;
  }
}

/**
 * @Author Ken
 * @CreateDate 2019-11-28 11:49
 * @LastUpdateDate 2019-11-28 11:49
 * @desc
 * @params
 *  currencyAttrArray: [String | Array] = ['profitCurrency', 'baseCurrency', 'currency', 'depositCurrency']
 * @return
 */
export function setCurrencyScale(currencyAttrArray) {
  if (_.isEmpty(currencyAttrArray)) {
    console.error('setCurrencyScale => no specific currency');
    return;
  }
  currencyAttrArray = _.isArray(currencyAttrArray) ? currencyAttrArray : [currencyAttrArray];
  return (r = {}) => {
    if (_.isEmpty(r)) {
      return;
    }
    const { currency, baseCurrency, groupId } = r;
    _.forEach(currencyAttrArray, attrName => {
      switch (attrName) {
        case CURRENCY_SCALE_NAME.BASE:
        case CURRENCY_SCALE_NAME.PROFIT:
          r[`${attrName}Scale`] = getCurrencyScale(baseCurrency);
          break;
        case CURRENCY_SCALE_NAME.CURRENCY:
          r[`${attrName}Scale`] = getCurrencyScale(currency | baseCurrency);
          break;
        case CURRENCY_SCALE_NAME.DEPOSIT:
          r[`${attrName}Scale`] = getGroupDepositCurrencyScale(groupId);
          break;
        default:
          console.error('unknown currencyAttrName = ', attrName);
      }
    });
  };
}

/**
 * @Author Ken
 * @CreateDate 2019-12-18 16:39
 * @LastUpdateDate 2019-12-18 16:39
 * @desc set restriction
 * @params  domain: 'company', 'dealing', 'bridging'
 * @return
 */
// export function setRestriction(domain) {
//   return (r = {}) => {
//     const { symbolId, brId, companyId } = r;
//     let symbol;
//     if (symbolId && (companyId || brId)) {
//       switch (domain) {
//         case RESTRICTION_DOMAIN.COMPANY:
//           symbol = getCoSymbol(companyId, symbolId);
//           if (symbol) {
//             r.pricingRestriction = symbol.pricingRestriction;
//             r.dealingRestriction = symbol.dealingRestriction;
//             r.placingRestriction = symbol.placingRestriction;
//             r.tradingRestriction = symbol.tradingRestriction;
//           }
//           break;
//         default:
//           console.error('unknown restriction domain = ', domain);
//       }
//     }
//   };
// }
