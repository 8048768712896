<template>
  <n-button
    ghost
    :disabled="disabled || downloading"
    type="info"
    :custom-icon="downloading ? 'fa fa-spinner fa-spin' : 'fa fa-download'"
    text="csv"
    @on-click="click"
  />
</template>

<script>
import axios from 'axios';
import filenamify from 'filenamify';
import { paramFilter } from '@/services/axios';

const NAMESPACE = '';
export default {
  name: 'NbDownload',

  props: {
    url: String,
    params: Object,
    strict: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      downloading: false,
    };
  },

  methods: {
    click() {
      this.downloading = true;
      download(this.url, this.params, { strict: this.strict })
        .catch(e => {
          console.log('download error', e);
          this.$Message.error(e?.statusText ?? 'Server Error');
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};

export function download(csvUrl, parameters, option = { strict: true }) {
  if (typeof csvUrl !== 'string') return;

  const responseType = 'blob';
  const params = option.strict ? paramFilter(parameters) : parameters;
  const url = `${NAMESPACE}${csvUrl.startsWith('/') ? '' : '/'}${csvUrl}`;

  return axios.request({ url, params, responseType }).then(res => {
    const link = document.createElement('a');
    const blob = new Blob([res.data], { type: res.data.type });
    link.setAttribute('download', getFileName(res.headers));
    link.setAttribute('href', window.URL.createObjectURL(blob));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

function getFileName(headers) {
  const dispositions = headers['content-disposition'];
  const fileName = dispositions.match(/filename="(.+)"/)[1];
  return fileName ? filenamify(fileName, { replacement: '-' }) : undefined;
}
</script>
