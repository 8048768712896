const RED = '#dc3545'; // text-danger
const BLUE = '#007bff'; // text-primary
const GREEN = '#28a745'; // text-success
const YELLOW = '#eb9316'; // text-warning

export const COLOR = {
  GREEN,
  RED,
  YELLOW,
  BLUE,
};

export const ENUM_COLOR = {
  AdminRoleStatus: {
    NORMAL: GREEN,
    LOCKED: RED,
    DISABLED: RED,
  },
  AdminOperatorStatus: {
    NORMAL: GREEN,
    LOCKED: RED,
    DISABLED: RED,
  },
  LoginRestriction: {
    NORMAL: GREEN,
    OFF: RED,
  },
  TradingRestriction: {
    NORMAL: GREEN,
    OFF: RED,
  },
  EditingRestriction: {
    NORMAL: GREEN,
    OFF: RED,
  },
  TransferRestriction: {
    NORMAL: GREEN,
    DEPOSIT_ONLY: YELLOW,
    WITHDRAW_ONLY: YELLOW,
    OFF: RED,
  },
  AdminNoticeLevel: {
    INFO: GREEN,
    WARN: YELLOW,
    ERROR: RED,
  },
  CustomerProfileStatus: {
    ACTIVE: GREEN,
    CLOSED: RED,
  },
  VerifyStatus: {
    PENDING: YELLOW,
    VERIFIED: GREEN,
    REJECTED: RED,
    VERIFYING: BLUE,
  },
  CustomerAccountStatus: {
    ACTIVE: GREEN,
    CLOSED: RED,
  },
  IssueStatus: {
    PENDING: YELLOW,
    ISSUING: YELLOW,
    SUCCESS: GREEN,
    FAILURE: RED,
  },
  StageStatus: {
    PENDING: YELLOW,
    STAGED: GREEN,
    STAGING: YELLOW,
    UNSTAGED: GREEN,
    UNSTAGING: YELLOW,
    FAILURE: RED,
  },
  CustomerBudgetStatus: {
    ACTIVE: GREEN,
    DELETED: RED
  },
  CustomerOfferStatus: {
    ACTIVE: GREEN,
    PAUSED: YELLOW,
    EXPIRED: RED,
    DELETED: RED
  },
  CustomerOrderStatus: {
    ACTIVE: GREEN,
    PENDING: YELLOW,
    CANCELED: RED,
    REFUNDED: RED,
    COMPLETED: GREEN
  },
  CustomerOfferPriority: {
    LOW: BLUE,
    MEDIUM: YELLOW,
    HIGH: RED
  },
  CustomerTransactionStatus: {
    ACTIVE: GREEN,
    DELETED: RED
  },
  TransactionType: {
    LOAN: YELLOW,
    INCOME: GREEN,
    EXPENSE: YELLOW
  },
  CompanyStatus: {
    ACTIVE: GREEN,
    DISABLED: RED,
  },
  MobileReleaseStatus: {
    ACTIVE: GREEN,
    PENDING: YELLOW,
    DISABLED: RED,
  },
  CustomerDeviceStatus: {
    ACTIVE: GREEN,
    PAUSED: YELLOW,
    DISABLED: RED,
  },
  GroupStatus: {
    ACTIVE: GREEN,
    DISABLED: RED,
  },
  CustomerConfigStatus: {
    ACTIVE: GREEN,
    DELETED: RED,
  },
};
