import store from '@/store';

export const MESSAGE_TYPE = 'N_WINDOW_MESSAGE';

export const MSG = {
  GET_DEALING_PANEL_INIT_DATA: 'GetDealingPanelInitData',
  INIT_DATA: 'InitData',
  SUB_WINDOW_CLOSE: 'SubWindowClose',
  DEALING_PANEL_SETTING_UPDATED: 'DealingPanelSettingUpdated',
  WEBSOCKET_MSG: 'WebsocketMsg',
  WEBSOCKET_ACT: 'WebSocketAct',
  SUBSCRIBE_COVERAGE: 'SubscribeCoverage',
  UNSUBSCRIBE_COVERAGE: 'UnsubscribeCoverage',
};

export class WindowMessage {
  constructor(eventName, params) {
    this.type = MESSAGE_TYPE;
    this.eventName = eventName;
    this.params = params;
  }
}

export class BroadcastMessage extends WindowMessage {
  constructor(params) {
    super('Broadcast', params);
  }
}

export class InitDataMessage extends WindowMessage {
  constructor() {
    const { state } = store;
    const bootstrap = {
      config: state.config,
      menu: state.menu.data,
      profile: state.profile,
      enums: state.enums.data,
      selection: state.selection.data,
    };
    super(MSG.INIT_DATA, { bootstrap });
  }
}

export class WebsocketMessage extends WindowMessage {
  constructor(event, data) {
    super(MSG.WEBSOCKET_MSG, { event, data });
  }
}

export function isValidMessage(data) {
  return data?.type === MESSAGE_TYPE;
}
