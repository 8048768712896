<template>
  <np-base
    :value="value"
    :field="field"
    :records="records"
    :default-value="defaultValue"
    v-on="listeners"
    @input="v => (value = v)"
  >
    <Select
      v-model="value"
      class="operation"
      @on-change="
        v => {
          this.$emit('input', v);
          this.$emit('on-change', v);
        }
      "
    >
      <Option v-for="option in selections" :key="option.value" :value="option.value" :disabled="option.disabled">
        {{ option.name | enumName }}
      </Option>
    </Select>
  </np-base>
</template>

<script>
import { getEnum } from '@/store';
import NpBase from './NpBase';

export default {
  name: 'NpSelect',
  components: { NpBase },
  props: {
    field: String,
    records: Array,
    options: Array,
    enumName: String,
  },
  data() {
    return {
      value: undefined,
      defaultValue: undefined,
    };
  },
  computed: {
    selections: function() {
      let options = this.options;
      return options && options.length > 0 ? options : getEnum(this.enumName);
    },
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
  watch: {
    selections: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.defaultValue = this.selections[0].value;
        this.value = this.defaultValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.operation {
  width: auto;
}

::v-deep .ivu-select-dropdown-list {
  text-align: left;
}

::v-deep .ivu-select-single .ivu-select-selection {
  height: 27px !important;
}

::v-deep .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
  height: 26px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  padding-right: 36px;
}

::v-deep .ivu-select-arrow {
  position: absolute;
  top: 50%;
  right: 4px;
  line-height: 1;
  font-size: 14px !important;
}

::v-deep .ivu-select-item {
  font-weight: 500;
  font-size: 12px !important;
}
</style>
