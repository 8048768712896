import { merge } from 'lodash-es';
import { storage } from '@nextop/admin-utils';
import { STORAGE_PREFERENCE_KEY } from '../index';
import { DEFAULT_PAGE_SIZE } from '@/mixins/paginate-mixin';

const state = {
  popup: true,
  sound: true,
  maxTabSize: 8,
  pageSize: DEFAULT_PAGE_SIZE,
};

function Save() {
  storage.set(STORAGE_PREFERENCE_KEY, state);
}

const getters = {
  popup: (state, getters, rootState, rootGetters) => {
    return state.popup && rootGetters['menu/hasOperatorNoticeReadPrivilege'];
  },
  sound: (state, getters, rootState, rootGetters) => {
    return state.sound && rootGetters['menu/hasOperatorNoticeReadPrivilege'];
  },
};
const mutations = {
  setup(state) {
    merge(state, storage.get(STORAGE_PREFERENCE_KEY));
  },
  setPreference(state, preference) {
    merge(state, preference);
    // 判断当前tabSize是否大于maxTabSize
    if (state.maxTabSize < this.state.access.tabs.length) {
      this.commit('access/reset');
    }
    Save();
  },
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
