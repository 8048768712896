<template>
  <n-button-icon custom-icon="far fa-clone" ghost style="width: 35px;" :disabled="disabled" @on-click="$emit('on-click')" />
</template>

<script>
export default {
  name: 'NbPanel',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
