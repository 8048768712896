export default {
  label: {
    ok: 'OK',
    no: 'いいえ',
    yes: 'はい',
    hide: '隠す',
    save: '保存',
    show: '表示',
    back: 'バック',
    close: '閉じる',
    confirm: '確認',
    cancel: 'キャンセル',
    preview: 'プレビュー',
    refresh: 'リフレッシュ',
    fullScreen: '全画面へ',
    columnFilter: '列フィルター',
    listIsEmpty: 'リストが空です',
  },
};
