<template>
  <!-- prettier-ignore -->
  <Page transfer show-total show-sizer size="small" :total="total" :current="pageNo" :page-size="pageSize" :page-size-opts="[25, 50, 100]" class-name="n-table-pagination" @on-change="v => $emit('on-page-no', v)" @on-page-size-change="v => $emit('on-page-size', v)" />
</template>

<script>
export default {
  name: 'NPage',
  props: {
    total: Number,
    pageNo: Number,
    pageSize: Number,
  },
};
</script>

<style lang="scss" scoped>
@import 'style';
</style>
