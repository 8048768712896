export const RESULT = {
  DATA: 'DATA',
  CSV: 'text/csv',
  REPORT: 'application/octet-stream',
  MESSAGE: 'MESSAGE',
  SUCCESS: 'SUCCESS',
  VALIDATION: 'VALIDATION',
  APPLICATION_JSON: 'application/json',
};

export const LEVEL = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
};

export const ImageType = {
  ICON: {
    value: 8,
    suffix: 'png',
    name: 'icon',
  },

  SYMBOL: {
    value: 1,
    suffix: 'png',
    name: 'symbol',
  },

  CURRENCY: {
    value: 5,
    suffix: 'svg',
    name: 'currency',
  },
};

export const CustomerReportType = {
  DAILY_TRADE: { value: 1, suffix: 'pdf' },
  MONTHLY_TRADE: { value: 2, suffix: 'pdf' },
  ANNUAL_TRADE_PDF: { value: 3, suffix: 'pdf' },
  ANNUAL_TRADE_CSV: { value: 4, suffix: 'csv' },
};

export const SystemReportType = {
  DAILY_ORDER_PDF: { value: 1, suffix: 'pdf' },
  DAILY_TRADE_PDF: { value: 6, suffix: 'pdf' },
  DAILY_TRADE_CSV: { value: 7, suffix: 'csv' },

  DAILY_POSITION_CSV: { value: 12, suffix: 'csv' },
  DAILY_STATEMENT_PDF: { value: 21, suffix: 'pdf' },
  DAILY_STATEMENT_CSV: { value: 22, suffix: 'csv' },
  DAILY_REJECTION_PDF: { value: 26, suffix: 'pdf' },
  DAILY_SWAP_POINT_CSV: { value: 32, suffix: 'csv' },
  ANNUAL_PAYMENT_DETAIL_CSV: { value: 102, suffix: 'csv' },
  ANNUAL_PAYMENT_SUMMARY_CSV: { value: 103, suffix: 'csv' },
  DAILY_EXECUTION_DEALING_PDF: { value: 51, suffix: 'pdf' },
  DAILY_EXECUTION_DEALING_CSV: { value: 52, suffix: 'csv' },
  DAILY_EXECUTION_TRADING_PDF: { value: 53, suffix: 'pdf' },
  DAILY_EXECUTION_TRADING_CSV: { value: 54, suffix: 'csv' },
};

const AdminNoticeDomain = {
  SYSTEM: 1,
  DEALING: 2,
  BRIDGING: 3,
  HOLDING: 4,
  PRICING: 5,
  SETTING: 6,
  TRADING: 7,
};

export const AdminNoticeType = {
  DEALING_ORDER_TIMEOUT: { value: 201, domain: AdminNoticeDomain.DEALING },
  INSERT_TRADING_EXECUTION: { value: 701, domain: AdminNoticeDomain.TRADING },
  DELETE_TRADING_EXECUTION: { value: 702, domain: AdminNoticeDomain.TRADING },
  MODIFY_TRADING_EXECUTION: { value: 703, domain: AdminNoticeDomain.TRADING },
};

export const SYMBOL_SCALE_DOMAIN = {
  TRADING: 'trading',
  DEALING: 'dealing',
  IT: 'it',
};

export const CURRENCY_SCALE_NAME = {
  PROFIT: 'profitCurrency',
  BASE: 'baseCurrency',
  CURRENCY: 'currency',
  DEPOSIT: 'depositCurrency',
};

export const PricingSpreadOption = {
  S1: 1,
  S2: 2,
  S3: 3,
  S4: 4,
};

export const PricingVolumeOption = {
  V1: 1,
  V2: 2,
};

export const MarginStatus = {
  WARNING: 1,
  LOSSCUT: 2,
};

// Ken 2020-02-27 14:26 bootstrap只返回的common包下的枚举, 后台有很多的枚举是不在bootstrap中的, 所以需要的时候在前端定义一下
export const LOCAL_ENUMS = {
  BridgingResult: [
    { name: 'TIMEOUT', value: 1 },
    { name: 'OVERLOAD', value: 2 },
    { name: 'REJECTED', value: 3 },
    { name: 'DUPLICATED', value: 4 },
    { name: 'RESTRICTED', value: 5 },
    { name: 'UNAVAILABLE', value: 6 },
    { name: 'STALE_VERSION', value: 7 },
    { name: 'INVALID_ORDER', value: 11 },
    { name: 'INVALID_QUOTE', value: 12 },
    { name: 'INTERNAL_ERROR', value: 99 },
    { name: 'INVALID_REQUEST', value: 13 },
    { name: 'INVALID_SESSION', value: 14 },
    { name: 'INVALID_LIQUIDITY', value: 15 },
    { name: 'INVALID_PORTFOLIO', value: 16 },
    { name: 'INVALID_SUBSCRIPTION', value: 17 },
  ],

  DealingTransferType: [{ name: 'AUTO', value: 1 }, { name: 'MANUAL', value: 2 }],

  SystemReportTypeName: [
    { name: 'DAILY_ORDER_PDF', value: 1 },
    { name: 'DAILY_TRADE_PDF', value: 6 },
    { name: 'DAILY_TRADE_CSV', value: 7 },
    { name: 'DAILY_POSITION_CSV', value: 12 },
    { name: 'DAILY_STATEMENT_PDF', value: 21 },
    { name: 'DAILY_STATEMENT_CSV', value: 22 },
    { name: 'DAILY_REJECTION_PDF', value: 26 },
    { name: 'DAILY_SWAP_POINT_CSV', value: 32 },
    { name: 'ANNUAL_PAYMENT_DETAIL_CSV', value: 102 },
    { name: 'ANNUAL_PAYMENT_SUMMARY_CSV', value: 103 },
    { name: 'DAILY_EXECUTION_DEALING_PDF', value: 51 },
    { name: 'DAILY_EXECUTION_DEALING_CSV', value: 52 },
    { name: 'DAILY_EXECUTION_TRADING_PDF', value: 53 },
    { name: 'DAILY_EXECUTION_TRADING_CSV', value: 54 },
  ],

  CustomerReportTypeName: [
    { name: 'DAILY_TRADE', value: 1 },
    { name: 'MONTHLY_TRADE', value: 2 },
    { name: 'ANNUAL_TRADE_PDF', value: 3 },
    { name: 'ANNUAL_TRADE_CSV', value: 4 },
  ],
};

// ENUMS: include bootstrap enums & LOCAL_ENUMS
export default {};
