import { eventBus, i18n } from '@nextop/admin-glue';
import iNotice from 'view-design/src/components/notice';
import { routerTo, routerReplace } from '@/router';

export function bindEventBusHandler() {
  eventBus.on('NOTICE:WEBSOCKET_OFFLINE', ({ duration }) => {
    iNotice.warning({ duration, desc: i18n.t('tooltip.ws_offline') });
  });

  eventBus.on('RouterTo', path => {
    routerTo(path);
  });

  eventBus.on('RouterReplace', path => {
    routerReplace(path);
  });
}
