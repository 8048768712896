<template>
  <div class="n-field">
    <Tooltip v-if="show" :placement="placement" :content="content" transfer>
      <div class="validation-indicator">
        <slot />
      </div>
    </Tooltip>
    <slot v-else />
  </div>
</template>

<script>
import { isEmpty } from '@/helpers/utils';

export default {
  name: 'NtField',
  props: {
    placement: {
      type: String,
      default: 'top',
    },
    content: String,
  },
  computed: {
    show() {
      return !isEmpty(this.content);
    },
  },
};
</script>

<style lang="scss" scoped>
.n-field > div {
  width: 100%;
}

.validation-indicator:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: block;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-top: 5px solid red;
}
</style>
