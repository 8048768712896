<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-input v-if="zeroValue" value="" type="text" disabled></i-input>
      <i-input v-else v-model="displayValue" type="text" :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)" :disabled="disabled" @on-change="onChange" @on-focus="focusIn" @on-blur="focusOut" />
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldId',
  props: {
    value: [String, Number],
  },
  data() {
    return {
      displayValue: null,
      isFocusing: false,
    };
  },
  computed: {
    zeroValue() {
      return this.isDetail && (this.value === 0 || parseInt(this.value) === 0);
    },
    isModified() {
      return this.showModifyIcon && this.initValue !== (isEmpty(this.value) ? null : `${this.value}`);
    },
  },
  watch: {
    value() {
      if (!this.isFocusing) this.init();
    },
  },
  created() {
    this.initValue = isEmpty(this.value) ? null : `${this.value}`;
    this.init();
  },
  methods: {
    init() {
      if (isEmpty(this.value)) {
        this.displayValue = null;
        return;
      }
      if (typeof this.value === 'string' && this.value !== '-' && isNaN(this.value)) {
        this.displayValue = null;
        return;
      }
      this.displayValue = this.stringValue(this.value);
    },
    onChange() {
      if (!isEmpty(this.displayValue)) this.displayValue = this.displayValue.trim();
      let isNumber = this.displayValue === '-' || !isNaN(this.displayValue);
      if (isNumber) {
        this.$emit('input', this.displayValue);
        this.$emit('on-change', this.displayValue);
      } else {
        this.$nextTick(() => {
          this.displayValue = this.stringValue(this.value);
        });
      }
    },
    focusIn() {
      this.isFocusing = true;
    },
    focusOut() {
      this.isFocusing = false;
    },
    stringValue(v) {
      if (isEmpty(v)) return '';
      return typeof v === 'string' ? v : v.toString();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
