<template>
  <img :src="url" :width="width" :alt="title" :height="height" @error.once="fallback" />
</template>

<script>
import { mapState } from 'vuex';

const IMG = 'img';
export default {
  name: 'NImage',
  props: {
    type: Object,
    title: String,
    imageId: String,
    width: { type: Number, default: 39 },
    height: { type: Number, default: 22 },
  },
  computed: {
    ...mapState('config', ['imageServer']),
    url: function() {
      let { value, suffix } = this.type;
      return `${this.imageServer}/${value}/${this.imageId}.${suffix}?v=2`;
    },
    failbackUrl: function() {
      let { name, suffix } = this.type;
      return `${IMG}/${name}/${this.imageId}.${suffix}`;
    },
  },
  methods: {
    fallback(e) {
      e.target.src = this.failbackUrl;
    },
  },
};
</script>
