export const DATE_FORMAT = {
  YMD_1: {
    DETAIL: 'yyyyMMdd',
    MOMENT: 'YYYYMMDD',
  },
  YMD_2: {
    DETAIL: 'yyyy-MM-dd',
    MOMENT: 'YYYY-MM-DD',
  },
  YMD_3: {
    DETAIL: 'yyyy/MM/dd',
    MOMENT: 'YYYY/MM/DD',
  },
  HMS: {
    DETAIL: 'HH:mm:ss',
    MOMENT: 'HH:mm:ss',
  },
  HM: {
    DETAIL: 'HH:mm',
    MOMENT: 'HH:mm',
  },
  YMD_HM_1: {
    DETAIL: 'yyyyMMdd HH:mm',
    MOMENT: 'YYYYMMDD HH:mm',
  },
  YMD_HM_2: {
    DETAIL: 'yyyy-MM-dd HH:mm',
    MOMENT: 'YYYY-MM-DD HH:mm',
  },
  YMD_HM_3: {
    DETAIL: 'yyyy/MM/dd HH:mm',
    MOMENT: 'YYYY/MM/DD HH:mm',
  },
  YMD_HMS_1: {
    DETAIL: 'yyyyMMdd HH:mm:ss',
    MOMENT: 'YYYYMMDD HH:mm:ss',
  },
  YMD_HMS_2: {
    DETAIL: 'yyyy-MM-dd HH:mm:ss',
    MOMENT: 'YYYY-MM-DD HH:mm:ss',
  },
  YMD_HMS_3: {
    DETAIL: 'yyyy/MM/dd HH:mm:ss',
    MOMENT: 'YYYY/MM/DD HH:mm:ss',
  },
  YMD_HMS_S_1: {
    DETAIL: 'yyyyMMdd HH:mm:ss.SSS',
    MOMENT: 'YYYYMMDD HH:mm:ss.SSS',
  },
  YMD_HMS_S_2: {
    DETAIL: 'yyyy-MM-dd HH:mm:ss.SSS',
    MOMENT: 'YYYY-MM-DD HH:mm:ss.SSS',
  },
  YMD_HMS_S_3: {
    DETAIL: 'yyyy/MM/dd HH:mm:ss.SSS',
    MOMENT: 'YYYY/MM/DD HH:mm:ss.SSS',
  },
};
