const state = {
  data: [],
};
const getters = {};
const mutations = {
  setup(state, value) {
    state.data = value;
  },
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
