export default {
  label: {
    cc: 'CC',
    id: 'ID',
    it: 'IT',
    ip: 'IP',
    lp: 'LP',
    no: 'No',
    ok: 'OK',
    on: 'ON',
    pl: 'PL',
    s1: 'S1',
    s2: 'S2',
    s3: 'S3',
    s4: 'S4',
    to: 'To',
    v1: 'V1',
    v2: 'V2',
    ms: 'ms',
    pt: 'pt',
    pp: 'PP',
    ios: 'iOS',
    sex: 'Sex',
    dob: 'DoB',
    add: 'Add',
    app: 'App',
    buy: 'BUY',
    bcc: 'BCC',
    csv: 'CSV',
    jpy: 'JPY',
    key: 'Key',
    brId: 'LP',
    nop: 'Nop',
    off: 'OFF',
    tax: 'Tax',
    url: 'URL',
    yes: 'Yes',
    low: 'Low',
    bid: 'Bid',
    ask: 'Ask',
    ttm: 'TTM',
    fee: 'Fee',
    code: 'Code',
    zone: 'Zone',
    item: 'Item',
    city: 'City',
    fees: 'Fees',
    asia: 'Asia',
    band: 'Band',
    back: 'Back',
    book: 'Book',
    copy: 'Copy',
    edit: 'Edit',
    from: 'From',
    hide: 'Hide',
    html: 'Html',
    host: 'Host',
    icon: 'Icon',
    long: 'Long',
    lock: 'Lock',
    more: 'More',
    name: 'Name',
    read: 'Read',
    note: 'Note',
    role: 'Role',
    sell: 'SELL',
    swap: 'Swap',
    side: 'Side',
    sort: 'Sort',
    save: 'Save',
    show: 'Show',
    time: 'Time',
    type: 'Type',
    unit: 'Unit',
    view: 'View',
    weak: 'Weak',
    meta: 'Meta',
    high: 'High',
    open: 'Open',
    date: 'Date',
    less: 'Less',
    step: 'Step',
    gap1: 'Gap 1',
    gap2: 'Gap 2',
    rate: 'Rate',
    state: 'State',
    rates: 'Rates',
    books: 'Books',
    abort: 'Abort',
    agent: 'Agent',
    bookId: 'Book',
    basic: 'Basic',
    close: 'Close',
    depth: 'Depth',
    debug: 'Debug',
    error: 'Error',
    email: 'Email',
    ratio: 'Ratio',
    group: 'Group',
    image: 'Image',
    japan: 'Japan',
    login: 'Login',
    label: 'Label',
    level: 'Level',
    maker: 'Maker',
    order: 'Order',
    panel: 'Panel',
    price: 'Price',
    popup: 'Popup',
    query: 'Query',
    reset: 'Reset',
    roles: 'Roles',
    route: 'Route',
    retry: 'Retry',
    short: 'Short',
    scale: 'Scale',
    space: 'Space',
    steps: 'Steps',
    sound: 'Sound',
    stage: 'Stage',
    trade: 'Trade',
    total: 'Total',
    types: 'Types',
    taker: 'Taker',
    token: 'Token',
    value: 'Value',
    write: 'Write',
    title: 'Title',
    phone: 'Phone',
    count: 'Count',
    quote: 'Quote',
    delta: 'Delta',
    appId: 'App ID',
    bidId: 'Bid ID',
    askId: 'Ask ID',
    active: 'Active',
    action: 'Action',
    amount: 'Amount',
    cancel: 'Cancel',
    column: 'Column',
    device: 'Device',
    detail: 'Detail',
    digest: 'Digest',
    domain: 'Domain',
    email1: 'Email1',
    email2: 'Email2',
    expire: 'Expire',
    equity: 'Equity',
    filter: 'Filter',
    remark: 'Remark',
    ladder: 'Ladder',
    unlock: 'Unlock',
    logout: 'Logout',
    medium: 'Medium',
    margin: 'Margin',
    notice: 'Notice',
    profit: 'Profit',
    policy: 'Policy',
    parent: 'Parent',
    quotes: 'Quotes',
    remove: 'Remove',
    roster: 'Roster',
    charge: 'Charge',
    result: 'Result',
    reason: 'Reason',
    sender: 'Sender',
    strong: 'Strong',
    suffix: 'Suffix',
    spread: 'Spread',
    status: 'Status',
    search: 'Search',
    source: 'Source',
    symbol: 'Symbol',
    target: 'Target',
    tuning: 'Tuning',
    uptime: 'Uptime',
    volume: 'Volume',
    weight: 'Weight',
    assess: 'Assess',
    bridge: 'Bridge',
    config: 'Config',
    flurry: 'Flurry',
    resume: 'Resume',
    layout: 'Layout',
    offset: 'Offset',
    orders: 'Orders',
    reject: 'Reject',
    accept: 'Accept',
    closed: 'Closed',
    lpType: 'LP Type',
    basic1: 'Basic 1',
    basic2: 'Basic 2',
    basic3: 'Basic 3',
    basePl: 'Base PL',
    mailTo: 'Mail To',
    stepId: 'Step ID',
    spotPl: 'Spot PL',
    swapPl: 'Swap PL',
    trades: 'Trades',
    bottom: 'Bottom',
    enable: 'Enable',
    miscPl: 'Misc PL',
    minFee: 'Min Fee',
    maxFee: 'Max Fee',
    bidBrId: 'Bid LP',
    askBrId: 'Ask LP',
    variant: 'Variant',
    android: 'Android',
    account: 'Account',
    address: 'Address',
    balance: 'Balance',
    connect: 'Connect',
    carrier: 'Carrier',
    current: 'Current',
    content: 'Content',
    contact: 'Contact',
    confirm: 'Confirm',
    company: 'Company',
    comment: 'Comment',
    chinese: 'Chinese',
    dynamic: 'Dynamic',
    devices: 'Devices',
    deposit: 'Deposit',
    enabled: 'Enabled',
    english: 'English',
    execute: 'Execute',
    holiday: 'Holiday',
    history: 'History',
    gateway: 'Gateway',
    zipcode: 'Zipcode',
    pending: 'Pending',
    placing: 'Placing',
    pricing: 'Pricing',
    editing: 'Editing',
    picking: 'Picking',
    profile: 'Profile',
    preview: 'Preview',
    refresh: 'Refresh',
    restart: 'Restart',
    suspend: 'Suspend',
    symbolId: 'Symbol',
    session: 'Session',
    subject: 'Subject',
    setting: 'Setting',
    service: 'Service',
    summary: 'Summary',
    success: 'Success',
    display: 'Display',
    trading: 'Trading',
    trigger: 'Trigger',
    unstage: 'Unstage',
    version: 'Version',
    symbols: 'Symbols',
    release: 'Release',
    verbose: 'Verbose',
    message: 'Message',
    command: 'Command',
    configs: 'Configs',
    licence: 'Licence',
    dealing: 'Dealing',
    storage: 'Storage',
    product: 'Product',
    latency: 'Latency',
    closing: 'Closing',
    country: 'Country',
    wysiwyg: 'Wysiwyg',
    i18nKey: 'I18n Key',
    appName: 'App Name',
    appHome: 'App Home',
    appHost: 'App Host',
    csvPath: 'Csv Path',
    comboId: 'Combo ID',
    endTime: 'End Time',
    eodTime: 'Eod Time',
    groupId: 'Group ID',
    jobName: 'Job Name',
    longOff: 'LONG OFF',
    loginId: 'Login ID',
    matchId: 'Match ID',
    quoteId: 'Quote ID',
    replyTo: 'Reply To',
    sodTime: 'Sod Time',
    totalPl: 'Total PL',
    zipCode: 'Zip Code',
    taxRate: 'Tax Rate',
    mtLogin: 'MT Login',
    bidType: 'Bid Type',
    askType: 'Ask Type',
    ytdSwap: 'YTD Swap',
    ttlSwap: 'TTL Swap',
    visible: 'Visible',
    funding: 'Funding',
    banking: 'Banking',
    feeType: 'Fee Type',
    duration: 'Duration',
    discount: 'Discount',
    security: 'Security',
    identity: 'Identity',
    address1: 'Address1',
    address2: 'Address2',
    address3: 'Address3',
    accepted: 'Accepted',
    allocate: 'Allocate',
    customer: 'Customer',
    currency: 'Currency',
    companyId: 'Company',
    passport: 'Passport',
    bridging: 'Bridging',
    executed: 'Executed',
    holidays: 'Holidays',
    japanese: 'Japanese',
    language: 'Language',
    leverage: 'Leverage',
    maturity: 'Maturity',
    operator: 'Operator',
    position: 'Position',
    previous: 'Previous',
    property: 'Property',
    password: 'Password',
    priority: 'Priority',
    quantity: 'Quantity',
    realtime: 'Realtime',
    resource: 'Resource',
    slippage: 'Slippage',
    settings: 'Settings',
    schedule: 'Schedule',
    sessions: 'Sessions',
    services: 'Services',
    template: 'Template',
    transfer: 'Transfer',
    withdraw: 'Withdraw',
    encoding: 'Encoding',
    activity: 'Activity',
    tradable: 'Tradable',
    platform: 'Platform',
    approver: 'Approver',
    costRate: 'Cost Rate',
    feeValue: 'Fee Value',
    clientIp: 'Client IP',
    deviceId: 'Device ID',
    evalRate: 'Eval Rate',
    exitCode: 'Exit Code',
    lastName: 'Last Name',
    longSwap: 'Long Swap',
    mutateId: 'Mutate ID',
    noticeId: 'Notice ID',
    openDate: 'Open Date',
    openTime: 'Open Time',
    pageSize: 'Page Size',
    stepName: 'Step Name',
    swapType: 'Swap Type',
    swapUnit: 'Swap Unit',
    shortOff: 'SHORT OFF',
    sourceId: 'Source ID',
    tabLimit: 'Tab Limit',
    mtmQuote: 'MTM Quote',
    ttmQuote: 'TTM Quote',
    askDelta: 'Ask Delta',
    bidDelta: 'Bid Delta',
    askPrice: 'Ask Price',
    bidPrice: 'Bid Price',
    swapDate: 'Swap Date',
    cashback: 'Cashback',
    swapLoss: 'Swap Loss',
    spotLoss: 'Spot Loss',
    bankName: 'Bank Name',
    newsDate: 'News Date',
    newsTime: 'News Time',
    category: 'Category',
    twoFaOn: '2FA Enabled',
    available: 'Available',
    inputTime: 'Input Time',
    abTesting: 'A/B Testing',
    i18nValue: 'I18n Value',
    newsTitle: 'News Title',
    accountNo: 'Account No',
    commandId: 'Command ID',
    otherLoss: 'Other Loss',
    allocator: 'Allocator',
    deviation: 'Deviation',
    execution: 'Execution',
    frequency: 'Frequency',
    parameter: 'Parameter',
    recipient: 'Recipient',
    subscribe: 'Subscribe',
    threshold: 'Threshold',
    auditType: 'AuditType',
    leverages: 'Leverages',
    operation: 'Operation',
    transfers: 'Transfers',
    contracts: 'Contracts',
    exchanger: 'Exchanger',
    statement: 'Statement',
    initiator: 'Initiator',
    renewable: 'Renewable',
    countdown: 'Countdown',
    accountId: 'Account ID',
    counterId: 'Counter ID',
    comboType: 'Combo Type',
    coverRate: 'Cover Rate',
    contextId: 'Context ID',
    firstName: 'First Name',
    issueDate: 'Issue Date',
    issueTime: 'Issue Time',
    longRatio: 'Long Ratio',
    loginTime: 'Login Time',
    minVolume: 'Min Volume',
    maxVolume: 'Max Volume',
    matchRole: 'Match Role',
    matchType: 'Match Type',
    openPrice: 'Open Price',
    orderDate: 'Order Date',
    orderTime: 'Order Time',
    orderType: 'Order Type',
    placeType: 'Place Type',
    processId: 'Process ID',
    routeType: 'Route Type',
    requestId: 'Request ID',
    repeatable: 'Repeatable',
    startTime: 'Start Time',
    stageTime: 'Stage Time',
    sessionId: 'Session ID',
    shortSwap: 'Short Swap',
    selectAll: 'Select ALL',
    slEnabled: 'SL Enabled',
    tradeType: 'Trade Type',
    tpEnabled: 'TP Enabled',
    triggerId: 'Trigger ID',
    netAmount: 'Net Amount',
    chartDate: 'Chart Date',
    chartTime: 'Chart Time',
    askStatus: 'Ask Status',
    bidStatus: 'Bid Status',
    quoteDate: 'Quote Date',
    quoteTime: 'Quote Time',
    bidVolume: 'Bid Volume',
    bidExpiry: 'Bid Expiry',
    askVolume: 'Ask Volume',
    askExpiry: 'Ask Expiry',
    minEquity: 'Min Equity',
    clusterId: 'Cluster ID',
    depositId: 'Deposit ID',
    phoneCode: 'Phone Code',
    correctId: 'Correct ID',
    minAmount: 'Min Amount',
    maxAmount: 'Max Amount',
    occurTime: 'Occur Time',
    occurDate: 'Occur Date',
    startDate: 'Start Date',
    offerDate: 'Offer Date',
    offerTime: 'Offer Time',
    executeId: 'Execute ID',
    basePrice: 'Base Price',
    mailTypes: 'Mail Types',
    swiftCode: 'Swift Code',
    mtComment: 'MT Comment',
    iosItemId: 'iOS Item ID',
    currencies: 'Currencies',
    verifyMail: 'Verify Mail',
    withdrawId: 'Withdraw ID',
    spotProfit: 'Spot Profit',
    swapProfit: 'Swap Profit',
    basePlPerLot: 'Base P/L',
    conversion: 'Conversion',
    coordinate: 'Coordinate',
    commission: 'Commission',
    disconnect: 'Disconnect',
    executions: 'Executions',
    lpOrderId: 'LP Order ID',
    parameters: 'Parameters',
    privileges: 'Privileges',
    individual: 'Individual',
    expiration: 'Expiration',
    adjustment: 'Adjustment',
    generation: 'Generation',
    connection: 'Connection',
    visibility: 'Visibility',
    metatrader: 'Metatrader',
    allocation: 'Allocation',
    depositFee: 'Deposit Fee',
    registerIp: 'Register IP',
    accessTime: 'Access Time',
    amountSize: 'Amount Size',
    chargeType: 'Charge Type',
    finishDate: 'Finish Date',
    finishTime: 'Finish Time',
    customerId: 'Customer ID',
    clientType: 'Client Type',
    configType: 'Config Type',
    contractId: 'Contract ID',
    deviceTime: 'Device Time',
    expireType: 'Expire Type',
    expireTime: 'Expire Time',
    expireDate: 'Expire Date',
    fullScreen: 'Full Screen',
    freeMargin: 'Free Margin',
    hedgePrice: 'Hedge Price',
    instanceId: 'Instance ID',
    longVolume: 'Long Volume',
    logoutTime: 'Logout Time',
    lastUpdate: 'Last Update',
    mutateType: 'Mutate Type',
    middleName: 'Middle Name',
    monitorKey: 'Monitor Key',
    mobileType: 'Mobile Type',
    transaction: 'Transaction',
    normalUppercase: 'NORMAL',
    openVolume: 'Open Volume',
    openTrades: 'Open Trades',
    categoryId: 'Category ID',
    allocations: 'Allocations',
    premiumDays: 'Premium Days',
    grossAmount: 'Gross Amount',
    closeTrades: 'Close Trades',
    openAmount: 'Open Amount',
    orderTypes: 'Order Types',
    orderPrice: 'Order Price',
    quoteRatio: 'Quote Ratio',
    reportType: 'Report Type',
    rejectDate: 'Reject Date',
    rejectTime: 'Reject Time',
    swapPolicy: 'Swap Policy',
    shortRatio: 'Short Ratio',
    serverHost: 'Server Host',
    serverPath: 'Server Path',
    templateId: 'Template ID',
    tradeTypes: 'Trade Types',
    basicOrder: 'Basic Order',
    comboOrder: 'Combo Order',
    leaveOrder: 'Leave Order',
    priceScale: 'Price Scale',
    modifyType: 'Modify Type',
    modifyDate: 'Modify Date',
    modifyTime: 'Modify Time',
    cancelType: 'Cancel Type',
    cancelTime: 'Cancel Time',
    ytdBalance: 'YTD Balance',
    dbyBalance: 'DBY Balance',
    intradayPl: 'Intraday PL',
    closePrice: 'Close Price',
    frzBalance: 'FRZ Balance',
    fltBalance: 'FLT Balance',
    volumeStep: 'Volume Step',
    branchName: 'Branch Name',
    sendReport: 'Send Report',
    bankBranch: 'Bank Branch',
    description: 'Description',
    nationality: 'Nationality',
    markAsRead: 'Mark as Read',
    mailTypes1: 'Mail Types 1',
    mailTypes2: 'Mail Types 2',
    maxTabSize: 'Max Tab Size',
    iosStoreId: 'iOS Store ID',
    exchangerId: 'Exchanger ID',
    otherProfit: 'Other Profit',
    restriction: 'Restriction',
    unsubscribe: 'Unsubscribe',
    conversions: 'Conversions',
    corporation: 'Corporation',
    bankAddress: 'Bank Address',
    bookConfigs: 'Book Configs',
    counterType: 'Counter Type',
    clientAgent: 'Client Agent',
    closeOthers: 'Close Others',
    configValue: 'Config Value',
    changeGroup: 'Change Group',
    oldPassword: 'Old Password',
    deliverTime: 'Deliver Time',
    externalUrl: 'External Url',
    executeType: 'Execute Type',
    newsContent: 'News Content',
    executeTime: 'Execute Time',
    executeDate: 'Execute Date',
    executionId: 'Execution ID',
    fledgedTime: 'Fledged Time',
    issueStatus: 'Issue Status',
    stageStatus: 'Stage Status',
    marketDepth: 'Market Depth',
    messageType: 'Message Type',
    monitorName: 'Monitor Name',
    messageTime: 'Message Time',
    marginLevel: 'Margin Level',
    newPassword: 'New Password',
    openingTime: 'Opening Time',
    coordinator: 'Coordinator',
    countryCode: 'Country Code',
    countryName: 'Country Name',
    orderStatus: 'Order Status',
    orderVolume: 'Order Volume',
    orderAmount: 'Order Amount',
    orderMargin: 'Order Margin',
    requestDate: 'Request Date',
    requestStep: 'Request Step',
    requestType: 'Request Type',
    requestTime: 'Request Time',
    documentUrl: 'Document Url',
    reportTitle: 'Report Title',
    receiptTime: 'Receipt Time',
    shortVolume: 'Short Volume',
    sessionType: 'Session Type',
    sourceGroup: 'Source Group',
    transferFee: 'Transfer Fee',
    slThreshold: 'SL Threshold',
    tradeVolume: 'Trade Volume',
    tradingDate: 'Trading Date',
    triggerTime: 'Trigger Time',
    targetGroup: 'Target Group',
    triggerType: 'Trigger Type',
    tpThreshold: 'TP Threshold',
    releaseTime: 'Release Time',
    advanceSwap: 'Advance Swap',
    volumeScale: 'Volume Scale',
    correctType: 'Correct Type',
    closeVolume: 'Close Volume',
    correctDate: 'Correct Date',
    accountName: 'Account Name',
    correctTime: 'Correct Time',
    closingDate: 'Closing Date',
    closingTime: 'Closing Time',
    commandDate: 'Command Date',
    commandTime: 'Command Time',
    commandType: 'Command Type',
    openingDate: 'Opening Date',
    fledgedDate: 'Fledged Date',
    depositDate: 'Deposit Date',
    depositTime: 'Deposit Time',
    withdrawFee: 'Withdraw Fee',
    verifyPhone: 'Verify Phone',
    loginMethod: 'Login Method',
    displayOrder: 'Display Order',
    verification: 'Verification',
    abbreviation: 'Abbreviation',
    listIsEmpty: 'List is empty',
    lpExecuteId: 'LP Execute ID',
    prevSodTime: 'Prev Sod Time',
    prevEodTime: 'Prev Eod Time',
    subscription: 'Subscription',
    slOrderType: 'SL Order Type',
    tpOrderType: 'TP Order Type',
    withdrawable: 'Withdrawable',
    totalLatency: 'Total Millis',
    purchasePlan: 'Purchase Plan',
    lifetimePlan: 'Lifetime Plan',
    purchaseDate: 'Purchase Date',
    purchaseTime: 'Purchase Time',
    fledgedTypes: 'Fledged Types',
    sourceStatus: 'Source Status',
    targetStatus: 'Target Status',
    agentAccount: 'Agent Account',
    completeDate: 'Complete Date',
    completeTime: 'Complete Time',
    transferNote: 'Transfer Note',
    addExecution: 'Add Execution',
    allocateDate: 'Allocate Date',
    businessName: 'Business Name',
    cancelOrders: 'Cancel Orders',
    allocateTime: 'Allocate Time',
    allocateType: 'Allocate Type',
    allocateRole: 'Allocate Role',
    bookTransfer: 'Book Transfer',
    baseCurrency: 'Base Currency',
    columnFilter: 'Column Filter',
    customerName: 'Customer Name',
    exchangeRate: 'Exchange Rate',
    clientSystem: 'Client System',
    andOrderId: 'Android Order ID',
    configStatus: 'Config Status',
    deviceStatus: 'Device Status',
    expirePolicy: 'Expire Policy',
    executePrice: 'Execute Price',
    filterPolicy: 'Filter Policy',
    monitorLevel: 'Monitor Level',
    messageToken: 'Message Token',
    messageTypes: 'Message Types',
    marginStatus: 'Margin Status',
    orderComment: 'Order Comment',
    openPosition: 'Open Position',
    pickingDepth: 'Picking Depth',
    pickingSpace: 'Picking Space',
    verifySelfie: 'Verify Selfie',
    resumeFilter: 'Resume Filter',
    rejectResult: 'Reject Result',
    rejectReason: 'Reject Reason',
    receiptRoute: 'Receipt Route',
    spreadPolicy: 'Spread Policy',
    spreadOffset: 'Spread Offset',
    tuningPolicy: 'Tuning Policy',
    unrealizedPl: 'Unrealized PL',
    mobileConfig: 'Mobile Config',
    resetWebView: 'Reset WebView',
    contractSize: 'Contract Size',
    quoteStorage: 'Quote Storage',
    executeDelay: 'Process Queue',
    transferTime: 'Transfer Time',
    transferDate: 'Transfer Date',
    closedVolume: 'Closed Volume',
    withdrawDate: 'Withdraw Date',
    withdrawTime: 'Withdraw Time',
    executeQuote: 'Execute Quote',
    tradingScale: 'Trading Scale',
    dealingScale: 'Dealing Scale',
    minRoundTrip: 'Min Round Trip',
    convertRate1: 'Convert Rate 1',
    convertRate2: 'Convert Rate 2',
    commandStatus: 'CommandStatus',
    bottomEnabled: 'Bottom Enabled',
    sourceAccount: 'Source Account',
    targetAccount: 'Target Account',
    verifyAddress: 'Verify Address',
    messageCookie: 'Message Cookie',
    inventoryDate: 'Inventory Date',
    inventoryTime: 'Inventory Time',
    clientVersion: 'Client Version',
    sentimentTime: 'Sentiment Time',
    dealingOrders: 'Dealing Orders',
    executionDate: 'Execution Date',
    executionTime: 'Execution Time',
    executeVolume: 'Execute Volume',
    executeResult: 'Execute Result',
    executeAmount: 'Execute Amount',
    jobParameters: 'Job Parameters',
    messageStatus: 'Message Status',
    messageResult: 'Message Result',
    monitorPolicy: 'Monitor Policy',
    pickingPolicy: 'Picking Policy',
    pickingConfig: 'Picking Config',
    pendingVolume: 'Pending Volume',
    quoteQuantity: 'Quote Quantity',
    rejectComment: 'Reject Comment',
    receiptStatus: 'Receipt Status',
    readPrivilege: 'Read Privilege',
    statementDate: 'Statement Date',
    statementTime: 'Statement Time',
    triggerStatus: 'Trigger Status',
    askAdjustment: 'Ask Adjustment',
    bidAdjustment: 'Bid Adjustment',
    marginFormula: 'Margin Formula',
    andStoreId: 'Android Store ID',
    variantPrefer: 'Variant Prefer',
    bottomBgColor: 'Bottom Bg Color',
    beneficiaryBankName: 'Bank Name',
    releaseStatus: 'Release Status',
    mobileRelease: 'Mobile Release',
    losscutPolicy: 'Losscut Policy',
    orderCurrency: 'Order Currency',
    quoteTradable: 'Quote Tradable',
    closingVolume: 'Closing Volume',
    deliverMillis: 'Deliver Millis',
    processMillis: 'Process Millis',
    respondMillis: 'Respond Millis',
    enqueueMillis: 'Enqueue Millis',
    persistMillis: 'Persist Millis',
    frozenBalance: 'Frozen Balance',
    companyConfig: 'Company Config',
    correctStatus: 'Correct Status',
    correctResult: 'Correct Result',
    sentryVerbose: 'Sentry Verbose',
    noticeSubject: 'Notice Subject',
    noticeComment: 'Notice Comment',
    challengeCode: 'Challenge Code',
    challengeTime: 'Challenge Time',
    coQuoteFilter: 'CO Quote Filter',
    expirationDate: 'Expiration Date',
    expirationTime: 'Expiration Time',
    transactionRef: 'Transaction Ref',
    beneficiarySwiftCode: 'SwiftCode',
    verifyPassport: 'Verify Passport',
    verifyIdentity: 'Verify Identity',
    enableReadonly: 'Enable Readonly',
    exchangeAmount: 'Exchange Amount',
    allocateStatus: 'Allocate Status',
    allocateProfit: 'Allocate Profit',
    allocateVolume: 'Allocate Volume',
    allocateAmount: 'Allocate Amount',
    profitCurrency: 'Profit Currency',
    changePassword: 'Change Password',
    closePositions: 'Close Positions',
    closeSideSize: 'Close(side) Size',
    unrealizedSpot: 'Unrealized Spot',
    unrealizedSwap: 'Unrealized Swap',
    commissionLoss: 'Commission Loss',
    daylightSaving: 'Daylight Saving',
    executeComment: 'Execute Comment',
    emergencyCover: 'Emergency Cover',
    messageContent: 'Message Content',
    readPrivileges: 'Read Privileges',
    rolePrivileges: 'Role Privileges',
    requiredMargin: 'Required Margin',
    tradeableQuote: 'Tradeable Quote',
    writePrivilege: 'Write Privilege',
    unrealizedComm: 'Unrealized Comm',
    requestTimeout: 'Request Timeout',
    commissionType: 'Commission Type',
    commissionUnit: 'Commission Unit',
    tradablePolicy: 'Tradable Policy',
    assessCurrency: 'Assess Currency',
    bridgeCurrency: 'Bridge Currency',
    quoteFrequency: 'Quote Frequency',
    validateMillis: 'Validate Millis',
    postponeMillis: 'Postpone Millis',
    notionalAmount: 'Notional Amount',
    variantARatio: 'Variant A Ratio',
    companyBankId: 'Company Bank ID',
    sourcePlatform: 'Source Platform',
    targetPlatform: 'Target Platform',
    availableAfter: 'Available After',
    customerBankId: 'Customer Bank ID',
    coverAITrigger: 'Cover AI Trigger',
    beneficiaryAccountNo: 'Account No',
    confirmDeposit: 'Confirm Deposit',
    secondsFromGmt: 'Seconds From Gmt',
    storeProductId: 'Store Product ID',
    iosReceiptData: 'iOS Receipt Data',
    defaultCurrency: 'Default Currency',
    backgroundImage: 'Background Image',
    verifySignature: 'Verify Signature',
    confirmWithdraw: 'Confirm Withdraw',
    accumulatedSwap: 'Accumulated Swap',
    systemProtoVersion: 'System Proto',
    marketProtoVersion: 'Market Proto',
    maxActiveOrder: 'Max Active Order',
    convertSymbol1: 'Convert Symbol 1',
    convertSymbol2: 'Convert Symbol 2',
    businessLicense: 'Business License',
    confirmPassword: 'Confirm Password',
    conversionRate1: 'Conversion Rate1',
    conversionRate2: 'Conversion Rate2',
    daylightSavings: 'Daylight Savings',
    liquidityPolicy: 'Liquidity Policy',
    operatorSetting: 'Operator Setting',
    operationFailed: 'Operation Failed',
    orderAllocation: 'Order Allocation',
    writePrivileges: 'Write Privileges',
    quoteExpiration: 'Quote Expiration',
    quoteGeneration: 'Quote Generation',
    depositCurrency: 'Deposit Currency',
    noticeRecipient: 'Notice Recipient',
    counterCurrency: 'Counter Currency',
    discountEnabled: 'Discount Enabled',
    discountPercent: 'Discount Percent',
    discountBgImage: 'Discount Bg Image',
    appAccountToken: 'App Account Token',
    freeMarginRatio: 'Free Margin Ratio',
    retryAttempt2fa: 'Retry Attempt 2FA',
    beneficiaryBankBranch: 'Bank Branch',
    retryAttemptPwd: 'Retry Attempt Pwd',
    allBookPosition: 'All Book Position',
    maxTabSizePlaceholder: 'Range: 1~16',
    sourceAccountId: 'Source Account ID',
    pleaseSelectLP: 'Please select a LP',
    prevTradingDate: 'Prev Trading Date',
    companyProtoVersion: 'Company Proto',
    pricingProtoVersion: 'Pricing Proto',
    enableChangePwd: 'Enable Change Pwd',
    tradingProtoVersion: 'Trading Proto',
    transactionProof: 'Transaction Proof',
    quotationSetting: 'Quotation Setting',
    contractCurrency: 'Contract Currency',
    iosTransactionId: 'iOS Transaction ID',
    indiFledgedTypes: 'Indi Fledged Types',
    corpFledgedTypes: 'Corp Fledged Types',
    retryAttemptTime: 'Retry Attempt Time',
    countdownEnabled: 'Countdown Enabled',
    exchangeCurrency: 'Exchange Currency',
    allocatorConfigs: 'Allocator Configs',
    highAvailability: 'High Availability',
    bridgingCommands: 'Bridging Commands',
    loginRestriction: 'Login Restriction',
    pleaseSelectIT: 'Please select an IT',
    profitConversion: 'Profit Conversion',
    marginConversion: 'Margin Conversion',
    convertOperation: 'Convert Operation',
    bottomButtonText: 'Bottom Button Text',
    beneficiaryAccountName: 'Account Name',
    beneficiaryBankAddress: 'Bank Address',
    minTradingVolume: 'Min Trading Volume',
    maxTradingVolume: 'Max Trading Volume',
    minLeavingOffset: 'Min Leaving Offset',
    maxLeavingOffset: 'Max Leaving Offset',
    minDepositAmount: 'Min Deposit Amount',
    maxDepositAmount: 'Max Deposit Amount',
    orderMarginRatio: 'Order Margin Ratio',
    customerProtoVersion: 'Customer Proto',
    freeMarginPolicy: 'Free Margin Policy',
    countdownBgColor: 'Countdown Bg Color',
    sourceCategoryId: 'Source Category ID',
    afterRegistration: 'After Registration',
    availableDuration: 'Available Duration',
    countdownDuration: 'Countdown Duration',
    closeToRight: 'Close tabs to the right',
    marginRestriction: 'Margin Restriction',
    quoteSubscription: 'Quote Subscription',
    resetConfirmation: 'Reset Confirmation',
    heartbeatInterval: 'Heartbeat Interval',
    losscutExpiration: 'Losscut Expiration',
    maxBridgingVolume: 'Max Bridging Volume',
    pleaseSelectBook: 'Please select a book',
    maxConnectRetries: 'Max Connect Retries',
    maxPositionVolume: 'Max Position Volume',
    hedgedMarginRatio: 'Hedged Margin Ratio',
    maxActiveContract: 'Max Active Contract',
    minTransferAmount: 'Min Transfer Amount',
    maxTransferAmount: 'Max Transfer Amount',
    minWithdrawAmount: 'Min Withdraw Amount',
    maxWithdrawAmount: 'Max Withdraw Amount',
    maxRestableVolume: 'Max Restable Volume',
    minTradableEquity: 'Min Tradable Equity',
    minRequiredEquity: 'Min Required Equity',
    dealingRestriction: 'Dealing Restriction',
    pricingRestriction: 'Pricing Restriction',
    placingRestriction: 'Placing Restriction',
    removeConfirmation: 'Remove Confirmation',
    tradingRestriction: 'Trading Restriction',
    editingRestriction: 'Editing Restriction',
    strictSubscription: 'Strict Subscription',
    daylightSavingType: 'Daylight Saving Type',
    sourceCompleteDate: 'Source Complete Date',
    targetCompleteDate: 'Target Complete Date',
    sourceCompleteTime: 'Source Complete Time',
    targetCompleteTime: 'Target Complete Time',
    minConnectInterval: 'Min Connect Interval',
    maxConnectInterval: 'Max Connect Interval',
    chartIterationStep: 'Chart Iteration Step',
    quotePanelCapacity: 'Quote Panel Capacity',
    marginRatioLosscut: 'Margin Ratio Losscut',
    marginRatioWarning: 'Margin Ratio Warning',
    maxPortfolioVolume: 'Max Portfolio Volume',
    andPurchaseToken: 'Android Purchase Token',
    minTradingInterval: 'Min Trading Interval',
    initialExecuteDate: 'Initial Execute Date',
    initialDepositDate: 'Initial Deposit Date',
    bridgingRestriction: 'Bridging Restriction',
    restartConfirmation: 'Restart Confirmation',
    transferredQuantity: 'Transferred Quantity',
    transferRestriction: 'Transfer Restriction',
    initialWithdrawDate: 'Initial Withdraw Date',
    dealingPanelSetting: 'Dealing Panel Setting',
    pleaseSelectDomain: 'Please select a domain',
    pleaseSelectSymbol: 'Please select a symbol',
    contractMarginRatio: 'Contract Margin Ratio',
    scheduleConfirmation: 'Schedule Confirmation',
    unrealizedCommission: 'Unrealized Commission',
    agentMinDepositAmount: 'Agent Min Dep Amount',
    agentMaxDepositAmount: 'Agent Max Dep Amount',
    agentMinWithdrawAmount: 'Agent Min Wdr Amount',
    agentMaxWithdrawAmount: 'Agent Max Wdr Amount',
    pleaseSelectCompany: 'Please select a company',
    chartInitialCapacity: 'Chart Initial Capacity',
    chartMaximumCapacity: 'Chart Maximum Capacity',
    tradingIterationStep: 'Trading Iteration Step',
    requiredMarginPolicy: 'Required Margin Policy',
    stopOrderPricePolicy: 'Stop Order Price Policy',
    pleaseInputTriggerID: 'Please input customer ID',
    sentimentDetailTitle: '{name} Sentiment Detail',
    resetConfirmationText: 'Are you sure to reset ?',
    pleaseInputCustomerID: 'Please input customer ID',
    limitOrderPricePolicy: 'Limit Order Price Policy',
    tradingRefreshInterval: 'Trading Refresh Interval',
    iosOriginalTransactionId: 'iOS Orig TransactionId',
    restartConfirmationText: 'Are you sure to restart ?',
    scheduleConfirmationText: 'Do you want to schedule job :',
    instantOrderInterestPolicy: 'Instant Order Interest Policy',
    removeConfirmationText: 'Are you sure to remove this record ?',
  },
};
