<template>
  <!-- prettier-ignore -->
  <Poptip trigger="hover" :placement="placement" :width="width" padding="4px 4px" transfer>
    <Icon custom="far fa-question-circle" color="#5cadff" class="action-help" />
    <div slot="content">
      <table class="table table-bordered action-help-table">
        <thead>
          <tr>
            <th v-for="title in titles" :key="title">{{ title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="index">
            <td v-for="(column, cIndex) in row.columns" :key="cIndex" :style="{ textAlign: row.center.indexOf(cIndex) !== -1 ? 'center' : 'left' }">{{ column }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Poptip>
</template>

<script>
export default {
  name: 'NHelpPopover',
  props: {
    placement: {
      type: String,
      default: 'left',
    },
    titles: Array,
    rows: Array,
    width: Number,
  },
};
</script>

<style lang="scss" scoped>
.action-help {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 4px;
}

.action-help-table {
  margin: 0;
  font-size: 12px;

  thead {
    text-align: center;
    background-color: #80cbc4;
  }

  th,
  td {
    margin: 0;
    padding: 0 4px;
  }
}
</style>
