export default {
  name: 'AudioMixin',

  data() {
    return {
      audio: null,
      mute: false, // 会被正常组件的属性overwrite
      isActivated: false,
    };
  },

  mounted() {
    this.isActivated = true;
  },

  activated() {
    this.isActivated = true;
  },

  deactivated() {
    this.isActivated = false;
    this.stopAudio();
  },

  beforeDestroy() {
    this.stopAudio();
  },

  methods: {
    playAudio() {
      if (this.audio) {
        // Ken 2019-12-27 12:46 未静音 且当前组件激活的时候, 才播放
        if (!this.mute && this.isActivated) {
          this.audio.play();
        }
      } else {
        console.error('AudioMixin, no audio');
      }
    },
    stopAudio() {
      if (this.audio) {
        this.audio.stop();
      } else {
        console.error('AudioMixin, no audio');
      }
    },
  },
};
