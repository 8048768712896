export default [
  {
    path: '/company/product',
    meta: {
      name: 'company.product',
      cacheName: 'CompanyProduct',
    },
    component: () => import('@/views/index/company/CompanyProduct'),
  },
  {
    path: '/company/news',
    meta: {
      name: 'company.news',
      cacheName: 'CompanyNews',
    },
    component: () => import('@/views/index/company/CompanyNews'),
  },
  {
    path: '/company/group',
    meta: {
      name: 'company.group',
      cacheName: 'CompanyGroup',
    },
    component: () => import('@/views/index/company/CompanyGroup'),
  },
  {
    path: '/company/translation',
    meta: {
      name: 'company.translation',
      cacheName: 'CompanyTranslation',
    },
    component: () => import('@/views/index/company/CompanyTranslation'),
  },
  {
    path: '/company/landing_page',
    meta: {
      name: 'company.landing_page',
      cacheName: 'CompanyLandingPage',
    },
    component: () => import('@/views/index/company/CompanyLandingPage'),
  },
  {
    path: '/company/assignment',
    meta: {
      name: 'company.assignment',
      cacheName: 'CompanyAssignment',
    },
    component: () => import('@/views/index/company/CompanyAssignment'),
  },
  {
    path: '/company/mobile',
    meta: {
      name: 'company.mobile',
      cacheName: 'CompanyMobile',
    },
    component: () => import('@/views/index/company/CompanyMobile'),
  },
  {
    path: '/company/company',
    meta: {
      name: 'company.company',
      cacheName: 'CompanyCompany',
    },
    component: () => import('@/views/index/company/CompanyCompany'),
  },
  {
    path: '/company/company/:companyId/configs',
    meta: {
      name: 'company.company',
      submenu: true,
      cacheName: ['CompanyCompany', 'CompanyCompanyConfig'],
    },
    component: () => import('@/views/index/company/CompanyCompanyConfig'),
  },
  {
    path: '/company/company/:companyId/currencies',
    meta: {
      name: 'company.company',
      submenu: true,
      cacheName: ['CompanyCompany', 'CompanyCompanyCurrency'],
    },
    component: () => import('@/views/index/company/CompanyCompanyCurrency'),
  },
  {
    path: '/company/company/:companyId/allocations',
    meta: {
      name: 'company.company',
      submenu: true,
      cacheName: ['CompanyCompany', 'CompanyCompanyAllocation'],
    },
    component: () => import('@/views/index/company/CompanyCompanyAllocation'),
  },
  {
    path: '/company/company/:companyId/holidays',
    meta: {
      name: 'company.company',
      submenu: true,
      cacheName: ['CompanyCompany', 'CompanyCompanyHoliday'],
    },
    component: () => import('@/views/index/company/CompanyCompanyHoliday'),
  },
  {
    path: '/company/category',
    meta: {
      name: 'company.category',
      cacheName: 'CompanyCategory',
    },
    component: () => import('@/views/index/company/CompanyCategory'),
  },
];
