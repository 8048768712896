import Vue from 'vue';
import { TYPES } from '@/constant/bitwise';

Vue.directive('mask-enums', function(el, binding, vnode) {
  let option = binding.value;
  let v = option.value;

  if (v === 0) {
    el.textContent = '-';
    return;
  }
  // hash
  let name = option.name ? option.name : undefined;

  let names = [];
  let options = TYPES[name] || {};
  let keys = Object.keys(options);
  let threshold = option.threshold ? option.threshold : keys.length;
  keys.forEach(k => {
    if (parseInt(v & options[k]) !== 0) {
      names.push(k);
    }
  });

  let r = names.splice(0, threshold);
  el.title = r.join(',');
  el.textContent = keys.length !== threshold ? r.join(',') + ', ...' : r.join(',');
});
