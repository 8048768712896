<template>
  <div :id="`n-panel-${id}`">
    <!--window-->
    <div class="n-panel" :class="{ 'n-no-select n-will-change': isDragging }" :style="panelStyle" @click="onClickPanel">
      <div ref="header" class="n-panel-header" @dblclick="onToggleFullscreen" @mousedown="onDragMouseDown">
        <Row type="flex">
          <Col style="flex: 1">
            <div v-if="!$slots.header" class="n-panel-header-title">
              {{ title }}
            </div>
            <slot name="header"></slot>
          </Col>
          <Col v-if="hasHeaderAction">
            <Row type="flex" :gutter="8">
              <Col v-if="config.minimizable">
                <div class="n-panel-menu-action" @click="onMinimize">
                  <Icon type="md-remove" style="transform: translateY(3px);" />
                </div>
              </Col>
              <Col v-for="item in headerActions" :key="item.icon">
                <div class="n-panel-menu-action" @click="item.click">
                  <i :class="item.icon" />
                </div>
              </Col>
              <Col v-if="config.maximizable">
                <div class="n-panel-menu-action" @click="onToggleFullscreen">
                  <Icon :type="isFullscreen ? 'md-contract' : 'md-expand'" />
                </div>
              </Col>
              <Col v-if="config.closeable">
                <div class="n-panel-menu-action" @click.stop="onClose">
                  <Icon type="md-close" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div class="n-panel-body" :style="bodyStyle">
        <slot></slot>
      </div>

      <div
        v-if="hasFooter"
        ref="footer"
        class="n-panel-footer text-right"
        @dblclick="onToggleFullscreen"
        @mousedown="onDragMouseDown"
      >
        <slot name="footer"></slot>
      </div>

      <div v-if="config.resizable" class="resize-handler">
        <div class="n-panel-resize-handler n-panel-resize-handler-t" @mousedown.stop="onResizeMouseDown($event, 't')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-r" @mousedown.stop="onResizeMouseDown($event, 'r')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-l" @mousedown.stop="onResizeMouseDown($event, 'l')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-b" @mousedown.stop="onResizeMouseDown($event, 'b')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-tl" @mousedown.stop="onResizeMouseDown($event, 'tl')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-tr" @mousedown.stop="onResizeMouseDown($event, 'tr')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-bl" @mousedown.stop="onResizeMouseDown($event, 'bl')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-br" @mousedown.stop="onResizeMouseDown($event, 'br')"></div>
      </div>
    </div>

    <!--minimized window  Ken 2020-01-07 19:54 be above of panel -->
    <n-panel-minimized-window v-if="config.minimized" :id="id" :style="{ left: `${leftOrder * 202}px`, zIndex: BASE_ZINDEX }">
      <div v-if="!$slots.header" class="n-panel-header-title">
        {{ title }}
      </div>
      <slot name="header"></slot>
    </n-panel-minimized-window>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { get, find, findIndex, filter, sortBy } from 'lodash-es';
import NPanelMinimizedWindow from './NPanelMinimizedWindow.vue';

function GetClientBounding() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { width, height };
}

const VectorPair = [['top', 'y'], ['left', 'x'], ['width', 'x'], ['height', 'y']];

// array order: top, left, width, height
// 1: add, 0: ignore, -1: mines
const DirectionConfig = {
  t: [1, 0, 0, -1],
  tl: [1, 1, -1, -1],
  tr: [1, 0, 1, -1],
  l: [0, 1, -1, 0],
  r: [0, 0, 1, 0],
  b: [0, 0, 0, 1],
  bl: [0, 1, -1, 1],
  br: [0, 0, 1, 1],
};

const OFFSET = 40; // limitation to edge

export default {
  name: 'NPanel',

  components: { NPanelMinimizedWindow },

  props: {
    id: String,
    title: String,
    hasHeaderAction: { type: Boolean, default: true },
    headerActions: Array,
  },

  data() {
    return {
      isDragging: false,
      isFullscreen: false,
      BASE_ZINDEX: 1000,
    };
  },

  computed: {
    ...mapState({ panelList: s => s.panel.list }),
    ...mapGetters('panel', ['findPanel']),

    config() {
      return this.findPanel({ id: this.id }) || {};
    },

    leftOrder() {
      const minimizedList = filter(this.panelList, { minimized: true });
      const sortedList = sortBy(minimizedList, ['displayOrder'], ['asc']);
      return findIndex(sortedList, { id: this.id });
    },

    panelStyle() {
      return {
        left: `${this.config.left}px`,
        top: `${this.config.top}px`,
        display: this.config.minimized ? 'none' : 'block',
        zIndex: this.BASE_ZINDEX + this.config.displayOrder,
      };
    },

    bodyStyle() {
      return {
        width: `${this.config.width}px`,
        height: `${this.config.height}px`,
      };
    },

    hasFooter() {
      return this.$scopedSlots.footer;
    },

    headerHeight() {
      return this.$refs.header?.clientHeight ?? 0;
    },

    footerHeight() {
      return this.$refs.footer?.clientHeight ?? 0;
    },
  },

  methods: {
    ...mapMutations('panel', ['close']),

    setAttrs(config) {
      this.$store.commit('panel/setAttrs', { id: this.id, ...config });
    },

    moveToTop() {
      this.$store.commit('panel/moveToTop', this.id);
    },

    close() {
      this.$store.commit('panel/close', this.id);
    },

    onDragMouseDown(downEvent) {
      this.moveToTop();

      this.isDragging = true;

      const offset = {
        x: downEvent.clientX - (this.config.left || 0),
        y: downEvent.clientY - (this.config.top || 0),
      };

      /**
       * Ken: Reason put logic here, not panel.js in store:
       *  prevent `reflow` from calling `window.innerWidth` everytime
       * */
      const SPACE_LIMITATION = {
        TOP: 0,
        RIGHT: window.innerWidth - OFFSET,
        LEFT: OFFSET - this.config.width,
        BOTTOM: window.innerHeight - OFFSET,
      };

      const onMouseMove = moveEvent => {
        let left = moveEvent.clientX - offset.x;
        let top = moveEvent.clientY - offset.y;

        top = Math.max(SPACE_LIMITATION.TOP, Math.min(top, SPACE_LIMITATION.BOTTOM));
        left = Math.max(SPACE_LIMITATION.LEFT, Math.min(left, SPACE_LIMITATION.RIGHT));

        this.setAttrs({ top, left });
      };

      const onMouseUp = upEvent => {
        this.isDragging = false;

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },

    onResizeMouseDown(downEvent, direction) {
      this.isDragging = true;

      const origin = {
        x: downEvent.clientX,
        y: downEvent.clientY,
        top: this.config.top || 0,
        left: this.config.left || 0,
        width: this.config.width || 0,
        height: this.config.height || 0,
      };

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const onMouseMove = moveEvent => {
        const mouseX = Math.max(Math.min(moveEvent.clientX, windowWidth - OFFSET), OFFSET);
        const mouseY = Math.max(Math.min(moveEvent.clientY, windowHeight - OFFSET), OFFSET);

        const offset = {
          x: mouseX - origin.x,
          y: mouseY - origin.y,
        };

        const config = {};

        const directionConfig = DirectionConfig[direction];

        VectorPair.forEach((vector, index) => {
          if (directionConfig[index]) {
            config[vector[0]] = origin[vector[0]] + offset[vector[1]] * directionConfig[index];
          }
        });

        this.setAttrs(config);
      };

      const onMouseUp = upEvent => {
        this.isDragging = false;

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },

    onClickPanel() {
      this.moveToTop();
    },

    onClose() {
      this.close();
    },

    onMinimize() {
      this.setAttrs({ minimized: true });
    },

    onToggleFullscreen() {
      if (!this.config.maximizable) {
        return;
      }
      this.isFullscreen = !this.isFullscreen;

      const config = {};
      if (this.isFullscreen) {
        const { height: clientHeight } = GetClientBounding();

        Object.assign(config, {
          previousLeft: this.config.left,
          previousTop: this.config.top,
          previousWidth: this.config.width,
          previousHeight: this.config.height,
          left: 0,
          top: 0,
          width: document.body.clientWidth,
          height: clientHeight - this.headerHeight - this.footerHeight - 4,
        });
      } else {
        Object.assign(config, {
          left: this.config.previousLeft,
          top: this.config.previousTop,
          width: this.config.previousWidth,
          height: this.config.previousHeight,
        });
      }
      this.setAttrs(config);
    },
  },
};
</script>

<style lang="scss">
$panelRadius: 0.4rem;

.n-panel-menu-action {
  border-radius: 5px;
  padding: 1px 3px;
  cursor: pointer;
}

.n-panel-menu-action:hover {
  background-color: #ddd;
}

.n-panel-header-title {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 2px;
  padding-left: 4px;
}

.n-panel {
  position: fixed;
  border: 1px solid #ccc;
  border-radius: $panelRadius;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  font-size: 0.7rem;

  &-header {
    border-top-left-radius: $panelRadius;
    border-top-right-radius: $panelRadius;
    border-bottom: 1px solid #ddd;
    background-color: #f4f3f3;
    padding: 0.2rem 0.4rem;
    font-size: 0.9rem;
    cursor: move;
  }

  &-body {
    padding: 0.5rem;
    overflow: auto;
    position: relative;
  }

  &-footer {
    min-height: 1rem;
    border-top: 1px solid #ddd;
    border-bottom-left-radius: $panelRadius;
    border-bottom-right-radius: $panelRadius;
    background-color: #f4f3f3;
    padding: 0.2rem 0.4rem;
    cursor: move;
  }

  $offset: 0.4rem;

  &-resize-handler {
    position: absolute;
    background-color: #777777;

    &-t {
      cursor: n-resize;
      top: -$offset;
      left: $offset;
      right: $offset;
      height: 2 * $offset;
      background-color: transparent;
    }

    &-tl {
      cursor: nw-resize;
      top: -$offset;
      left: -$offset;
      width: 2 * $offset;
      height: 2 * $offset;
      background-color: transparent;
    }

    &-tr {
      cursor: ne-resize;
      top: -$offset;
      right: -$offset;
      width: 2 * $offset;
      height: 2 * $offset;
      background-color: transparent;
    }

    &-l {
      cursor: w-resize;
      top: $offset;
      left: -$offset;
      bottom: $offset;
      width: 2 * $offset;
      background-color: transparent;
    }

    &-r {
      cursor: e-resize;
      top: $offset;
      right: -$offset;
      bottom: $offset;
      width: 2 * $offset;
      background-color: transparent;
    }

    &-b {
      cursor: s-resize;
      bottom: -$offset;
      left: $offset;
      right: $offset;
      height: 2 * $offset;
      background-color: transparent;
    }

    &-bl {
      cursor: sw-resize;
      bottom: -$offset;
      left: -$offset;
      width: 2 * $offset;
      height: 2 * $offset;
      background-color: transparent;
    }

    &-br {
      cursor: se-resize;
      bottom: -$offset;
      right: -$offset;
      width: 2 * $offset;
      height: 2 * $offset;
      background-color: transparent;
    }
  }
}
</style>
