<script>
import { mapGetters } from 'vuex';
import { NoticeChannel } from '@/services';
import { beanCopy, isEmpty } from '@/helpers/utils';
import { operatorNoticeAudio } from '@/helpers/audio';
import _ from 'lodash-es';

export default {
  name: 'NoticeMixin',
  data() {
    return {
      notices: [],
      timer: '',
    };
  },
  computed: {
    ...mapGetters('menu', ['hasOperatorNoticeReadPrivilege']),
    ...mapGetters('preference', ['popup', 'sound']),
    noticeLevels() {
      let r = [];
      Object.keys(this.$enums.AdminNoticeLevel).forEach(t => {
        let name = t.toLowerCase();
        if (name === 'warn') name = 'warning';
        r.push({ name, value: this.$enums.AdminNoticeLevel[t] });
      });
      return r;
    },
  },
  mounted() {
    if (!this.hasOperatorNoticeReadPrivilege) return;
    NoticeChannel.subscribe(this.onNotice);
    this.timer = setInterval(this.handleNotices, 1000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer);
    });
  },
  beforeDestroy() {
    NoticeChannel.unsubscribe(this.onNotice);
  },
  methods: {
    onNotice(notice) {
      if (isEmpty(notice)) return;
      this.notices.push(beanCopy(notice));
    },
    handleNotices() {
      _.remove(this.notices, notice => {
        return notice.status === this.$enums.AdminOperatorNoticeStatus.ACCEPTED;
      });
      if (isEmpty(this.notices)) return;
      let notice = this.notices[0];
      if (this.sound && notice.sound !== this.$enums.AdminNoticeSound.SILENCE) operatorNoticeAudio.play(notice.sound);
      if (this.popup && notice.popup) {
        let type = this.noticeLevels.find(f => f.value === notice.level).name;
        this.$Notice[type]({ title: notice.subject });
      }
      notice.status = this.$enums.AdminOperatorNoticeStatus.ACCEPTED;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-notice {
  width: auto !important;

  .ivu-notice-notice-content {
    margin-right: 10px !important;
  }
}
</style>
