<template>
  <!-- prettier-ignore -->
  <div>
    <n-field-editor v-if="html" :value="htmlContent" :field-name="fieldName" :label="label" :error="error" :disabled="disabled" no-modified-icon @on-change="changeContent" />
    <n-field-textarea v-else :value="textContent" :rows="10" :field-name="fieldName" :label="label" :error="error" :disabled="disabled" no-modified-icon @on-change="changeContent" />
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2 mt-1">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </div>
</template>

<script>
import _ from 'lodash-es';
import { isEmpty } from '@/helpers/utils';

export default {
  name: 'NTextEditor',
  inject: {
    nModal: {
      from: 'nModal',
      default: null,
    },
  },
  props: {
    value: String,
    html: Boolean,
    fieldName: String,
    label: String,
    error: String,
    disabled: Boolean,
    textareaRows: {
      type: Number,
      default: 15,
    },
    editorHeight: {
      type: Number,
      default: 290,
    },
  },
  data() {
    return {
      initValue: null,
      htmlContent: null,
      textContent: null,
    };
  },
  computed: {
    isEdit() {
      return _.get(this.nModal, 'isEdit', false);
    },
    isPreview() {
      return _.get(this.nModal, 'preview', false);
    },
    isModified() {
      return this.isEdit && this.isPreview && this.initValue !== this.value;
    },
  },
  watch: {
    value() {
      this.init();
    },
    html() {
      this.init();
    },
  },
  created() {
    this.initValue = this.value;
    this.init();
  },
  methods: {
    init() {
      if (this.html) {
        this.htmlContent = this.transferHtml(this.value);
        this.textContent = null;
      } else {
        this.textContent = this.transferHtml(this.value);
        this.htmlContent = null;
      }
    },
    changeContent(v) {
      let t = this.transferHtml(v);
      if (this.html) this.htmlContent = t;
      else this.textContent = t;
      this.$emit('input', t);
    },
    transferHtml(v) {
      return isEmpty(v) ? v : v.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    },
  },
};
</script>

<style lang="scss" scoped>
.ivu-form-item {
  width: calc(100% - 24px);
  display: inline-block;
}
</style>
