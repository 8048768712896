import Vue from 'vue';
import i18n from '@/locale/i18n';
import { getSelection } from '@/store';
import { last, split, trim } from 'lodash-es';
import {formatDate, formatDateTimezone, formatDuration, formatFromNow} from '@/helpers/date';
import { numberFormat, percent } from '@/helpers/number';

Vue.filter('filterDate', (timestamp, format) => {
  return formatDate(timestamp, format);
});

Vue.filter('hyphen', (v, strict = true, replacement = '-') => {
  // Ken 2019-11-18 15:22 trim(null | undefined) = ""; trim(number) = String(number)
  return !trim(v) || (strict && (v === 0 || v === '0')) ? replacement : v;
});

Vue.filter('duration', (duration) => {
  if (duration === undefined || duration === null || +duration === 0) return '-';
  return formatDuration(duration);
});

Vue.filter('datetime', (timestamp, pattern) => {
  if (timestamp === undefined || timestamp === null || +timestamp === 0) return '-';
  return formatDateTimezone(timestamp, pattern);
});

Vue.filter('millisecond', timestamp => {
  return formatDateTimezone(timestamp, 'YYYY/MM/DD HH:mm:ss.SSS');
});

Vue.filter('fromNow', (timestamp, pattern) => {
  if (timestamp === undefined || timestamp === null || +timestamp === 0) return '-';
  return formatFromNow(timestamp, pattern);
});

Vue.filter('enumName', name => {
  return i18n.te(name) ? i18n.t(name) : last(split(name, '.'));
});

Vue.filter('numberText', (number, scale, round) => {
  return numberFormat(number, scale, round);
});

Vue.filter('percentText', (number, pattern) => {
  return percent(number, pattern);
});

Vue.filter('abbrList', (items, hash) => {
  let verbose = false;
  let field = 'brId';
  let type = 'company.br';
  if (items === undefined || items === null || items.length === 0) return '-';
  if (hash && hash.field) field = hash.field;
  if (hash && hash.type) type = hash.type;
  if (hash && hash.verbose) verbose = hash.verbose;
  let r = [];
  items.forEach(item => {
    let str = [];
    let keys = Object.keys(item);
    keys.forEach(key => {
      if (key === field) {
        let selection = getSelection(type, item[key]);
        str.push(selection ? selection.name : '-');
        if (verbose) {
          str.push(item['weight']);
        }
      }
    });
    r.push(str.join(':'));
  });
  return r.join(', ');
});

Vue.filter('bookList', items => {
  if (items === undefined || items === null || items.length === 0) return '-';
  let r = [];
  items.forEach(item => {
    let book = getSelection('holding.book').find(v => v.value === item.bookId);
    r.push(`${book.name}:${item.weight}`);
  });
  return r.join(', ');
});
