import i18n from '@/locale/i18n';

export function clearError(errors) {
  Object.keys(errors).forEach(r => {
    errors[r] = undefined;
  });
}

export function setError(response, errors) {
  clearError(errors);
  Object.keys(response).forEach(r => {
    errors[r] = this.$t(response[r].key);
  });
}

export function shouldInRange(start, end) {
  return {
    required: true,
    validator: (rule, value, callback) => {
      if (start <= value && value <= end) {
        callback();
      } else {
        callback(new Error(i18n.t('validation.notInRange', { start, end })));
      }
    },
    trigger: 'blur',
  };
}

export const shouldGreaterThanZero = {
  required: true,
  validator: (rule, value, callback) => {
    if (value > 0) {
      callback();
    } else {
      callback(new Error(i18n.t('validation.required')));
    }
  },
  trigger: 'blur',
};

export const shouldBeInteger = {
  required: true,
  validator: (rule, value, callback) => {
    if (/^\d+$/.test(String(value))) {
      callback();
    } else {
      callback(new Error(i18n.t('validation.notInteger')));
    }
  },
  trigger: 'blur',
};

export const shouldNotEmpty = {
  required: true,
  message: i18n.t('validation.required'),
  trigger: 'blur',
};

export const shouldBeJson = {
  required: true,
  validator: (rule, value, callback) => {
    if (isJSON(value)) {
      callback();
    } else {
      callback(new Error(i18n.t('validation.invalid')));
    }
  },
};

export function isJSON(str) {
  try {
    let r = JSON.parse(str);
    let type = Object.prototype.toString.call(r);
    return type === '[object Object]';
  } catch (e) {
    // console.error(e);
  }
  return false;
}
