import { NAMESPACE } from '@/constant/config';
import _ from 'lodash-es';

export function isEmpty(value) {
  return typeof value === 'number' || typeof value === 'boolean' || _.isDate(value) ? false : _.isEmpty(value);
}

export function isEmptyOption(value) {
  return isEmpty(value) || value === 0;
}

export function beanCopy(obj) {
  return _.cloneDeep(obj);
}

export function compare(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function download(url) {
  let a = document.createElement('a');
  a.setAttribute('href', `${NAMESPACE}/${url}`);
  a.setAttribute('download', '');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// Ken 重新排序, 以保证相同的obj可以被识别
export function SortStringify(obj) {
  let ret = '';
  if (_.isObject(obj)) {
    const newObj = {};
    _.sortBy(_.keys(obj)).forEach(key => {
      newObj[key] = obj[key];
    });
    ret = JSON.stringify(newObj);
  }
  return ret;
}
