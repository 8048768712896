<template>
  <div>
    <Col :xs="labelXs" :sm="labelSm" :md="labelMd" :lg="labelLg" class-name="pt-1">
      <span v-if="label !== undefined" class="n-field-label pl-3">
        {{ $t(`label.${label}`) }}
      </span>
    </Col>
    <Col :xs="contentXs" :sm="contentSm" :md="contentMd" :lg="contentLg">
      <Select
        :value="value"
        :disabled="disabled"
        :clearable="clearable"
        :filterable="filterable"
        :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)"
        @on-change="
          v => {
            this.$emit('input', v);
            this.$emit('on-change', v);
          }
        "
      >
        <Option v-if="allOption" :value="0">ALL</Option>
        <Option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.name | enumName }}
        </Option>
      </Select>
    </Col>
  </div>
</template>

<script>
import { getEnum } from '@/store';
import NFieldBase from './NFieldBase';
export default NFieldBase.extend({
  name: 'NFieldEnumX',

  props: {
    enumName: String,
    allOption: Boolean,
    clearable: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
  },

  computed: {
    options() {
      return getEnum(this.enumName);
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .ivu-select {
  width: 100%;
}

.ivu-select-item-selected,
.ivu-select-item-selected:hover {
  color: #2b85e4 !important;
}

::v-deep .ivu-select-disabled .ivu-select-selected-value {
  color: #495057;
  opacity: 1;
}
</style>
