<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-select v-model="n_value" multiple transfer :filterable="!disabled" :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)" :max-tag-count="1" :max-tag-placeholder="maxTagPlaceholder" :class="[viewTagPlaceholder ? 'n-tag-placeholder' : '', disabled ? 'n-select-disabled' : '']" @on-change="onChange" @on-select="onSelect">
        <template v-if="options.length > 0">
          <Option v-if="hasDisabledOption" value="" :style="{ color: allChecked ? 'rgba(45, 140, 240, 0.9)' : '', borderBottom: '1px solid #e8eaec' }" disabled>
            <Checkbox :value="allChecked" :indeterminate="checked.filter(f => f).length > 0 && !allChecked" disabled />
            Select ALL
          </Option>
          <Option v-else value="" :style="{ color: allChecked ? 'rgba(45, 140, 240, 0.9)' : '', borderBottom: '1px solid #e8eaec' }" :disabled="disabled" @click.native="clickSelectAll">
            <Checkbox :value="allChecked" :indeterminate="checked.filter(f => f).length > 0 && !allChecked" :disabled="disabled || options.length === 0" />
            Select ALL
          </Option>
        </template>
        <Option v-for="(option, index) in options" :key="option.value" :value="option.value" :disabled="disabled || option.disabled">
          <Checkbox :value="checked[index]" :disabled="disabled || option.disabled" />
          {{ $t(option.name) }}
        </Option>
      </i-select>
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="showName(initValue)" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { beanCopy, compare, isEmpty } from '@/helpers/utils';
import _ from 'lodash-es';

export default Base.extend({
  name: 'NFieldMultipleSelect',
  props: {
    options: Array, // [{name, value, disabled}]
    value: Array,
    selectAll: Boolean,
  },
  data() {
    return {
      n_value: [],
      checked: [],
      allChecked: false,
    };
  },
  computed: {
    activeValue() {
      let activeValue = [];
      if (!isEmpty(this.n_value))
        this.n_value.forEach(o => {
          if (!isEmpty(o)) activeValue.push(o);
        });
      return activeValue;
    },
    allValue() {
      let allValue = [];
      this.options.forEach(o => {
        allValue.push(o.value);
      });
      return allValue;
    },
    hasDisabledOption() {
      return this.options.filter(v => v.disabled).length > 0;
    },
    viewTagPlaceholder() {
      return isEmpty(this.n_value) ? false : this.n_value.length > 1;
    },
    isModified() {
      return this.showModifyIcon && _.sum(this.initValue) !== _.sum(this.value);
    },
  },
  watch: {
    value(to) {
      if (!compare(to, this.n_value)) this.n_value = to;
    },
  },
  created() {
    this.initValue = this.value;
    this.n_value = this.selectAll ? this.allValue : this.value;
    this.initChecked();
    this.initAllChecked();
  },
  methods: {
    initChecked() {
      this.options.forEach((option, index) => {
        this.checked[index] = !isEmpty(this.n_value) && this.n_value.find(v => v === option.value) !== undefined;
      });
    },
    initAllChecked() {
      this.allChecked = this.activeValue.length > 0 && this.activeValue.length === this.allValue.length;
    },
    maxTagPlaceholder(num) {
      let selected = num + 1;
      return selected >= this.options.length ? `ALL selected (${selected})` : `Selected (${selected})`;
    },
    showName(value) {
      if (isEmpty(value)) return null;
      let r = [];
      value.forEach(_v => {
        let n = this.options.find(f => f.value === _v).name;
        r.push(this.$t(n));
      });
      return r.join(', ');
    },
    clickSelectAll() {
      if (this.disabled) return;
      this.allChecked = !this.allChecked;
      this.n_value = this.allChecked ? this.allValue : [];
      this.$emit('on-select', this.allChecked ? this.allValue : []);
    },
    onChange(value) {
      if (value.length > 0 && value.indexOf('') !== -1) return;
      this.initChecked();
      this.initAllChecked();
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
    onSelect(select) {
      if (select.label === ' Select ALL ') return;
      let selectValue = !isEmpty(this.n_value) ? beanCopy(this.n_value) : [];
      if (selectValue.find(f => f === select.value) === undefined) selectValue.push(select.value);
      else selectValue = selectValue.filter(f => f !== select.value);
      this.$emit('on-select', selectValue);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
