import { $fetch, $post, $put } from '@/services/axios';

export const CSV_URL = `company/groups/csv`;

export function add(data) {
  return $put(`company/group`, data);
}

export function fetch(data) {
  return $fetch(`company/groups`, data);
}

export function validate(data) {
  return $post(`company/group/validate`, data);
}

export function edit(data) {
  return $post(`company/group/${data.id}`, data);
}

export function one(data) {
  return $fetch(`company/group/${data.id}`, data);
}
