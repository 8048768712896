<template>
  <!-- prettier-ignore -->
  <n-button v-rw type="error" :custom-icon="value ? 'fa fa-lock-open' : 'fas fa-lock'" text="sort" ghost style="width: 60px;" @on-click="onClick" />
</template>

<script>
export default {
  name: 'NbSort',
  props: {
    value: Boolean,
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
    },
  },
};
</script>
