<template>
  <n-button v-if="editable" v-rw ghost type="error" custom-icon="fa fa-cog" text="setting" @on-click="$emit('on-click')" />
  <n-button v-else ghost type="info" custom-icon="fa fa-cog" text="setting" @on-click="$emit('on-click')" />
</template>

<script>
export default {
  name: 'NbSetting',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
