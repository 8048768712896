import Vue from 'vue';
import { getSelection } from '@/store';
import { CommonChannel } from '@/services';

Vue.directive('selection', {
  inserted(el, binding, vnode) {
    const k = binding.arg;
    const v = binding.value;
    const name = getName(k, v);

    setName(el, name);

    el.__watch__ = s => {
      if (s.type === k) {
        setName(el, getName(k, v));
      }
    };

    CommonChannel.subscribe(el.__watch__);
  },

  unbind(el) {
    CommonChannel.unsubscribe(el.__watch__);
  },

  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      setName(el, getName(binding.arg, binding.value));
    }
  },
});

function getName(k, v) {
  const { name } = getSelection(k, parseInt(v)) || {};
  return name;
}

function setName(el, name) {
  if (name) {
    el.title = name;
    el.textContent = name;
  } else {
    el.textContent = '-';
  }
}
