import _ from 'lodash-es';
import EventBus from '../eventbus';
import { isValidMessage, BroadcastMessage, WindowMessage } from './WindowMessage';
import { bind } from './MainWndMessageHandler';

const LIMITATION = 99; // max opened window count

class WindowManager {
  constructor() {
    this.list = [];
    this.opener = window.opener;

    const isPopupUrl = location.hash.indexOf('#/popup/') === 0;
    let isSameOrigin = false;
    if (this.opener) {
      try {
        isSameOrigin = this.opener.document.domain === document.domain;
      } catch (e) {
        // cross-origin open
      }
    }
    this.isSubWindow = isPopupUrl && isSameOrigin;
    this.isMainWindow = !this.isSubWindow;
  }

  open(url, name, attrs = {}) {
    const fullAttrs = {
      left: 400,
      top: 200,
      width: 500,
      height: 500,
      menubar: 'no',
      toolbar: 'no',
      location: 'no',
      status: 'no',
      scrollbars: 'yes',
      ...attrs,
    };

    let strAttrs = '';

    Object.keys(fullAttrs).forEach(key => {
      strAttrs += `${key}=${fullAttrs[key]},`;
    });

    // '_blank' means new window
    let windowObj = name !== '_blank' ? _.find(this.list, { name }) : null;

    if (windowObj && url === windowObj.url) {
      windowObj.handler.focus();
    } else {
      if (!windowObj && this.list.length > LIMITATION) throw new Error(`reach limitation [${LIMITATION}]`);

      const handler = window.open(url, name, strAttrs);
      if (windowObj) {
        windowObj.url = url;
      } else {
        windowObj = { url, name, strAttrs, handler };
        this.list.push(windowObj);
      }
    }
  }

  init() {
    if (this.isMainWindow) {
      window.onunload = () => {
        this.destroy();
      };
    }

    window.addEventListener('message', e => {
      const origin = e.origin || e.originalEvent.origin;
      const { data } = e;
      if (origin === window.location.origin && isValidMessage(data)) {
        // console.log('receiveMessage', data);
        let { eventName, params = {} } = data;
        if (eventName === 'Broadcast') {
          this.onBroadcastByMainWindow(params);
        } else {
          if (!_.isObject) {
            params = { params };
          }
          params._source = e.source;
          EventBus.$emit(eventName, params);
        }
      }
    });
  }

  destroy() {
    this.list.forEach(win => win.handler.close());
  }

  // send(target, event)
  send(target, eventName, params) {
    const event = _.isObject(eventName) ? eventName : new WindowMessage(eventName, params);
    target.postMessage(event, target.location.href);
  }

  sendToParent(eventName, params) {
    const target = this.opener || window;
    this.send(target, eventName, params);
  }

  broadcast(event) {
    if (this.isMainWindow) {
      const { eventName, params } = event;
      EventBus.$emit(eventName, params);

      this.list.forEach(win => {
        this.send(win.handler, event);
      });
    } else {
      this.send(this.opener, new BroadcastMessage(event));
    }
  }

  broadcastToChildren(event) {
    if (this.isMainWindow) {
      this.list.forEach(win => {
        this.send(win.handler, event);
      });
    }
  }

  onBroadcastByMainWindow(event) {
    if (!this.isMainWindow) return;
    this.broadcast(event);
  }
}

const wndManager = new WindowManager();

bind(wndManager);

window.wndManager = wndManager;

export default wndManager;
