import BigNumber from 'bignumber.js';
import _ from 'lodash-es';
import { isNumber } from 'lodash-es';
import { isEmpty } from '@/helpers/utils';

// prettier-ignore
export const ROUNDING = {
  ROUND_UP:         0, //	Rounds away from zero
  ROUND_DOWN:       1, //	Rounds away from zero
  ROUND_CEIL:       2, //   Rounds towards Infinity
  ROUND_FLOOR:      3, //   Rounds towards -Infinity
  ROUND_HALF_UP:    4, //   Rounds towards nearest neighbour.If equidistant, rounds away from zero
  ROUND_HALF_DOWN:  5, //   Rounds towards nearest neighbour.If equidistant, rounds towards zero
  ROUND_HALF_EVEN:  6, //   Rounds towards nearest neighbour.If equidistant, rounds towards even neighbour
  ROUND_HALF_CEIL:  7, //   Rounds towards nearest neighbour.If equidistant, rounds towards Infinity
  ROUND_HALF_FLOOR: 8, //   Rounds towards nearest neighbour.If equidistant, rounds towards -Infinity
  EUCLID:           9, //   Not a rounding mode, see modulo
};

export const DEFAULT_FORMAT = {
  // the primary grouping size of the integer part
  groupSize: 3,
  // the grouping size of the fraction part
  fractionGroupSize: 0,
  // the grouping separator of the integer part
  groupSeparator: ',',
  // the decimal separator
  decimalSeparator: '.',
  // the secondary grouping size of the integer part
  secondaryGroupSize: 0,
  // the grouping separator of the fraction part
  fractionGroupSeparator: ' ',
};

const DEFAULT_BASE = 10; // 十进制

export function valueOf(v, format, roundingMode = ROUNDING.ROUND_HALF_UP) {
  format.groupSeparator = '';
  return toBigNumber(v, format, roundingMode);
}

export function toBigNumber(v, format = DEFAULT_FORMAT, roundingMode = ROUNDING.ROUND_HALF_UP) {
  BigNumber.config({ FORMAT: format, ROUNDING_MODE: roundingMode });
  return new BigNumber(v);
}

BigNumber.config({
  EXPONENTIAL_AT: 1e9, // Almost never return exponential notation
});

/**
 * @Author Ken
 * @CreateDate 2019-12-17 14:29
 * @LastUpdateDate 2019-12-17 14:29
 * @desc
 *  比较两个number的大小, 考虑精度
 *  (对于PriceCell组件, 如果不考虑scale, 可能会出现显示的price没变, 但trend会有变化)
 *  如: scale = 3时, 0.100001 与 0.1 一样大
 */
export function isGreaterThan(v1, v2, scale) {
  const BN = _.isNumber(scale) ? BigNumber.clone({ DECIMAL_PLACES: scale }) : BigNumber;
  return new BN(v1, DEFAULT_BASE).isGreaterThan(new BN(v2, DEFAULT_BASE));
}

export function isGreaterThanOrEqualTo(v1, v2, scale) {
  const BN = _.isNumber(scale) ? BigNumber.clone({ DECIMAL_PLACES: scale }) : BigNumber;
  return new BN(v1, DEFAULT_BASE).isGreaterThanOrEqualTo(new BN(v2, DEFAULT_BASE));
}

export function adds(...values) {
  return operate('plus', values);
}

export function subs(...values) {
  return operate('minus', values);
}

export function isValid(n) {
  const ILLEGALS = [undefined, null, NaN, Infinity, -Infinity];
  return !ILLEGALS.includes(n) && !new BigNumber(n).isNaN();
}

function operate(method, values) {
  let valids = values.filter(isValid);
  if (isEmpty(valids)) valids = [];
  let i = 1;
  let r = new BigNumber(valids[0]);
  for (; i < valids.length; ) r = r[method](valids[i++]);
  return r;
}

export function isLessThan(v1, v2, scale) {
  const BN = isNumber(scale) ? BigNumber.clone({ DECIMAL_PLACES: scale }) : BigNumber;
  return new BN(v1, DEFAULT_BASE).lt(new BN(v2, DEFAULT_BASE));
}
