<template>
  <nb-custom v-bind="attrs" @on-click="onClick" />
</template>

<script>
import { isFunction, isUndefined } from 'lodash-es';
import ICON from '@/constant/icon';
import { hasInheritedRW } from '@/helpers/privilege';

export default {
  name: 'NbModalButton',
  props: {
    type: String,
    size: String,
    icon: String,
    customIcon: String,
    text: String,
    editText: String,
    detailText: String,
    long: Boolean,
    ghost: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      hasRW: false,
    };
  },
  computed: {
    attrs() {
      return {
        ...this.$props,
        type: this.hasRW ? 'error' : 'info',
        text: this.hasRW ? this.editText || this.text || 'edit' : this.detailText || this.text || 'detail',
        customIcon: isUndefined(this.customIcon) ? (this.hasRW ? ICON.edit : ICON.detail) : this.customIcon,
      };
    },
  },
  created() {
    this.hasRW = hasInheritedRW(this);
  },
  methods: {
    onClick() {
      if (this.loading) return;
      const onClick = this.$listeners['on-click'];
      const onDetail = this.$listeners['on-detail'];
      const onEdit = this.$listeners['on-edit'];
      this.invoke(onClick);
      if (this.hasRW) {
        this.invoke(onEdit);
      } else {
        this.invoke(onDetail);
      }
    },
    invoke(cb) {
      if (!isFunction(cb)) return;
      this.loading = true;
      const ret = cb() || {};
      if (isFunction(ret.finally)) {
        ret.finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
