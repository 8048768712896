<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :width="500" :custom-title="$t('label.changePassword')">
    <Form ref="modalForm" class="n-modal-container" :label-width="labelWidth" :model="model" @submit.native.prevent>
      <n-field-password v-model="model.currentPw" label="oldPassword" :no-modified-icon="true" :error="error.currentPw" clearable :disabled="disabled" />
      <n-field-password v-model="model.newPw1" label="newPassword" :no-modified-icon="true" :error="error.newPw1" clearable :disabled="disabled" />
      <password-strength-meter :label-width="labelWidth" :value="model.newPw1" :error="error.newPw1" />
      <n-field-password v-model="model.newPw2" label="confirmPassword" :no-modified-icon="true" :error="error.newPw2" clearable :disabled="disabled" />
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { validatePassword, updatePassword } from '@/api/utility/utility';

export default NModal.extend({
  name: 'ChangePasswordDialog',
  components: { NModal, PasswordStrengthMeter },

  methods: {
    doValidate() {
      return validatePassword(this.model);
    },

    doSubmit() {
      return updatePassword(this.model);
    },
  },
});
</script>
