import Vue from 'vue';
import { TYPES } from '@/constant/bitwise';

Vue.directive('mask-boolean', function(el, binding, vnode) {
  let option = binding.value;
  let v = option.value;
  // hash
  let name = option.name ? option.name : undefined;
  let field = option.field ? option.field : undefined;
  let enums = TYPES[name] || {};

  // prettier-ignore
  let enable = enums && enums[field] && (v & enums[field]) !== 0;
  el.innerHTML = enable ? `<i class="fas fa-check n-success" />` : `<i class="fas fa-times n-error" />`;
});
