<template>
  <Content class="content">
    <Row>
      <Col v-if="tabs.length > 0" span="24" class="p-content-tabs">
        <Card :bordered="true" class="p-content-card" :style="cardStyle" dis-hover>
          <div slot="title" :style="titleStyle">
            <div v-if="showTitle" class="n-flex p-content-card-title">
              <div class="n-col-1">
                <Breadcrumb>
                  <BreadcrumbItem v-for="(item, index) in nBreadcrumbItems" :key="index" :to="item.url">
                    {{ item.name || item }}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div class="n-button-box">
                <slot name="options" />
              </div>
            </div>
          </div>
          <slot></slot>
        </Card>
      </Col>
    </Row>
  </Content>
</template>
<script>
import Vue from 'vue';
import { size, find, forEach } from 'lodash-es';
import { mapState, mapGetters } from 'vuex';

const IndexContent = {
  props: {
    breadcrumbItems: Array,
  },

  data() {
    return {
      // showTitle: false,
    };
  },

  computed: {
    ...mapState('access', ['tabs']),
    ...mapState('layout', ['tabsHeight']),
    ...mapGetters('layout', ['innerHeight']),
    ...mapGetters('menu', ['menus']),
    subMenus() {
      const list = [];
      forEach(this.menus, m => {
        forEach(m.subs, sub => list.push(sub));
      });
      return list;
    },
    nBreadcrumbItems() {
      // Ken 2019-10-28 16:14 如果存在子路由, 则自动添加一级路由
      if (size(this.breadcrumbItems) > 0) {
        const { name } = this.$route.meta;
        const parentRoute = find(this.subMenus, { name });
        return [
          {
            name: this.$t(`menu.${name}`),
            url: parentRoute.url,
          },
          ...this.breadcrumbItems.filter(item => item),
        ];
      }
    },
    showTitle() {
      return size(this.breadcrumbItems) > 0 || this.$slots.options;
    },
    titleStyle() {
      const style = { height: 0 };
      if (this.showTitle) {
        style.borderBottom = '1px solid #e8eaec';
        style.height = '40px';
      }
      return style;
    },
    cardStyle() {
      return {
        height: `${this.innerHeight - this.tabsHeight - 57}px`,
      };
    },
  },

  methods: {},
};

Vue.component('IndexContent', IndexContent);
export default IndexContent;
</script>
<style lang="scss">
@import 'style';
</style>
