import { NavigatorMessage } from '../../../models';
import { routerTo, routerReplace } from '@/router';

export function navigatorHandler(err, vm, info) {
  if (!(err instanceof NavigatorMessage)) return true;

  const { type, path } = err;

  switch (type) {
    case 'to':
      routerTo(path);
      break;
    case 'replace':
      routerReplace(path);
      break;
    default:
      const msg = 'navigatorHandler: wrong type, type=' + type;
      console.error(msg);
      vm.$Sentry?.captureErrorMessage(msg);
  }
  return false;
}
