<script>
export default {
  name: 'ModalMixin',

  data() {
    return {
      tab: 'basic',
      tabCache: { basic: true },
    };
  },

  watch: {
    tab(v) {
      this.tabCache[v] = true;
    },
  },
};
</script>
