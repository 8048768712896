import { MessageModel } from '../../../models';

const importantMessages = ['error.internal_error', 'error.unknown'];

export function messageHandler(err, vm, info) {
  if (!(err instanceof MessageModel)) return true;

  const { type, config, extra, isReport } = err;

  // vm.$Message[type](config ?? { content: 'Unknown Error' });

  if (importantMessages.includes(config) || isReport) {
    const message = typeof config === 'object' ? config.content : config;
    vm.$Sentry?.captureErrorMessage(message, { extra });
  }
  return false;
}
