<template>
  <np-base
    :value="value"
    :field="field"
    :records="records"
    :default-value="defaultValue"
    v-on="listeners"
    @input="v => (value = v)"
  >
    <Input v-model="value" type="text" class="field-input operation" />
  </np-base>
</template>

<script>
import NpBase from './NpBase';

export default {
  name: 'NpInput',
  components: { NpBase },
  props: {
    field: String,
    records: Array,
  },
  data() {
    return {
      value: '',
      defaultValue: '',
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-input {
  height: 26px !important;
}

.operation {
  width: 5rem;
}
</style>
