// BUSINESS MESSAGE PACKAGE

/**
 * @Author Ken
 * @CreateDate 2021-10-27 18:21
 * @desc for navigation
 * @params
 * @return
 */
export class NavigatorMessage {
  constructor({ path, type, data = {} }) {
    this.name = 'NavigatorMessage';
    this.path = path;
    this.type = type; // to | replace
    this.data = data;
  }
}

/**
 * @Author Ken
 * @CreateDate 2022-01-14 10:32
 * @desc ignore this message
 * @params
 * @return
 */
export class IgnoredMessage {
  constructor() {
    this.name = 'IgnoredMessage';
  }
}
