import { isString } from 'lodash-es';

const isDevelopment = process.env.NODE_ENV === 'development';

// assert
export function stringErrorHandler(err, vm, info) {
  if (!isString(err)) return true;

  console.error(err, vm, info);
  vm.$Sentry?.captureErrorMessage(err);

  // Assert Error, only show in development. WON'T show in production
  if (isDevelopment) {
    // vm.$Message.error({ content: err } ?? { content: 'Unknown Error' });
  }
  return false;
}
