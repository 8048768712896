import Vue from 'vue';
import store from '@/store';
import i18n from '@/locale/i18n';
import _ from 'lodash-es';

let BaseVue;

/**
 * @Author Ken
 * @CreateDate 2020-03-25 01:36
 * @LastUpdateDate 2020-03-25 01:36
 * @desc
 * modal层级设计说明:
 * 1. 每个modal都有level属性, 表示其层级
 * 2. close modal 会关闭某层级及以上的modal
 *
 * menu level: 切换menu时, 会自动销毁, 如: 正常的modal
 * index level: 切换menu时, 也一直会显示, 但跳到login页面时, index销毁, 则销毁modal, 如: 密码过期强制修改
 * @params
 * @return
 */
export const MODAL_LEVEL = {
  INDEX: 1,
  MENU: 2,
};

// Ken 2019-12-24 19:17 全局唯一, 只能弹一个modal
let component = null;
export let destroyModal = () => {};
const noop = () => {};

let appDom;
let appVue;
function getAppDom() {
  if (!appDom) {
    appDom = document.querySelector('#app');
    appVue = appDom.__vue__;
  }
  return appDom;
}

export function createModal(modal, options = {}) {
  if (component) return;
  const div = document.createElement('div');
  const el = document.createElement('div');
  div.appendChild(el);
  getAppDom().appendChild(div);

  destroyModal = function(level = MODAL_LEVEL.MENU) {
    // Ken 2019-11-19 22:53 如果在close后还有事件发出, $destroy会阻止这些事件, 所以使用setTimeout
    setTimeout(() => {
      if (!component) return;
      if (component.level < level) return; // see MODAL_LEVEL
      component.$destroy();
      component = null;
      getAppDom().removeChild(div);
      destroyModal = noop; // release 'div'
    }, 0);
  };

  function onEvent(name, v) {
    if (options.on) {
      options.on[name] && options.on[name](v);
    }
  }

  component = new (BaseVue || Vue)({
    el,
    store,
    i18n,
    parent: appVue,
    data() {
      // Ken 2019-11-20 01:25 保证是observe的对象
      return {
        model: _.cloneDeep(options.model || {}),
        level: options.level || MODAL_LEVEL.MENU,
      };
    },
    render(h) {
      return h(modal, {
        props: {
          model: this.model,
          visible: true,
          ...(options.props || {}),
        },
        on: {
          'modal-close': function(v) {
            onEvent('close');
            destroyModal();
          },
          close: function(v) {
            onEvent('close');
            destroyModal();
          },
          load: function(v) {
            // _isVue 为true, 说明传入的是VueComponent的this, 此时可以自动判断是否可以调用doSearch (主要是为了方便分页页面)
            if (_.get(options.on, '_isVue')) {
              const doSearch = options.on.doSearch;
              const callback = doSearch ? options.on.search : options.on.load;
              callback && callback(v);
            } else {
              // 如果传入的是普通对象, 则按同名事件流程走
              onEvent('load', v);
            }
          },
        },
      });
    },
  });
}

const install = function(Vue, opts = {}) {
  if (install.installed) return;
  BaseVue = Vue;
};

export default {
  install,
};
