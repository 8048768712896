let router = [];

if (process.env.NODE_ENV === 'development') {
  router = [
    {
      path: '/lab/document',
      meta: {
        name: 'lab.document',
        cacheName: 'Document',
      },
      component: () => import('@base/document'),
    },
    {
      path: '/lab/example',
      meta: {
        name: 'lab.example',
        cacheName: 'Example',
      },
      component: () => import('@base/example'),
    },
  ];
}

export default router;
