<script>
import Vue from 'vue';
import FieldGrid from './mixins/FieldGrid';
export default Vue.extend({
  name: 'NFieldBase',
  mixins: [FieldGrid],
  props: {
    value: Number,
    error: String,
    label: String,
    disabled: Boolean,
    placeholder: String,
    responsive: { type: Boolean, default: true },
  },
});
</script>
