export default [
  {
    path: '/operator/role',
    meta: {
      name: 'operator.role',
      cacheName: 'OperatorRole',
    },
    component: () => import('@/views/index/operator/OperatorRole'),
  },
  {
    path: '/operator/menu',
    meta: {
      name: 'operator.menu',
      cacheName: 'OperatorMenu',
    },
    component: () => import('@/views/index/operator/OperatorMenu'),
  },
  {
    path: '/operator/notice',
    meta: {
      name: 'operator.notice',
      cacheName: 'OperatorNotice',
    },
    component: () => import('@/views/index/operator/OperatorNotice'),
  },
  {
    path: '/operator/notice/templates',
    meta: {
      name: 'operator.notice',
      submenu: true,
      cacheName: ['OperatorNotice', 'OperatorNoticeTemplate'],
    },
    component: () => import('@/views/index/operator/OperatorNoticeTemplate'),
  },
  {
    path: '/operator/privilege',
    meta: {
      name: 'operator.privilege',
      cacheName: 'OperatorPrivilege',
    },
    component: () => import('@/views/index/operator/OperatorPrivilege'),
  },
  {
    path: '/operator/operator',
    meta: {
      name: 'operator.operator',
      cacheName: 'OperatorOperator',
    },
    component: () => import('@/views/index/operator/OperatorOperator'),
  },
  {
    path: '/operator/operator/config',
    meta: {
      name: 'operator.operator_config',
      cacheName: 'OperatorOperatorConfig',
    },
    component: () => import('@/views/index/operator/OperatorOperatorConfig'),
  },
  {
    path: '/operator/operator/notice',
    meta: {
      name: 'operator.operator_notice',
      cacheName: 'OperatorOperatorNotice',
    },
    component: () => import('@/views/index/operator/OperatorOperatorNotice'),
  },
  {
    path: '/operator/operator/access',
    meta: {
      name: 'operator.operator_access',
      cacheName: 'OperatorOperatorAccess',
    },
    component: () => import('@/views/index/operator/OperatorOperatorAccess'),
  },
  {
    path: '/operator/operator/session',
    meta: {
      name: 'operator.operator_session',
      cacheName: 'OperatorOperatorSession',
    },
    component: () => import('@/views/index/operator/OperatorOperatorSession'),
  },
];
