/**
 * @Author Ken
 * @CreateDate 2019-12-06 11:58
 * @LastUpdateDate 2019-12-06 11:58
 * @desc
     当关联项(companyId, pfId, brId)变化时, 是否强制重置value的值
     (在做为检索条件时, 切换关联项, 对应的option需要强制重置)
     (在做为modal form item时, 刚打开modal, 要保持value为传入的值) (否则会被关联项变化, 所重置)
 */
export default {
  computed: {
    isForceUpdate() {
      if (this.$attrs.hasOwnProperty('force-update')) {
        return this.$attrs['force-update'] !== false;
      } else {
        // 如果未指定force-update, 则默认判断nModal是否存在
        return !this.nModal;
      }
    },
  },
};
