import { SentryMessage } from '../../../models';

const isDevelopment = process.env.NODE_ENV === 'development';

export function sentryMessageHandler(err, vm, info) {
  if (!(err instanceof SentryMessage)) return true;

  console.warn('SentryMessage', err);
  vm.$Sentry?.captureMessage(err.message, { extra: err.extra }, err.level);

  if (isDevelopment) {
    // vm.$Notice.open({
    //   duration: null,
    //   title: `SentryMessage: ${err.message} [${err.level}]`,
    //   description: JSON.stringify(err.extra),
    // });
  }
  return false;
}
