import { Howl, Alarm } from '@nextop/admin-utils';

export const suspendQuoteAlarm = new Alarm('/sounds/alarm/quote.mp3');

export const dealingOrderAlarm = new Alarm('/sounds/alarm/position.mp3');

export const bridgingCommandAlarm = new Alarm('/sounds/alarm/position.mp3');

export const operatorNoticeAudio = {
  sounds: [
    null,
    null,
    new Howl({ src: ['/sounds/alarm/notice1.mp3'], preload: true }),
    new Howl({ src: ['/sounds/alarm/notice2.mp3'], preload: true }),
    new Howl({ src: ['/sounds/alarm/notice3.mp3'], preload: true }),
    new Howl({ src: ['/sounds/alarm/notice4.mp3'], preload: true }),
    new Howl({ src: ['/sounds/alarm/notice5.mp3'], preload: true }),
    new Howl({ src: ['/sounds/alarm/notice6.mp3'], preload: true }),
  ],

  play(sound) {
    const audio = this.sounds[sound];
    if (audio) {
      audio.play();
    }
  },
};
