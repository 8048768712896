import Vue from 'vue';
import { MySentry } from './mySentry';
import { getFingerprint } from '@/utils/fingerprint';

export async function initSentry(bootstrapData) {
  const { operatorId, companyId } = bootstrapData?.profile;
  const { sentryServer: dsn, environment } = bootstrapData?.config;

  if (dsn && process.env.VUE_APP_ENV !== 'LOCAL') {
    const mySentry = new MySentry({
      dsn,
      environment,
      integrations: function(integrations) {
        // integrations will be all default integrations
        return integrations.filter(function(integration) {
          return integration.name !== 'Dedupe';
        });
      },
    });

    Vue.prototype.$Sentry = mySentry;

    // Tag
    mySentry.setTag('X_ROUTE', 'ADMIN');
    mySentry.setTag('X_VERSION', process.env.X_VERSION);
    mySentry.setTag('X_BRANCH', process.env.X_BRANCH_NAME);

    // User
    const fingerprint = await getFingerprint();
    const id = fingerprint?.visitorId ?? 'NA';
    mySentry?.setUser({
      id,
      Fingerprint: id,
      'Company ID': companyId,
      'Operator ID': operatorId,
      Timezone: fingerprint?.components?.timezone?.value,
      Resolution: fingerprint?.components?.screenResolution?.value.toString(),
    });
    // setLocalConfig({ mySentry });
  }
}
