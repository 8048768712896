<template>
  <!-- prettier-ignore -->
  <n-dropdown v-rw type="error" text="add" :items="items" ghost class="n-dropdown-add" @on-click="v => { this.$emit('on-click', v); }" />
</template>

<script>
export default {
  name: 'NbDropdownAdd',
  props: {
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
.n-dropdown-add > ::v-deep .ivu-dropdown-rel > button:hover {
  border-color: #f16643;
  background-color: #f16643;
  color: #ffffff;
}
</style>
