import Vue from 'vue';
import numeral from 'numeral';
import { repeat } from 'lodash-es';

Vue.directive('formatter', function(el, binding, vnode) {
  let option = binding.value;
  let v = option.value;
  // hash
  let unit = option.unit ? option.unit : undefined;
  let scale = option.scale ? option.scale : 0;
  let pattern = option.pattern ? option.pattern : '0,0';
  let replacement = option.replacement ? option.replacement : '-';
  let colorful = option.colorful !== undefined ? option.colorful : false;

  if (scale > 0) {
    pattern += `.${repeat('0', scale)}`;
  }

  if (v === undefined || v === null) {
    el.textContent = unit ? `${replacement} ${unit}` : replacement;
    return;
  }

  if (+v === 0) {
    el.textContent = unit ? `0 ${unit}` : 0;
    return;
  }

  let decorate = numeral(v).format(pattern);

  if (unit) {
    decorate += ' ' + unit;
  }

  if (colorful && v < 0) {
    el.innerHTML = `<span class="text-danger">${decorate}</span>`;
  } else {
    el.textContent = decorate;
  }
});
