<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-input :value="value" type="password" :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)" :autocomplete="autocomplete" :prefix="prefixIcon" :clearable="clearable" :disabled="iDisabled || disabled" @input="v => { this.$emit('input', v); this.$emit('on-change', v); }">
        <n-button-icon v-if="lockBtn" slot="append" :icon="lockBtnIcon" :style="{ width: '32px', height: '32px', border: '0', cursor: disabled ? 'not-allowed' : 'pointer' }" :disabled="lockDisabled" @on-click="onLockBtn" />
      </i-input>
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldPassword',
  props: {
    value: String,
    prefixIcon: String,
    lockBtn: String,
    lockDisabled: Boolean,
    clearable: Boolean,
  },
  data() {
    return {
      lockBtnIcon: '',
      iDisabled: false,
      inputLockBtn: '',
      lockBtnOptions: ['lock', 'unlock'],
    };
  },
  computed: {
    isActiveLockBtn() {
      if (isEmpty(this.lockBtn)) return false;
      return this.lockBtnOptions.filter(v => v === this.lockBtn).length > 0;
    },
    isModified() {
      return this.showModifyIcon && this.initValue !== this.value;
    },
  },
  watch: {
    lockBtn() {
      this.initLockBtn();
    },
  },
  created() {
    this.initValue = this.value;
    this.iDisabled = this.disabled;
    this.initLockBtn();
  },
  methods: {
    initLockBtn() {
      if (!this.isActiveLockBtn) return;
      this.inputLockBtn = this.lockBtn;
      if (this.inputLockBtn === 'lock') {
        this.lockBtnIcon = 'ios-lock';
        this.iDisabled = true;
      } else if (this.inputLockBtn === 'unlock') {
        this.lockBtnIcon = 'ios-unlock-outline';
        this.iDisabled = false;
      }
    },
    onLockBtn() {
      if (this.disabled || this.lockDisabled) return;
      if (this.inputLockBtn === 'unlock') {
        this.lockBtnIcon = 'ios-lock';
        this.iDisabled = true;
        this.inputLockBtn = 'lock';
      } else {
        this.lockBtnIcon = 'ios-unlock-outline';
        this.iDisabled = false;
        this.inputLockBtn = 'unlock';
      }
      this.$emit('on-lock-btn', this.inputLockBtn);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
