<template>
  <Modal v-bind="attrs" v-on="listeners">
    <template #header>
      <div class="n-sub-head">
        <slot v-if="$slots.header" name="header" />
        <template v-else>
          <div class="n-flex">
            <div class="n-col-1">
              <slot name="title" />
            </div>
            <div>
              <Icon type="ios-close" size="30" class="p-close" @click="$parent.$emit('close')" />
            </div>
          </div>
        </template>
      </div>
    </template>

    <slot />

    <template #footer>
      <slot name="footer" />
    </template>
  </Modal>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    attrs() {
      return {
        draggable: false,
        fullscreen: false,
        maskClosable: false,
        value: true,
        width: 800,
        ...this.$attrs,
      };
    },
    listeners() {
      return { ...this.$listeners };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ivu-modal-header {
  height: 45px;
  line-height: 45px;
  padding: 0 10px 0 15px;
}

::v-deep .ivu-modal-body {
  padding: 20px 30px;
}

::v-deep .ivu-modal-close {
  display: none;
}

.p-close {
  cursor: pointer;
}
</style>
