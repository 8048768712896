<script>
import { isFunction } from 'lodash-es';
import ResultMixin from './result-mixin';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE_OPTIONS = [DEFAULT_PAGE_SIZE, 50, 100];

export default {
  name: 'PaginateMixin',
  mixins: [ResultMixin],

  data() {
    return {
      total: 0,
      pageNo: 1,
      pageReset: false,
      pageSize: this.$store.state.preference.pageSize,

      // records: [], // I'm from base-view, DO NOT UNCOMMENT.
      searched: false,
      searching: false,
      paginatable: true,
      timeDisable: false,
      hasSearchError: false,
    };
  },

  computed: {
    hasResult() {
      return this.records && this.records.length > 0;
    },
  },

  methods: {
    search(reset = true) {
      if (reset && this.paginatable) {
        this.pageNo = 1;
      }

      this.records = [];
      this.searched = false;
      this.searching = true;

      let p = this._buildParams();

      this.doSearch(p)
        .then(r => {
          this.searched = true;
          if (this.isValidation(r) || this.isError(r.data || r)) {
            this.hasSearchError = true;
            this.showErrorMsg(r);
          } else {
            this.total = r.total;
            this.pageNo = r.pageNo; // for reset scenario
            this.hasSearchError = false;
            if (isFunction(this.parse)) {
              this.parse(r);
            } else {
              this.records = r.records;
            }
          }
        })
        .finally(() => {
          this.searched = true;
          this.searching = false;
        });
    },

    _buildParams() {
      let p = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      if (!this.timeDisable) {
        if (this.to) p.to = this.to;
        if (this.from) p.from = this.from;
      }

      return p;
    },

    onPageNo(v) {
      this.pageNo = v;
      // Avoid hit twice, confirm button will set pageNo = 1
      if (this.pageNo !== 1 || !this.pageReset) {
        this.search(false);
      }
      this.pageReset = false;
    },

    onPageSize(v) {
      this.pageSize = v;
      this.pageReset = true;
      this.search(this.pageReset);
    },
  },
};
</script>
