<script>
export default {
  name: 'TrailingZero',
  functional: true,
  render: (h, ctx) => {
    const price = ctx.children[0].text;

    if (/\.\d*0$/.test(price)) {
      const match = price.match(/0+$/);
      const priceWithoutZero = price.substr(0, match.index);
      const tailZero = match[0];

      return h('span', { attrs: { 'tail-zero': tailZero } }, priceWithoutZero);
    } else {
      return ctx.children;
    }
  },
};
</script>

<style scoped lang="less">
span::after {
  content: attr(tail-zero);
  opacity: 0.5;
}
</style>
