import Vue from 'vue';
import './enums';
import './selection';
import './formatter';
import './percentage';
import './mask-enums';
import './mask-boolean';
import { hasInheritedRW } from '@/helpers/privilege';

Vue.directive('table-action', {
  bind: function(el, binding, vnode) {
    const hasRW = hasInheritedRW(vnode.context);
    el.classList.add(hasRW ? 'rw-action' : 'ro-action');
  },
});

Vue.directive('rw', {
  inserted(el, binding, vnode) {
    const hasRW = hasInheritedRW(vnode.context);
    if (!hasRW && el.parentNode) el.parentNode.removeChild(el);
  },
});
