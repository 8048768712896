<template>
  <div class="error-page-container">
    <h1 class="title mb-2">Service Unavailable</h1>
    <div class="desc mb-2">
      <p>The server is currently unavailable. Please wait and try again later.</p>
    </div>
    <div class="action">
      <Button type="success" size="large" icon="ios-log-in" class="login-button" @click="login">
        Login
      </Button>
    </div>
  </div>
</template>

<script>
import { routerReplace } from '@/router';
export default {
  data() {
    return {};
  },

  methods: {
    login() {
      routerReplace('/login');
    },
  },
};
</script>

<style scoped lang="less">
@import '../../style/font.less';
.error-page-container {
  height: 100vh;
  display: flex;
  color: #212529;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

  .login-button {
    border-color: #fff;
    background-color: #00b9f1;
    &:hover {
      border-color: lightblue;
      background-color: #00b9f1;
    }
  }
}
</style>
