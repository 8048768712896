/**
 * @Author Ken
 * @CreateDate 2021-10-27 12:41
 * @desc Sentry wrapper
 * @params
 * @return
 */
import * as Sentry from '@sentry/browser';

export class MySentry {
  constructor(options = {}) {
    // const { sentry, ...opts } = options;
    this.sentry = Sentry;
    this.options = options;

    this.sentry.init(this.options);
  }

  close() {
    return this.sentry.close();
  }

  setTag(key, value) {
    return this.sentry.setTag(key, value);
  }

  setUser(user) {
    return this.sentry.setUser(user);
  }

  addBreadcrumb(message) {
    return this.sentry.addBreadcrumb({ message });
  }

  captureException(error, context) {
    return this.sentry.captureException(error, context);
  }

  captureMessage(msg, context, level = 'info') {
    const _context = Object.assign({}, context, { level });
    return this.sentry.captureMessage(msg, _context);
  }

  captureDebugMessage(msg, context) {
    return this.captureMessage(msg, context, 'debug');
  }

  captureInfoMessage(msg, context) {
    return this.captureMessage(msg, context, 'info');
  }

  captureWarnMessage(msg, context) {
    return this.captureMessage(msg, context, 'warning');
  }

  captureErrorMessage(msg, context) {
    return this.captureMessage(msg, context, 'error');
  }

  captureCriticalMessage(msg, context) {
    return this.captureMessage(msg, context, 'critical');
  }

  captureFatalMessage(msg, context) {
    return this.captureMessage(msg, context, 'fatal');
  }
}
