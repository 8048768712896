import iViewMessage from 'view-design/src/components/message';
import i18n from '@/locale/i18n';
import { isObject } from 'lodash-es';

const successMsg = i18n.t('message.success');
const failureMsg = i18n.t('message.failure');

export default {
  ...iViewMessage,
  name: 'NMessage',

  success(config = successMsg) {
    iViewMessage.success(config);
  },

  error(config = failureMsg) {
    const _config = isObject(config) ? config : { content: config };
    const params = {
      content: failureMsg,
      duration: 3,
      background: true,
      ..._config,
    };
    iViewMessage.error(params);
  },
};
