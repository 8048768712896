export default [
  {
    path: '/customer/config',
    meta: {
      name: 'customer.config',
      cacheName: 'CustomerConfig',
    },
    component: () => import('@/views/index/customer/CustomerConfig'),
  },
  {
    path: '/customer/profile',
    meta: {
      name: 'customer.profile',
      cacheName: 'CustomerProfile',
    },
    component: () => import('@/views/index/customer/CustomerProfile'),
  },
  {
    path: '/customer/account',
    meta: {
      name: 'customer.account',
      cacheName: 'CustomerAccount',
    },
    component: () => import('@/views/index/customer/CustomerAccount'),
  },
  {
    path: '/customer/customer/mail',
    meta: {
      name: 'customer.customer_mail',
      cacheName: 'CustomerCustomerEmail',
    },
    component: () => import('@/views/index/customer/CustomerCustomerEmail'),
  },
  {
    path: '/customer/customer/mail/:id/template',
    meta: {
      name: 'customer.customer_mail',
      submenu: true,
      cacheName: ['CustomerCustomerEmail', 'CustomerCustomerEmailTemplate'],
    },
    component: () => import('@/views/index/customer/CustomerCustomerEmailTemplate'),
  },
  {
    path: '/customer/customer/report',
    meta: {
      name: 'customer.customer_report',
      cacheName: 'CustomerCustomerReport',
    },
    component: () => import('@/views/index/customer/CustomerCustomerReport'),
  },
  {
    path: '/customer/customer/report/summary',
    meta: {
      name: 'customer.customer_report',
      submenu: true,
      cacheName: ['CustomerCustomerReport', 'CustomerCustomerReportSummary'],
    },
    component: () => import('@/views/index/customer/CustomerCustomerReportSummary'),
  },
  {
    path: '/customer/customer/access',
    meta: {
      name: 'customer.customer_access',
      cacheName: 'CustomerCustomerAccess',
    },
    component: () => import('@/views/index/customer/CustomerCustomerAccess'),
  },
  {
    path: '/customer/customer/session',
    meta: {
      name: 'customer.customer_session',
      cacheName: 'CustomerCustomerSession',
    },
    component: () => import('@/views/index/customer/CustomerCustomerSession'),
  },
  {
    path: '/customer/customer/message',
    meta: {
      name: 'customer.customer_message',
      cacheName: 'CustomerCustomerMessage',
    },
    component: () => import('@/views/index/customer/CustomerCustomerMessage'),
  },
  {
    path: '/customer/customer/message/device',
    meta: {
      name: 'customer.customer_message',
      submenu: true,
      cacheName: ['CustomerCustomerMessage', 'CustomerCustomerMessageDevices'],
    },
    component: () => import('@/views/index/customer/CustomerCustomerMessageDevices'),
  },
  {
    path: '/customer/customer/message/:id/template',
    meta: {
      name: 'customer.customer_message',
      submenu: true,
      cacheName: ['CustomerCustomerMessage', 'CustomerCustomerMessageTemplate'],
    },
    component: () => import('@/views/index/customer/CustomerCustomerMessageTemplate'),
  },
  {
    path: '/customer/customer/order',
    meta: {
      name: 'customer.customer_order',
      cacheName: 'CustomerCustomerOrder',
    },
    component: () => import('@/views/index/customer/CustomerCustomerOrder'),
  },
  {
    path: '/customer/customer/offer',
    meta: {
      name: 'customer.customer_offer',
      cacheName: 'CustomerCustomerOffer',
    },
    component: () => import('@/views/index/customer/CustomerCustomerOffer'),
  },
  {
    path: '/customer/customer/budget',
    meta: {
      name: 'customer.customer_budget',
      cacheName: 'CustomerCustomerBudget',
    },
    component: () => import('@/views/index/customer/CustomerCustomerBudget'),
  },
  {
    path: '/customer/customer/category',
    meta: {
      name: 'customer.customer_category',
      cacheName: 'CustomerCustomerCategory',
    },
    component: () => import('@/views/index/customer/CustomerCustomerCategory'),
  },
  {
    path: '/customer/customer/transaction',
    meta: {
      name: 'customer.customer_transaction',
      cacheName: 'CustomerCustomerTransaction',
    },
    component: () => import('@/views/index/customer/CustomerCustomerTransaction'),
  },
];
