import { findComponentUpwardBy } from './component';
import store from '@/store';

function _hasRW() {
  return store.getters['access/hasRW'];
}

export function hasInheritedRW(component) {
  const modal = findComponentUpwardBy(component, comp => {
    return comp.isPrivilegeMixin;
  });
  return modal ? modal.privilege.hasRW : _hasRW();
}
