/* Button */
import NButton from './button/n-button';
import NButtonIcon from './button/n-button-icon';
import NDropdown from './button/n-dropdown';
import NRadioGroup from './button/n-radio-group';
/* Field */
import NFieldPreview from './field/n-field-preview';
import NFieldBoolean from './field/n-field-boolean';
import NFieldDate from './field/n-field-date';
import NFieldDatetime from './field/n-field-datetime';
import NFieldEditor from './field/n-field-editor';
import NFieldEnum from './field/n-field-enum';
import NFieldId from './field/n-field-id';
import NFieldMultipleSelect from '@base/field/n-field-multiple-select';
import NFieldNumber from './field/n-field-number';
import NFieldDecimal from './field/n-field-decimal';
import NFieldStepDecimal from './field/n-field-step-decimal';
import NFieldPassword from './field/n-field-password';
import NFieldPercent from './field/n-field-percent';
import NFieldSelect from '@base/field/n-field-select';
import NFieldSelection from './field/n-field-selection';
import NFieldText from './field/n-field-text';
import NFieldTextarea from './field/n-field-textarea';
import NFieldMaskEnum from './field/n-field-mask-enum';
/* Label */
import NIconBoolean from './label/n-icon-boolean';
/* Other */
import NContextMenu from './other/n-context-menu';
import NPage from './other/n-page';
import NRowCol1 from './other/n-row-col1';
import NRowCol2 from './other/n-row-col2';
import NMessage from './other/n-message';
import NRowDraggable from './n-row-draggable';
import NDraggable from './other/NDraggable';
import NUpload from './other/n-upload';
import NColSort from './other/NColSort';

import TrailingZero from './trailing-zero';
import NFieldEnumX from './n-field/NFieldEnum';

export const components = {
  /* Button */
  NButton,
  NButtonIcon,
  NDropdown,
  NRadioGroup,
  /* Field */
  NFieldPreview,
  NFieldBoolean,
  NFieldDate,
  NFieldDatetime,
  NFieldEditor,
  NFieldEnum,
  NFieldId,
  NFieldNumber,
  NFieldDecimal,
  NFieldStepDecimal,
  NFieldPassword,
  NFieldSelection,
  NFieldText,
  NFieldTextarea,
  NFieldSelect,
  NFieldMultipleSelect,
  NFieldPercent,
  NFieldMaskEnum,
  /* Label */
  NIconBoolean,
  /* Other */
  NContextMenu,
  NPage,
  NRowCol1,
  NRowCol2,
  NRowDraggable,
  NDraggable,
  NUpload,
  NColSort,

  NFieldEnumX,
  TrailingZero,
};

const install = function(Vue, opts = {}) {
  if (install.installed) return;

  Object.keys(components).forEach(key => {
    Vue.component(key, components[key]);
  });

  // Vue.prototype.$Loading = LoadingBar;
  Vue.prototype.$Message = NMessage;
  // Vue.prototype.$Modal = Modal;
  // Vue.prototype.$Notice = Notice;
  // Vue.prototype.$Spin = Spin;
};

export default {
  install,
};
