<template>
  <!-- prettier-ignore -->
  <n-button :custom-icon="value ? 'fa fa-search-minus' : 'fa fa-search-plus'" :text="value ? 'less' : 'more'" ghost @on-click="onClick" />
</template>

<script>
export default {
  name: 'NbMore',
  props: {
    value: Boolean,
  },
  methods: {
    onClick() {
      let v = !this.value;
      this.$emit('input', v);
      this.$emit('on-change', v);
    },
  },
};
</script>
