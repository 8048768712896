<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" @submit.native.prevent>
      <Tabs name="customerProfile" type="card" :animated="false">
        <TabPane tab="customerProfile" name="basic" :label="$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <n-field-id v-model="model.id" field-name="id" label="customerId" :error="error.id" disabled />
            <ns-company v-model="model.companyId" :error="error.companyId" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-text v-model="model.loginId" field-name="loginId" label="loginId" :error="error.loginId" disabled />
            <n-field-enum v-model="model.loginMethod" field-name="loginMethod" enum-name="LoginMethod" label="loginMethod" :error="error.loginMethod" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-enum v-model="model.status" field-name="status" enum-name="CustomerProfileStatus" label="status" :error="error.status" disabled />
            <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-enum v-model="model.type" field-name="type" enum-name="CustomerProfileType" label="type" :error="error.type" disabled />
            <n-field-selection v-model="model.defaultCurrency" field-name="defaultCurrency" selection-name="market.currency" :error="error.defaultCurrency" label="defaultCurrency" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-selection v-model="model.groupId" field-name="groupId" selection-name="company.group" label="group" :error="error.groupId" :disabled="disabled" />
            <n-field-id v-model="model.sourceId" label="sourceId" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-enum v-model="model.sex" field-name="sex" enum-name="GenderType" label="sex" :error="error.sex" :disabled="disabled" />
            <n-field-datetime v-model="model.dob" v-bind:format="dobFormat" field-name="dob" label="dob" :error="error.dob" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-text v-model="model.firstName" field-name="firstName" label="firstName" :error="error.firstName" :disabled="disabled || !model.isIndividual" />
            <n-field-text v-model="model.lastName" field-name="lastName" label="lastName" :error="error.lastName" :disabled="disabled || !model.isIndividual" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="customerProfile" name="security" :label="$t('label.security')" class="n-modal-container">
          <n-row-col2>
            <n-field-text v-model="model.challengeCode" field-name="challengeCode" label="challengeCode" :error="error.challengeCode" disabled />
            <n-field-datetime v-model="model.challengeTime" field-name="challengeTime" label="challengeTime" :error="error.challengeTime" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-boolean v-model="model.twoFaOn" field-name="twoFaOn" label="twoFaOn" :error="error.twoFaOn" :disabled="!model.twoFaChangable" />
            <n-field-datetime v-model="model.retryAttemptTime" field-name="retryAttemptTime" label="retryAttemptTime" :error="error.retryAttemptTime" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-text v-model="model.retryAttempt2fa" field-name="retryAttempt2fa" label="retryAttempt2fa" :error="error.retryAttempt2fa" disabled />
            <n-field-text v-model="model.retryAttemptPwd" field-name="retryAttemptPwd" label="retryAttemptPwd" :error="error.retryAttemptPwd" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-text v-model="model.registerIp" field-name="registerIp" label="registerIp" :error="error.registerIp" disabled />
            <n-field-text v-model="model.appAccountToken" field-name="appAccountToken" label="appAccountToken" :error="error.appAccountToken" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-id v-model="model.secondsFromGmt" field-name="secondsFromGmt" label="secondsFromGmt" :error="error.secondsFromGmt" :disabled="disabled" />
            <n-field-mask-enum v-model="model.maturity" field-name="maturity" type-name="CustomerProfileMaturity" label="maturity" :error="error.maturity" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-enum v-model="model.verifyMailStatus" field-name="verifyMailStatus" enum-name="VerifyStatus" label="verifyMail" :error="error.verifyMailStatus" disabled />
            <n-field-enum v-model="model.verifyPhoneStatus" field-name="verifyPhoneStatus" enum-name="VerifyStatus" label="verifyPhone" :error="error.verifyPhoneStatus" disabled />
          </n-row-col2>
        </TabPane>
        <TabPane tab="customerProfile" name="contact" :label="$t('label.contact')" class="n-modal-container">
          <n-row-col2>
            <n-field-selection v-model="model.countryId" :error="error.countryId" selection-name="market.country" label="country" :disabled="disabled" />
            <n-field-selection v-model="model.nationality" :error="error.nationality" selection-name="market.country" label="nationality" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-text v-model="model.zipCode" field-name="zipCode" label="zipCode" :error="error.zipCode" :disabled="disabled" />
            <n-field-text v-model="model.phone" field-name="phone" label="phone" :error="error.phone" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <n-field-text v-model="model.email" field-name="email" label="email" :error="error.email" :disabled="disabled" />
            <n-field-mask-enum v-model="model.mailTypes" field-name="mailTypes" type-name="CustomerMailTypes" label="mailTypes" :error="error.mailTypes" :disabled="disabled" />
          </n-row-col2>
          <n-row-col1>
            <n-field-text v-model="model.address1" field-name="address1" label="address1" :error="error.address1" :disabled="disabled" />
          </n-row-col1>
          <n-row-col1>
            <n-field-text v-model="model.address2" field-name="address2" label="address2" :error="error.address2" :disabled="disabled" />
          </n-row-col1>
        </TabPane>
        <TabPane tab="customerProfile" name="subscription" :label="$t('label.subscription')" class="n-modal-container">
          <n-row-col2>
            <n-field-selection v-model="model.purchasePlan" field-name="purchasePlan" selection-name="company.product" label="purchasePlan" disabled />
            <n-field-boolean v-model="model.lifetimePlan" field-name="lifetimePlan" label="lifetimePlan" :error="error.lifetimePlan" disabled />
          </n-row-col2>
          <n-row-col2>
            <n-field-date v-model="model.purchaseDate" field-name="purchaseDate" label="purchaseDate" :error="error.purchaseDate" :disabled="true" />
            <n-field-datetime v-model="model.purchaseTime" field-name="purchaseTime" label="purchaseTime" :error="error.purchaseTime" :disabled="true" />
          </n-row-col2>
          <n-row-col2>
            <n-field-date v-model="model.expirationDate" field-name="expirationDate" label="expirationDate" :error="error.expirationDate" :disabled="true" />
            <n-field-datetime v-model="model.expirationTime" field-name="expirationTime" label="expirationTime" :error="error.expirationTime" :disabled="true" />
          </n-row-col2>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { DATE_FORMAT } from '@/constant/format';
import { update, validate } from '@/api/customer/customer-profile';

export default NModal.extend({
  name: 'ModalProfile',
  components: { NModal },
  data() {
    return {
      tab: 'basic',
      dobFormat: DATE_FORMAT.YMD_2,
    };
  },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return update(model);
    },
  },
});
</script>
