import Vue from 'vue';
import _, { get } from 'lodash-es';
import { getSelection } from '@/store';
import { CommonChannel, WebsocketMessage, WindowManager } from '@/services';

const DEFAULT_MARKET = 1;
const state = {
  data: {},
};

const getters = {
  market() {
    const market = getSelection('market.market', DEFAULT_MARKET);
    return { ...market };
  },

  defaultCompany() {
    return get(getSelection('company.company'), '[0]', { name: '', value: 0 });
  },

  defaultCurrency() {
    return get(getSelection('market.currency'), '[0]', { name: '', value: 0 });
  },

  currencyExtraMap() {
    return listToExtraMap('market.currency');
  },

  currencyMap() {
    return listToMap('market.currency');
  },

  companyExtraMap() {
    return listToExtraMap('company.company');
  },

  groupExtraMap() {
    return listToExtraMap('company.group');
  },
};

const mutations = {
  setup(state, value) {
    state.data = value;
    CommonChannel.subscribe(s => {
      Vue.set(state.data, s.type, s.data);
      WindowManager.broadcastToChildren(new WebsocketMessage('COMMON', s));
    });
  },
};

function listToExtraMap(name) {
  return listToMap(name, true);
}

function listToMap(name, extra = false) {
  const map = {};
  _.forEach(getSelection(name), item => (map[item.value] = extra ? item.extra : item));
  return map;
}

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
