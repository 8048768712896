<template>
  <!-- prettier-ignore -->
  <Button :type="type" :icon="icon" :long="long" :size="n_size" :ghost="ghost" :loading="loading" :class="buttonClass" :disabled="disabled" :custom-icon="customIcon" @click="$emit('on-click')">
    {{ $t(`label.${text}`) }}
  </Button>
</template>

<script>
import Base from '../base';

export default Base.extend({
  name: 'NButton',
  props: {
    long: Boolean,
    loading: Boolean,
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
