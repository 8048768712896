<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <RadioGroup :value="value ? 1 : 0" type="button" @on-change="v => { this.$emit('input', v === 1); this.$emit('on-change', v === 1); }">
        <Radio :label="1" :disabled="disabled">
          <Icon type="md-checkmark-circle-outline" size="16" color="#19be6b" />
          <span>{{ n_trueLabel }}</span>
        </Radio>
        <Radio :label="0" :disabled="disabled">
          <Icon type="md-close" size="16" color="#ed4014" />
          <span>{{ n_falseLabel }}</span>
        </Radio>
      </RadioGroup>
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue ? n_trueLabel : n_falseLabel" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldBoolean',
  props: {
    value: Boolean,
    trueLabel: String,
    customTrueLabel: String,
    falseLabel: String,
    customFalseLabel: String,
  },
  computed: {
    n_trueLabel() {
      if (!isEmpty(this.trueLabel)) return this.$t(`label.${this.trueLabel}`);
      if (!isEmpty(this.customTrueLabel)) return this.customTrueLabel;
      return this.$t(`label.yes`);
    },
    n_falseLabel() {
      if (!isEmpty(this.falseLabel)) return this.$t(`label.${this.falseLabel}`);
      if (!isEmpty(this.customFalseLabel)) return this.customFalseLabel;
      return this.$t(`label.no`);
    },
    isModified() {
      return this.showModifyIcon && this.initValue !== this.value;
    },
  },
  created() {
    this.initValue = this.value;
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
