export default [
  {
    path: '/system/alert',
    meta: {
      name: 'system.alert',
      cacheName: 'SystemAlert',
    },
    component: () => import('@/views/index/system/SystemAlert'),
  },
  {
    path: '/system/alert/setting',
    meta: {
      name: 'system.alert',
      submenu: true,
      cacheName: ['SystemAlert', 'SystemAlertSetting'],
    },
    component: () => import('@/views/index/system/SystemAlertSetting'),
  },
  {
    path: '/system/batch',
    meta: {
      name: 'system.batch',
      cacheName: 'SystemBatch',
    },
    component: () => import('@/views/index/system/SystemBatch'),
  },
  {
    path: '/system/batch/:name/history',
    meta: {
      name: 'system.batch', // Ken 2019-10-24 16:57 i18n会使用name (index-menu/index.vue)
      submenu: true,
      cacheName: ['SystemBatch', 'SystemBatchHistory'],
    },
    component: () => import('@/views/index/system/SystemBatchHistory'),
  },
  {
    path: '/system/config',
    meta: {
      name: 'system.config',
      cacheName: 'SystemConfig',
    },
    component: () => import('@/views/index/system/SystemConfig'),
  },
  {
    path: '/system/debug',
    meta: {
      name: 'system.debug',
      cacheName: 'SystemDebug',
    },
    component: () => import('@/views/index/system/debug/'),
  },
  {
    path: '/system/digest',
    meta: {
      name: 'system.digest',
      cacheName: 'SystemDigest',
    },
    component: () => import('@/views/index/system/SystemDigest'),
  },
  {
    path: '/system/status',
    meta: {
      name: 'system.status',
      cacheName: 'SystemStatus',
    },
    component: () => import('@/views/index/system/SystemStatus'),
  },
  {
    path: '/system/service',
    meta: {
      name: 'system.service',
      cacheName: 'SystemService',
    },
    component: () => import('@/views/index/system/SystemService'),
  },
  {
    path: '/system/applications',
    meta: {
      name: 'system.applications',
      cacheName: 'SystemApplication',
    },
    component: () => import('@/views/index/system/SystemApplication'),
  },
];
