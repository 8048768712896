import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/profiles`, params);
}

export function validate(model) {
  return $post(`customer/profile/validate`, model);
}

export function update(model) {
  return $post(`customer/profile/${model.id}`, model);
}

export function getProfile(id) {
  return $fetch(`customer/profile/${id}`);
}
