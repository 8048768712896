import { get, size, flatMap, find, findIndex, forEach, cloneDeep, last, isArray } from 'lodash-es';
import { storage } from '@nextop/admin-utils';
import { routerTo } from '@/router';
import { STORAGE_TAB_KEY } from '@/store';
import { DEFAULT_PORTAL, MAX_TAB_SIZE } from '@/constant/config';

const state = {
  tabs: [],
  curUrl: String,
};
const getters = {
  activeRoute: state => state.tabs.find(t => t.active),
  cacheList(state) {
    /**
     * Ken 2019-10-28 12:46 ** DO NOT REMOVE next line about curUrl **
     * make cacheList observe curUrl, recalculate cacheList after enter sub-route
     * */
    const { curUrl } = state;
    let list = [];
    forEach(state.tabs, t => {
      if (isArray(t.cacheName)) {
        list.push(...t.cacheName);
      } else {
        list.push(t.cacheName);
      }
    });
    return list;
  },
  hasRO: (state, getters) => {
    let current = getters['activeRoute'];
    return current ? current.readable : false;
  },
  hasRW: (state, getters) => {
    let current = getters['activeRoute'];
    return current ? current.readable && current.writable : false;
  },
};

function Save() {
  // Ken 2019-11-16 01:36 保存的时候, 只保存基本信息
  storage.set(
    STORAGE_TAB_KEY,
    state.tabs.map(tab => ({
      active: tab.active,
      name: tab.name,
      url: tab.url,
      cacheName: tab.cacheName,
      lastAccessOn: tab.lastAccessOn,
    })),
  );
}

/**
 * @Author Ken
 * @CreateDate 2019-10-25 15:29
 * @LastUpdateDate 2019-10-25 15:29
 * @desc 找出合理的menu:  优先在store里找(还原子路由), 其次从sideMenus里找(新增加tab)
 * @params
 * @return menu
 */
let findMenu;

const mutations = {
  setup(state) {
    // Ken 2019-11-17 15:50 localstorage中只保存tabs基本信息, 所以要重建带有权限的tabs
    const flatMenus = flatMap(this.getters['menu/menus'], 'subs');

    state.tabs = [];
    forEach(storage.get(STORAGE_TAB_KEY), tab => {
      const menu = find(flatMenus, { name: tab.name });
      if (menu) {
        state.tabs.push({
          ...menu,
          url: tab.url,
          active: tab.active,
          cacheName: tab.cacheName,
          lastAccessOn: tab.lastAccessOn || Date.now(),
        });
      }
    });

    findMenu = name => {
      let inTabs = true;
      let menu = find(state.tabs, tab => tab.name === name);
      if (!menu) {
        inTabs = false;
        menu = find(flatMenus, { name });
        // 避免修改menus
        menu = cloneDeep(menu);
      }
      return { menu, inTabs };
    };
  },

  setTabs(state, tabs) {
    state.tabs = tabs;
  },

  // Ken 2019-11-17 17:06 判断权限
  onRouterChange(state, to) {
    // Ken 2019-11-18 10:23 经过checkPrivilege的判断, 进入到这里的, 肯定能找到对应的menu
    const { menu, inTabs } = findMenu(to.meta.name);
    if (!inTabs) {
      state.tabs.push(menu);
    }
    state.tabs.forEach(t => (t.active = false));
    menu.url = state.curUrl = to.fullPath;
    menu.cacheName = to.meta.cacheName;
    menu.active = true;
    menu.lastAccessOn = Date.now();
    // Ken 2019-11-20 21:49 check tab size ( size > 10个, 则删除最早访问的 [徐] )
    while (size(state.tabs) > (this.state.preference.maxTabSize || MAX_TAB_SIZE)) {
      let removeIndex = 0;
      state.tabs.reduce((previous, current, index) => {
        if (previous.lastAccessOn > current.lastAccessOn) {
          removeIndex = index;
          return current;
        }
        return previous;
      });
      state.tabs.splice(removeIndex, 1);
    }
    Save();
    return to.fullPath;
  },

  /**
   * @Author Ken
   * @CreateDate 2019-10-25 17:24
   * @LastUpdateDate 2019-10-25 17:24
   * @desc for user action
   * @params
   *  只有 name: 跳转到对应name tab (点击side menu)
   *  只有 url:  直接跳转到此路径 [剩下的工作由onRouterChange完成] (点击menu tabs | 跳转到子页面)
   */
  pushMenu(state, { name, url: path }) {
    if (name) {
      const { menu } = findMenu(name);
      if (menu) {
        path = menu.url;
      } else {
        console.error(`params error, no menu found, name = ${name}`);
      }
    } else if (!path) {
      console.error('params error, need name or url');
    }
    routerTo(path);
  },

  close(state, index) {
    const [tab] = state.tabs.splice(index, 1);

    if (tab.active) {
      const nextTab = state.tabs[Math.min(index, state.tabs.length - 1)];
      // Ken 2019-11-18 00:57 用routerTo等onRouteChange来设置active, 会慢一步, 页面用到activeRoute会是undefined
      nextTab.active = true;
      routerTo(nextTab.url);
    }
    Save();
  },

  // close tabs to the right
  closeToRight(state, index) {
    index++;
    const removeLength = state.tabs.length - index;
    const tabs = state.tabs.splice(index, removeLength);

    const hasActiveTab = find(tabs, t => t.active);
    if (hasActiveTab) {
      const lastTab = last(state.tabs);
      // Ken 2019-11-18 00:57 用routerTo等onRouteChange来设置active, 会慢一步, 页面用到activeRoute会是undefined
      lastTab.active = true;
      routerTo(lastTab.url);
    }
    Save();
  },

  closeOthers(state, index) {
    const tab = state.tabs[index];
    state.tabs = [tab];
    if (!tab.active) {
      // Ken 2019-11-18 00:57 用routerTo等onRouteChange来设置active, 会慢一步, 页面用到activeRoute会是undefined
      tab.active = true;
      routerTo(tab.url);
    }
    Save();
  },

  // Ken 2019-12-06 01:04 重置menu tabs (设置max tab size会触发)
  reset(state) {
    const activeTabIndex = findIndex(state.tabs, t => t.active);
    const activeTab = state.tabs[activeTabIndex];
    if (get(activeTab, 'url') === DEFAULT_PORTAL) {
      this.commit('access/closeOthers', activeTabIndex);
    } else {
      this.commit('access/setTabs', []);
      routerTo(DEFAULT_PORTAL);
    }
  },
};

const actions = {
  // Ken 2019-11-17 16:54 无权访问 或 不存在, 跳转到第一个tab, 或默认页
  checkPrivilege({ state }, { to, from }) {
    let toUrl;
    const { menu, inTabs } = findMenu(to.meta.name);
    if (!menu || menu?.meta?.panel) {
      toUrl = get(state.tabs, '[0].url', DEFAULT_PORTAL);
      // Ken 2019-11-28 19:27 路由循环了, 目前会导致这个问题的case: 没有market/market访问权限的用户登陆
      // 2020-01-15 14:57 Update: 目前已经在login处理了这种情况, 但从逻辑上, 仍可能发生用户已经登陆后, roles被清空的情况, 所以下面代码暂时不动
      if (toUrl === to.fullPath) {
        return '/login';
      }
    }

    return Promise.resolve(toUrl);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
