export default {
  enums: {
    LoginRestriction: {
      NORMAL: 'NORMAL',
      OFF: 'OFF',
    },
    TradingRestriction: {
      NORMAL: 'NORMAL',
      OPEN_SHORT_OFF: 'OPEN_SHORT_OFF',
      OPEN_LONG_OFF: 'OPEN_LONG_OFF',
      OPEN_OFF: 'OPEN_OFF',
      OFF: 'OFF',
    },
    EditingRestriction: {
      NORMAL: 'NORMAL',
      OFF: 'OFF',
    },
    MarginRestriction: {
      NORMAL: 'NORMAL',
      LOSSCUT_ONLY: 'LOSSCUT_ONLY',
      RISKCUT_ONLY: 'RISKCUT_ONLY',
      OFF: 'OFF',
    },
    PlacingRestriction: {
      NORMAL: 'NORMAL',
      OPEN_SHORT_OFF: 'OPEN_SHORT_OFF',
      OPEN_LONG_OFF: 'OPEN_LONG_OFF',
      OPEN_OFF: 'OPEN_OFF',
      OFF: 'OFF',
    },
    TransferRestriction: {
      NORMAL: 'NORMAL',
      DEPOSIT_ONLY: 'DEPOSIT_ONLY',
      WITHDRAW_ONLY: 'WITHDRAW_ONLY',
      OFF: 'OFF',
    },
    AdminRoleStatus: {
      NORMAL: 'NORMAL',
      LOCKED: 'LOCKED',
      DISABLED: 'DISABLED',
    },
    AdminOperatorType: {
      ROOT: 'ROOT',
      ADMIN: 'ADMIN',
      MANAGER: 'MANAGER',
    },
    AdminOperatorStatus: {
      NORMAL: 'NORMAL',
      LOCKED: 'LOCKED',
      DISABLED: 'DISABLED',
    },
    AdminPasswordExpirePolicy: {
      NEVER: 'NEVER',
      ALWAYS: 'ALWAYS',
    },
    AdminPrivilegeType: {
      MENU: 'MENU',
      DELEGATION: 'DELEGATION',
    },
    AdminConfigType: {
      ADMIN: 'ADMIN',
    },
    AdminNoticeStatus: {
      ACTIVE: 'ACTIVE',
      PENDING: 'PENDING',
      DELETED: 'DELETED',
    },
    AdminNoticeDomain: {
      SYSTEM: 'SYSTEM',
      DEALING: 'DEALING',
      BRIDGING: 'BRIDGING',
      HOLDING: 'HOLDING',
      PRICING: 'PRICING',
      SETTING: 'SETTING',
      TRADING: 'TRADING',
    },
    AdminNoticeLevel: {
      INFO: 'INFO',
      WARN: 'WARN',
      ERROR: 'ERROR',
    },
    AdminNoticeSound: {
      SILENCE: 'SILENCE',
      DEFAULT1: 'DEFAULT1',
      DEFAULT2: 'DEFAULT2',
      DEFAULT3: 'DEFAULT3',
      DEFAULT4: 'DEFAULT4',
      DEFAULT5: 'DEFAULT5',
      DEFAULT6: 'DEFAULT6',
    },
    AdminNoticeTarget: {
      ALL: 'ALL',
      ROLE: 'ROLE',
      OPERATOR: 'OPERATOR',
    },
    AdminNoticeType: {
      DEALING_ORDER_TIMEOUT: 'DEALING_ORDER_TIMEOUT',
      INSERT_TRADING_EXECUTION: 'INSERT_TRADING_EXECUTION',
      DELETE_TRADING_EXECUTION: 'DELETE_TRADING_EXECUTION',
      MODIFY_TRADING_EXECUTION: 'MODIFY_TRADING_EXECUTION',
    },
    Dow: {
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
      7: 'Sun',
    },
    AuditLevel: {
      INFO: 'INFO',
      WARN: 'WARN',
      ERROR: 'ERROR',
    },
    ClientType: {
      ADMIN: 'ADMIN',
      ADVAPI: 'ADVAPI',
      ADMAPI: 'ADMAPI',
      AMSAPI: 'AMSAPI',
      EXPAPI: 'EXPAPI',
      MGRAPI: 'MGRAPI',
      MIDAPI: 'MIDAPI',
      ADVANCE: 'ADVANCE',
      EXPRESS: 'EXPRESS',
    },
    AdminOperatorNoticeStatus: {
      PENDING: 'PENDING',
      ACCEPTED: 'ACCEPTED',
    },
    TraceRoute: {
      ADMIN: 'ADMIN',
      BATCH: 'BATCH',
      SYSTEM: 'SYSTEM',
      ADMAPI: 'ADMAPI',
      AMSAPI: 'AMSAPI',
      DLRAPI: 'DLRAPI',
      MGRAPI: 'MGRAPI',
      MIDAPI: 'MIDAPI',
      ADVAPI: 'ADVAPI',
      EXPAPI: 'EXPAPI',
      ADVANCE: 'ADVANCE',
      EXPRESS: 'EXPRESS',
    },
    CompanyConfigType: {
      URL_AMS: 'URL_AMS',
      URL_CAS: 'URL_CAS',
      URL_SSO: 'URL_SSO',
      URL_WEB: 'URL_WEB',
      URL_SWAP: 'URL_SWAP',
      URL_REPORT: 'URL_REPORT',
      URL_DEPOSIT: 'URL_DEPOSIT',
      URL_PROFILE: 'URL_PROFILE',
      URL_SUPPORT: 'URL_SUPPORT',
      URL_HOMEPAGE: 'URL_HOMEPAGE',
      URL_LEVERAGE: 'URL_LEVERAGE',
      URL_TRANSFER: 'URL_TRANSFER',
      URL_TUTORIAL: 'URL_TUTORIAL',
      URL_WITHDRAW: 'URL_WITHDRAW',
      GOOGLE_FCM_USR: 'GOOGLE_FCM_USR',
      GOOGLE_FCM_PWD: 'GOOGLE_FCM_PWD',
      SWAP_STAGE_TIME: 'SWAP_STAGE_TIME',
      RECAPTCHA_SECRET: 'RECAPTCHA_SECRET',
    },
    CustomerProfileStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
    },
    CustomerAccountStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
    },
    SystemReportTypeName: {
      DAILY_ORDER_PDF: '注文伝票',
      DAILY_TRADE_PDF: '顧客勘定元帳',
      DAILY_TRADE_CSV: '顧客勘定元帳',
      DAILY_POSITION_CSV: 'EOD建玉一覧',
      DAILY_STATEMENT_PDF: '証拠金等元帳',
      DAILY_STATEMENT_CSV: '証拠金等元帳',
      DAILY_REJECTION_PDF: '不成立注文伝票',
      DAILY_SWAP_POINT_CSV: '建玉管理料日記帳',
      ANNUAL_PAYMENT_DETAIL_CSV: '支払調書',
      ANNUAL_PAYMENT_SUMMARY_CSV: '支払調書合計',
      DAILY_EXECUTION_TRADING_PDF: '取引日記帳',
      DAILY_EXECUTION_TRADING_CSV: '取引日記帳',
      DAILY_EXECUTION_DEALING_PDF: '自己勘定(LP)',
      DAILY_EXECUTION_DEALING_CSV: '自己勘定(LP)',
    },
    CustomerReportTypeName: {
      DAILY_TRADE: '取引報告書',
      MONTHLY_TRADE: '月間取引報告書',
      ANNUAL_TRADE_PDF: '年間取引報告書（PDF）',
      ANNUAL_TRADE_CSV: '確定申告用取引履歴',
    },

    MarginFormulaType: {
      FX: 'Lots / Leverage * ContractSize * MarginPercentage',
    },
    InputOrderResult: {
      SUCCESS: 'SUCCESS',
      TIMEOUT: 'TIMEOUT',
      REJECTED: 'REJECTED',
      DUPLICATED: 'DUPLICATED',
      RESTRICTED: 'RESTRICTED',
      STALE_VERSION: 'STALE_VERSION',
      INVALID_ORDER: 'INVALID_ORDER',
      INVALID_REQUEST: 'INVALID_REQUEST',
      INVALID_TRIGGER: 'INVALID_TRIGGER',
      INVALID_PORTFOLIO: 'INVALID_PORTFOLIO',
      INVALID_LIQUIDITY: 'INVALID_LIQUIDITY',
      INVALID_ALLOCATION: 'INVALID_ALLOCATION',
      INVALID_COUNTERPARTY: 'INVALID_COUNTERPARTY',
      INTERNAL_ERROR: 'INTERNAL_ERROR',
    },
  },
};
