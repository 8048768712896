<template>
  <!-- prettier-ignore -->
  <FormItem>
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <i-input type="text" :value="fileName" disabled>
      <span slot="append">
        <Upload :action="`rest/${url}`" :show-upload-list="false" :headers="headers" :accept="accept" :format="format" :data="data" :disabled="disabled" :on-success="handleSuccess" :on-error="handleError" :class="disabled ? 'upload-button-disabled' : 'upload-button'">
          <Icon custom="fa fa-folder-open" />
        </Upload>
        <Icon custom="fa fa-undo" :class="disabled ? 'upload-undo-disabled' : 'upload-undo'" :disabled="disabled" @click="handleUndo" />
      </span>
    </i-input>
  </FormItem>
</template>

<script>
export default {
  name: 'NUpload',
  props: {
    label: String,
    url: String,
    headers: Object,
    accept: { type: String, default: '.csv' },
    data: Object,
    format: Array,
    disabled: Boolean,
  },
  data() {
    return {
      fileName: null,
    };
  },
  methods: {
    handleSuccess(response, file) {
      this.fileName = file.name;
      this.$emit('response', response);
    },
    handleError(error) {
      this.$emit('error', error);
    },
    handleUndo() {
      if (this.disabled) return;
      this.fileName = null;
      this.$emit('on-undo');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'style';
</style>
