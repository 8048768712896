<template>
  <Icon v-if="value" custom="fas fa-check" size="15" class="n-success" />
  <Icon v-else custom="fas fa-times" size="15" class="n-error" />
</template>

<script>
export default {
  name: 'NIconBoolean',
  props: {
    value: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import 'style';
</style>
