<template>
  <div>
    <n-dropdown
      type="text"
      :custom-text="customText"
      :items="items"
      padding="10px 15px 10px 7px"
      is-user-info
      @on-click="onClick"
    />
  </div>
</template>

<script>
import ModalChangePassword from './ModalChangePassword';
import { mapGetters } from 'vuex';
import { logout } from '@/api/utility/utility';
import { routerTo } from '@/router';
import { createModal } from '@/helpers/modal';

export default {
  name: 'UserInfoDropdown',

  props: {},

  data() {
    return {
      passwordModel: {},
      items: [
        { key: 'ChangePassword', text: 'password', customIcon: 'fas fa-key' },
        { key: 'Logout', text: 'logout', customIcon: 'fas fa-sign-out-alt' },
      ],
      changePwdDlgVisible: false,
    };
  },

  computed: {
    ...mapGetters('profile', ['fullName']),
    ...mapGetters('layout', ['mini']),
    customText() {
      return this.mini ? '' : this.fullName;
    },
  },

  methods: {
    onClick(key) {
      this[`on${key}`]();
    },
    onChangePassword() {
      createModal(ModalChangePassword, {
        props: {
          escapable: true,
          privilegeSensitive: false,
        },
        on: {
          load: () => {
            this.onLogout();
          },
        },
      });
    },
    onLogout() {
      return logout().then(() => routerTo('/login'));
    },
  },
};
</script>

<style scoped lang="scss">
@import 'style';
</style>
