import Vue from 'vue';
import numeral from 'numeral';

Vue.directive('percentage', function(el, binding, vnode) {
  let option = binding.value;
  let v = option.value;
  let pattern = option.pattern ? option.pattern : '0.00 %';
  let replacement = option.replacement ? option.replacement : '-';
  let colorful = option.colorful !== undefined ? option.colorful : false;

  if (v === undefined || v === null) {
    el.textContent = replacement;
    return;
  }

  let decorate = numeral(v).format(pattern);

  if (colorful && v < 0) {
    el.innerHTML = `<span class="text-danger">${decorate}</span>`;
  } else {
    el.textContent = decorate;
  }
});
