<template>
  <img :src="url" :width="width" :alt="title" class="img-doc" @click="click"/>
</template>

<script>
export default {
  name: 'NImage',
  props: {
    type: Object,
    title: String,
    url: String,
    width: { type: Number, default: 100 },
  },
  methods: {
    click(e) {
      window.open(e.target.src, '_blank', 'noreferrer');
    },
  },
};
</script>

<style scoped lang="less">
img.img-doc {
  cursor: pointer ;
}
</style>
