<script>
import Vue from 'vue';
import classNames from 'classnames';
import { getDecimalInfo } from '@/helpers/number';
import { getBoolean } from '@base/util';
import { getSelectionGetter } from '@/store';

/**
 * @Author Ken
 * @CreateDate 2019-12-31 15:57
 * @desc show formatted number for price, volume, total etc.
 *  Usage: <n-number :value='12345.6' :scale='2' /> will display: 12,345.60
 * @params
 *                 value: the number value, could be Number or String type
 *                 scale: scale for number
 *                  unit: the suffix for number, like: <n-number :value='123' unit='ms' /> will display: 123 ms
 *              currency: the currency suffix for number, will overwrite 'unit', like: <n-number :value='123' currency='1' /> will display: 123 JPY
 *           replacement: specific string to display if value is not a valid number(null, 0, NaN etc.)
 *             show-zero: true: show '0' as-is; false: show replacement when value = '0'
 *                 color: true: be red color if negative; false: always be default color
 *           format-zero: true: format '0' to '0.000' if scale = 3; false: show '0' even scale = 3
 *     lowlight-trailing: true: show invalid trailing zero in light grey/red color; false: always be default color
 */
export default Vue.component('NNumber', {
  name: 'NNumber',

  functional: true,

  render: (h, ctx) => {
    const { attrs, staticClass = '' } = ctx.data;
    let { value, scale, unit, currency, replacement = '-' } = attrs;
    let showZero = getBoolean(attrs, 'show-zero');
    let color = getBoolean(attrs, 'color', true);
    let formatZero = getBoolean(attrs, 'format-zero', true);
    let lowlightTrailing = getBoolean(attrs, 'lowlight-trailing', true);

    const className = classNames('n-number', ctx.data.class, staticClass);

    const spanData = {
      class: className,
      attrs: {
        'data-strip-value': '',
      },
    };

    const spanArr = [];

    const info = getDecimalInfo(value, scale);
    let dataStripValue = '',
      displayValue = '';

    if (!info) {
      dataStripValue = replacement;
      displayValue = replacement;
    } else {
      if (!showZero && Number(info.validValue) === 0) {
        dataStripValue = replacement;
        displayValue = replacement;
      } else {
        if (!formatZero && Number(info.validValue) === 0) {
          dataStripValue = '0';
          displayValue = '0';
        } else {
          dataStripValue = info.formattedValidValue;
          displayValue = info.formattedValue;
        }

        // 负数
        if (color && dataStripValue?.[0] === '-' && !className.includes('n-number-negative')) {
          spanData.class += ' n-number-negative';
        }
      }
    }

    if (lowlightTrailing) {
      const headString = displayValue.substring(0, dataStripValue.length);
      const tailString = displayValue.substring(dataStripValue.length);

      spanArr.push(h('span', {}, headString));
      if (tailString) {
        spanArr.push(h('span', { class: 'c-tail' }, tailString));
      }
    } else {
      spanArr.push(h('span', {}, displayValue));
    }

    spanData.attrs.title = displayValue;

    // currency 会覆盖unit参数
    if (currency) {
      unit = getSelectionGetter('currencyMap')[currency].name;
    }

    if (unit) {
      spanArr.push(h('span', { class: 'n-number-unit' }, unit));
      spanData.attrs.title += ' ' + unit;
    }

    return h('span', spanData, spanArr);
  },
});
</script>
