export default {
  info: 'fas fa-info-circle', // default
  detail: 'fas fa-info-circle',
  edit: 'fa fa-edit',
  remove: 'fas fa-trash-alt',
  reset: 'fas fa-sync-alt',
  retry: 'fas fa-sync-alt',
  restart: 'fas fa-sync-alt',
  schedule: 'fas fa-sync-alt',
  cancel: 'fas fa-undo',
  abort: 'fas fa-trash-alt',
};
