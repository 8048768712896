<template>
  <n-field-selection v-bind="attrs" v-on="listeners" />
</template>

<script>
import Base from './base';
import { mapGetters, mapState } from 'vuex';

const SELECTION_NAME = 'market.currency';
export default Base.extend({
  name: 'NsCurrency',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('selection', ['defaultCurrency']),
    ...mapState('profile', ['companyId']),
    attrs() {
      return {
        label: 'currency',
        ...this.$attrs,
        'selection-name': SELECTION_NAME,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: v => {
          // Ken 2019-11-21 14:54 由于iview select在computed values时会emit input和on-change, 所以特殊处理一下
          if (!this.disabled) {
            this.$emit('input', v);
          }
        },
        'on-change': v => {
          // Ken 2019-11-21 14:54 由于iview select在computed values时会emit input和on-change, 所以特殊处理一下
          if (!this.disabled) {
            this.$emit('on-change', v);
          }
        },
      };
    },
  },
  created() {
    this.$emit('input', this.defaultCurrency.value);
  },
});
</script>
