export const TYPES = {
  AdminLoginRestriction: {
    ADMIN: 1 << 0,
    ADMAPI: 1 << 1,
    AMSAPI: 1 << 2,
    DLRAPI: 1 << 3,
  },

  AdminMenuTypes: {
    APP: 1 << 0,
    WEB: 1 << 1,
  },

  CustomerProfileMaturity: {
    VERIFIED: 1 << 0,
    SECURED: 1 << 1,
  },

  CustomerAccountMaturity: {},

  CustomerMailTypes: {
    LOGIN: 1 << 0,
    REGISTER: 1 << 1,
    OPEN_ACCOUNT: 1 << 2,
    CLOSE_ACCOUNT: 1 << 3,
    ACTIVE_ACCOUNT: 1 << 4,
    FORGOT_PASSWORD: 1 << 5,
    CHANGE_PASSWORD: 1 << 6,
  },

  CustomerDocumentTypes: {
    SELFIE: 1 << 0,
    ADDRESS: 1 << 1,
    IDENTITY: 1 << 2,
    SIGNATURE: 1 << 3,
    STATEMENT: 1 << 4,
  },

  CustomerMessageTypes: {
    LOSSCUT: 1 << 0,
    MARGIN_CALL: 1 << 2,
    TRIGGER_ORDER: 1 << 4,
  },

  Sides: {
    BUY: 1 << 0,
    SELL: 1 << 1,
  },

  TradeTypes: {
    OPEN: 1 << 0,
    CLOSE: 1 << 1,
  },

  TradingOrderTypes: {
    STOP: 1 << 0,
    LIMIT: 1 << 2,
    MARKET: 1 << 4,
    INSTANT: 1 << 6,
  },

  BridgingCommandTypes: {
    LIMIT: 1 << 2,
    MARKET: 1 << 4,
  },

  BasicOrderConfig: {
    MARKET: 1 << 0,
    INSTANT: 1 << 1,
    OFFSETTING: 1 << 2,
  },

  ComboOrderConfig: {
    IFD: 1 << 0,
    OCO: 1 << 1,
    IFO: 1 << 2,
  },

  LeaveOrderConfig: {
    TIME: 1 << 0,
    STOP: 1 << 1,
    LIMIT: 1 << 2,
  },

  PricingQuoteStatus: {
    SOD: 1 << 0,
    EOD: 1 << 1,
    TRADEABLE: 1 << 3,
    DUPLICATE: 1 << 4,
    RECOVERED: 1 << 5,
    SUSPENDED: 1 << 6,
  },

  PricingBandStatus: {
    TRADEABLE: 1 << 3,
    DUPLICATE: 1 << 4,
    RECOVERED: 1 << 5,
    SUSPENDED: 1 << 6,
  },

  PricingFilterStatus: {
    ASK: 1 << 0,
    BID: 1 << 1,
  },

  PricingPickingConfig: {
    GREEDY: 1 << 0,
    STRICT: 1 << 1,
  },

  MarginStatus: {
    WARNING: 1 << 0,
    LOSSCUT: 1 << 1,
  },
};
