import 'view-design/dist/styles/iview.css';

export default {
  transfer: false,
  select: {
    arrow: 'md-arrow-dropdown',
    arrowSize: 20,
  },
  // capture: false,
};
