import i18n from '@/locale/i18n';
import { storage } from '@nextop/admin-utils';
import { projectName } from '@nextop/admin-glue';
import { routerTo } from '@/router';
import { createModal, MODAL_LEVEL } from '@/helpers/modal';
import { logout } from '@/api/utility/utility';
import ENUMS from '@/constant/enums';
import iNotice from 'view-design/src/components/notice';
import ModalChangePassword from '@/components/common/index/index-header/ModalChangePassword';

const state = {
  type: 0,
  language: 1, // 1: English; 2: Japanese; 3: Chinese
  lastName: '',
  companyId: 0,
  firstName: '',
  operatorId: 0,
  passwordExpired: false,
  passwordSurplusDays: 0,
};

const getters = {
  fullName(state) {
    return `${state.firstName} ${state.lastName}`;
  },

  isSuper(state) {
    return state.type === ENUMS.AdminOperatorType.ROOT;
  },

  isAdmin(state) {
    return state.type === ENUMS.AdminOperatorType.ADMIN;
  },

  isManager(state) {
    return state.type === ENUMS.AdminOperatorType.MANAGER;
  },

  isAdministrator(state, getters) {
    return getters['isSuper'] || getters['isAdmin'];
  },

  isPasswordExpired(state) {
    return state.passwordExpired;
  },

  isPasswordCloseToExpired(state) {
    return state.passwordSurplusDays > 0;
  },
};

const mutations = {
  // 只用于初始化
  setup(state, data) {
    Object.getOwnPropertyNames(data).forEach(key => {
      if (state.hasOwnProperty(key)) {
        state[key] = data[key];
        // Ken 2019-10-20 01:17 为每个operator分配不同的空间
        if (key === 'operatorId') {
          storage.prefix = `${projectName}_opt${data[key]}_`;
        }
      } else {
        console.error(`unknown profile attribute: [${key}] (maybe added by backend)`);
      }
    });
  },
  updateProfile(state, data) {
    Object.getOwnPropertyNames(data).forEach(key => {
      if (state.hasOwnProperty(key)) {
        state[key] = data[key];
      } else {
        console.error(`unsupported profile attribute: [${key}]`);
      }
    });
  },
};

const actions = {
  checkPassword({ state }) {
    if (this.getters['profile/isPasswordCloseToExpired']) {
      iNotice.warning({
        duration: 0,
        title: i18n.t('notice.warning.title'),
        desc: i18n.t('error.password.almost_expire', { n: state.passwordSurplusDays }),
      });
    }

    return this.getters['profile/isPasswordExpired'];
  },

  showPasswordModal() {
    createModal(ModalChangePassword, {
      model: {},
      level: MODAL_LEVEL.INDEX,
      on: {
        load: () => {
          logout();
          routerTo('/login');
        },
      },
      props: { cancelDisabled: true, privilegeSensitive: false },
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
