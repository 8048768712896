import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/accounts`, params);
}

export const ACCOUNT_CSV_URL = `customer/accounts/csv`;

export function validate(model) {
  return $post(`customer/account/validate`, model);
}

export function update(model) {
  return $post(`customer/account/${model.id}`, model);
}

export function validateChangeGroup(model) {
  return $post(`customer/account/group/validate`, model);
}

export function changeGroup(model) {
  return $post(`customer/account/group`, model);
}

export function getAccount(id) {
  return $fetch(`customer/account/${id}`);
}
