import store from '@/store';
import { $fetch, $post } from '@/services/axios';
import { initSentry } from '@/monitor/sentry';

let promise = null;

export const BOOTSTRAP_URL = '/utility/bootstrap';

export async function boot() {
  promise = null;
  const raw = await $fetch(BOOTSTRAP_URL);
  await store.dispatch('setup', raw);
  await initSentry(raw);
  promise = Promise.resolve(raw);
  return raw;
}

export function bootPromise() {
  return promise || boot();
}

export function resetBoot() {
  promise = null;
}

export function validatePassword(model) {
  return $post('utility/password/validate', model);
}

export function evaluatePassword(params) {
  return $post(`utility/password/evaluate`, params);
}

export function updatePassword(model) {
  return $post('utility/password', model);
}

export function logout() {
  promise = null;
  return $fetch('utility/logout');
}
