<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <iframe width="100%" height="300px" :srcdoc="value"/>
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldPreview',
  props: {
    value: [String, Number],
    prefixIcon: String,
    suffixText: String,
    customSuffixText: String,
  },
  computed: {
    isModified() {
      let v = isEmpty(this.value) ? '' : `${this.value}`;
      return this.showModifyIcon && this.initValue !== v;
    },
  },
  created() {
    this.initValue = isEmpty(this.value) ? '' : `${this.value}`;
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
