<template>
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <DatePicker
        :value="value"
        transfer
        type="date"
        :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)"
        :clearable="clearable"
        :format="n_format === null ? '' : n_format.DETAIL"
        placement="bottom-end"
        :options="options"
        :disabled="disabled"
        @input.native="onInput"
        @on-change="onChange"
      />
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import moment from 'moment';
import { getSelection } from '@/store';
import { isEmpty } from '@/helpers/utils';
import { DATE_FORMAT } from '@/constant/format';
import { formatStringDate, getDateFormat, isWeekend } from '@/helpers/date';
import { findComponentDownward, findComponentsDownward } from '@/helpers/component';

export default Base.extend({
  name: 'NFieldDate',
  props: {
    value: String,
    format: Object,
    weekendDisabled: Boolean,
    clearable: Boolean,
  },

  data() {
    return {
      n_format: null,
      v_format: null,
      p_value: null,
    };
  },

  computed: {
    options() {
      let $this = this;
      const {
        tradingDate,
        prevTradingDate,
        sowTradingDate,
        eowTradingDate,
        somTradingDate,
        eomTradingDate,
        soyTradingDate,
        eoyTradingDate,
      } = getSelection('market.market')[0].extra;
      return {
        disabledDate(date) {
          return $this.weekendDisabled ? isWeekend(date) : null;
        },
        shortcuts: [
          { text: 'Trading Date', value: () => formatStringDate(tradingDate) },
          { text: 'Prev Trading Date', value: () => formatStringDate(prevTradingDate) },
          { text: 'SOW Trading Date', value: () => formatStringDate(sowTradingDate) },
          { text: 'EOW Trading Date', value: () => formatStringDate(eowTradingDate) },
          { text: 'SOM Trading Date', value: () => formatStringDate(somTradingDate) },
          { text: 'EOM Trading Date', value: () => formatStringDate(eomTradingDate) },
          { text: 'SOY Trading Date', value: () => formatStringDate(soyTradingDate) },
          { text: 'EOY Trading Date', value: () => formatStringDate(eoyTradingDate) },
        ],
      };
    },

    isModified() {
      return this.showModifyIcon && this.initValue !== this.p_value;
    },
  },

  created() {
    let v = isEmpty(this.value) ? null : formatStringDate(this.value, DATE_FORMAT.YMD_2.MOMENT);
    this.initValue = v;
    this.p_value = v;
    this.initViewFormat();
    this.initValueFormat();
  },

  methods: {
    initValueFormat() {
      this.v_format = getDateFormat(this.value);
    },

    initViewFormat() {
      moment.suppressDeprecationWarnings = true;
      if (isEmpty(this.format)) {
        this.n_format = DATE_FORMAT.YMD_2;
      } else {
        this.n_format = this.format;
      }
    },

    onChange(value) {
      let v = null;
      if (isEmpty(value) || value === 'Invalid date') {
        v = null;
      } else if (typeof value === 'string') {
        v = moment(new Date(value)).format(this.v_format.MOMENT);
        this.p_value = moment(new Date(value)).format(DATE_FORMAT.YMD_2.MOMENT);
      } else if (typeof value === 'object') {
        v = moment(value).format(this.v_format.MOMENT);
        this.p_value = moment(value).format(DATE_FORMAT.YMD_2.MOMENT);
      }

      this.$emit('input', v);
      this.$emit('on-change', v);
    },

    // Handle enter event.
    // e.g. Input date manually then press enter key twice,
    // it is incorrect if not reset focusedDate.
    onInput(e) {
      const input = e.target.value;
      const m = moment(input, DATE_FORMAT.YMD_2.MOMENT, true);
      if (m.isValid()) {
        const picker = findComponentDownward(this, 'CalendarPicker');
        picker.focusedDate = m.toDate();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
