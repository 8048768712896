import Vue from 'vue';
import './style/bootstrap/scss/bootstrap.scss';
import Vuex from 'vuex';
import NextopViewDesign from '@/components/view-design';
// import ViewUI from 'view-design';
import iviewConfig from './iview';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from '@/locale/i18n';
import Vue2Editor from 'vue2-editor';
import './style/index.less';
import './style/common.scss';
import './style/mobile.scss';
import '@/helpers/filters';
import base from '@base';
import '@/components/common';
import './services/eventbus';
import './directives';
import './mixins';
import '@/components/common/panel-instance';
import '@/services/error-handler';
import ENUMS from '@/constant/enums';
import '@/glue-config';
import { bindEventBusHandler } from '@/services/eventBusHandler';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(NextopViewDesign, iviewConfig);
Vue.use(base);
Vue.use(Vue2Editor);
Vue.prototype.$enums = ENUMS;

bindEventBusHandler();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
