import { getPrivilege } from '@/store/module/menu';
import { findComponentUpwardBy } from '@/helpers/component';
import _ from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
  name: 'PrivilegeMixin',
  data() {
    return {
      isPrivilegeMixin: true,
      privilegeName: '',
      privilege: {
        hasRO: false,
        hasRW: false,
      },
    };
  },
  computed: {
    ...mapGetters('access', {
      _hasRO: 'hasRO',
      _hasRW: 'hasRW',
    }),
    hasRO() {
      return this.privilege.hasRO;
    },
    hasRW() {
      return this.privilege.hasRW;
    },
  },

  created() {
    if (!this.privilegeName) {
      const modal = findComponentUpwardBy(this, component => {
        return component.isPrivilegeMixin;
      });
      // Ken 2020-01-19 14:39 for NModal in extended NModal
      if (modal) {
        this.privilegeName = modal.privilegeName;
      }
    }
    this.privilege = getPrivilege(this.privilegeName);

    // Ken 2020-01-19 14:34 use route privilege if no specific privilege provided
    if (_.isEmpty(this.privilege)) {
      this.privilege = {
        hasRO: this._hasRO,
        hasRW: this._hasRW,
      };
    }
  },
};
