<template>
  <Dropdown
    ref="dropdown"
    trigger="click"
    placement="bottom-start"
    :style="style"
    :transfer="false"
    @on-click="click"
    @on-visible-change="onVisibleChange"
  >
    <DropdownMenu slot="list">
      <slot></slot>
    </DropdownMenu>
  </Dropdown>
</template>

<script>
import { isEmpty, isFunction } from 'lodash-es';
import ResultMixin from '@/mixins/result-mixin';
import { SUCCESS_MUTE } from '@/constant/config';

export default {
  name: 'NContextMenu',
  mixins: [ResultMixin],
  props: ['contextEvent'],
  data() {
    return {
      left: 0,
      top: 0,
    };
  },
  computed: {
    style() {
      return {
        position: 'fixed',
        left: `${this.left}px`,
        top: `${this.top}px`,
        zIndex: 10,
      };
    },
  },
  watch: {
    contextEvent() {
      if (!isEmpty(this.contextEvent)) {
        this.left = this.contextEvent.clientX;
        this.top = this.contextEvent.clientY;
        this.$refs.dropdown.currentVisible = true;
      }
    },
  },
  methods: {
    click(name) {
      if (!name) return;
      const cb = this.$listeners['click'];
      /**
       * Ken 2020-01-07 15:25 $listeners内的函数被Vue包了一层, 其实是invoker函数, 其中的fns才是原函数
       * invoker函数在调用时, 如果内部的promise有error, 会被vue内部拦截到, 并打印出来, 我们想自己拦截, 所以直接使用函数
       */
      if (!isFunction(cb) || !isFunction(cb.fns)) return;

      const ret = cb.fns(name) || {};
      if (isFunction(ret.finally)) {
        ret
          .then(r => {
            if (this.isSuccess(r)) {
              if (!SUCCESS_MUTE) {
                this.$Message.success();
              }
            } else {
              this.showErrorMsg(r);
            }
          })
          .catch(e => {
            // console.error('NbConfirm: uncaught promise error', e);
          });
      }
    },
    onVisibleChange(visible) {
      this.$emit('onVisibleChange', visible);
    },
  },
};
</script>
