<template>
  <Poptip
    v-if="hasRW && records.length > 0"
    ref="n-poptip"
    placement="top"
    trigger="click"
    transfer
    @on-popper-hide="onPopperHide"
  >
    <i class="fa fa-edit n-poptip-input-icon"></i>
    <div slot="content" class="api">
      <div class="table-poptip-content">
        <div id="pop_operation" class="operation">
          <slot></slot>
        </div>
        <div class="buttons">
          <Button class="ivu-btn ivu-btn-small ivu-btn-error mx-1" @click="cancel">
            <i class="fa fa-fw fa-times"></i>
          </Button>
          <Button :loading="loading" class="ivu-btn ivu-btn-small ivu-btn-success" @click="submit">
            <i class="fa fa-fw fa-check"></i>
          </Button>
        </div>
      </div>
    </div>
  </Poptip>
</template>
<script>
import { mapGetters } from 'vuex';
import ResultMixin from '@/mixins/result-mixin';
import { SUCCESS_MUTE } from '@/constant/config';

export default {
  name: 'NpBase',
  mixins: [ResultMixin],
  props: {
    field: String,
    records: Array,
    value: [String, Number, Boolean],
    defaultValue: [String, Number, Boolean],
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('access', ['hasRW']),
  },
  methods: {
    cancel() {
      this.$refs['n-poptip'].$refs.reference.click();
    },
    submit() {
      let onSubmit = this.$listeners['on-submit'];
      if (onSubmit) {
        this.loading = true;
        onSubmit(this.value, this.field)
          .then(r => {
            if (this.isSuccess(r)) {
              this.cancel();

              if (!SUCCESS_MUTE) {
                this.$Message.success();
              }
              let onSuccess = this.$listeners['on-success'];
              if (onSuccess) {
                onSuccess();
              }
            } else {
              let result = this.parse(r); // display first element validtion
              this.showErrorMsg(result ? { key: result, level: 'ERROR', type: 'MESSAGE' } : {});
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    parse(r) {
      let data = r.data;
      if (data) {
        let first = data[0];
        let field = this.field;
        return first[field].key;
      }
    },

    onPopperHide() {
      this.$emit('input', this.defaultValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/style/color.scss';

i:hover {
  cursor: pointer;
  color: $color-blue;
}

.table-poptip-content {
  display: flex;

  ::v-deep .ivu-btn-small {
    height: 26px !important;
  }

  .buttons {
    margin-left: 0.5rem;
  }
}
</style>
