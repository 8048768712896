<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-else-if="customLabel !== undefined" slot="label" class="n-field-label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <i-select ref="select" :value="value" :placeholder="placeholder === undefined ? '' : this.$t(`label.${placeholder}`)" :filterable="filterable" :clearable="clearable" :disabled="disabled" transfer @on-open-change="onOpenChange" @on-change="v => { this.$emit('input', v); this.$emit('on-change', v); }" @on-select="v => { this.$emit('on-select', v) }">
        <template v-if="allOption">
          <Option :value="allOptionValue" :style="{ borderBottom: options.length > 0 ? '1px solid #e8eaec' : '0' }">ALL</Option>
        </template>
        <Option v-for="option in options" :key="option[optionValueKey]" :value="option[optionValueKey]">
          {{ $t(option[optionNameKey]) }}
          <span v-if="option.abbr" class="option-abbr">{{ option.abbr }}</span>
        </Option>
      </i-select>
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="showName(initValue)" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { isEmpty, isEmptyOption } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldSelect',
  props: {
    options: Array,
    value: [String, Number],
    optionName: String,
    optionValue: String,
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    allOption: Boolean,
    allOptionValue: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    optionNameKey() {
      return isEmpty(this.optionName) ? 'name' : this.optionName;
    },
    optionValueKey() {
      return isEmpty(this.optionValue) ? 'value' : this.optionValue;
    },
    isModified() {
      return this.showModifyIcon && this.initValue !== this.value;
    },
  },
  created() {
    this.initValue = this.value;
  },
  methods: {
    onOpenChange(isOpen) {
      if (!isOpen || !isEmptyOption(this.value)) return;
      this.$nextTick(() => {
        this.$refs.select.$el.lastChild.scrollTop = 0;
      });
    },
    showName(value) {
      if (isEmpty(value)) return null;
      if (this.allOption && value === this.allOptionValue) return 'ALL';
      if (isEmpty(this.options) || value === this.allOptionValue) return null;
      return this.options.find(f => f[this.optionValueKey] === value)[this.optionNameKey];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../base';
@import 'style';
</style>
