import Vue from 'vue';

export default Vue.extend({
  inject: {
    nModal: {
      from: 'nModal',
      default: null,
    },
  },
  computed: {
    allOption() {
      return this.$attrs.hasOwnProperty('all-option') && this.$attrs['all-option'] !== false;
    },
    disabled() {
      return this.$attrs.hasOwnProperty('disabled') && this.$attrs['disabled'] !== false;
    },
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
});
