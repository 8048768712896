import _ from 'lodash-es';
import Vue from 'vue';
import i18n from '@/locale/i18n';
import { getCompanyIdByCoSymbolId, getSymbolIdByCoSymbolId } from '@nextop/admin-utils';
import { WindowManager } from '@/services';

let ID = 1;
const MAX_DISPLAY_ORDER = 100;

const POSITION_OFFSET = { left: 10, top: 10 };

const state = {
  list: [],
};

const getters = {
  findPanel(state, getters) {
    return (options = {}) => {
      return _.find(state.list, options);
    };
  },
};

export function SetBoundingBox(config) {
  // Ken 2020-01-07 21:27 设置最小值 不判断>0的话会有NaN, undefined的问题 (Math.max(123, undefined) === NaN)
  if (config.minWidth > 0) {
    config.width = Math.max(config.width, config.minWidth);
  }
  if (config.minHeight > 0) {
    config.height = Math.max(config.height, config.minHeight);
  }
}

function getMaxDisplayOrder() {
  return _.maxBy(state.list, 'displayOrder')?.displayOrder ?? 0;
}

function resetDisplayOrder() {
  const list = _.sortBy(state.list, ['displayOrder'], ['asc']);
  _.forEach(list, (panel, index) => (panel.displayOrder = index + 1));
}

function checkDisplayOrder() {
  const maxDisplayOrder = getMaxDisplayOrder();
  if (maxDisplayOrder >= MAX_DISPLAY_ORDER) {
    resetDisplayOrder();
  }
}

function getShiftPosition(left, top) {
  const samePositionPanel = _.find(state.list, { left, top });
  if (samePositionPanel) {
    left += POSITION_OFFSET.left;
    top += POSITION_OFFSET.top;
    return getShiftPosition(left, top);
  } else {
    return { left, top };
  }
}

const mutations = {
  create(state, panelConfig) {
    checkDisplayOrder();

    const newConfig = {
      width: 800,
      height: 450,
      resizable: true,
      closeable: true,
      minimizable: true,
      minimized: false,
      ...panelConfig,
      id: Date.now() + '_' + ID++,
      displayOrder: getMaxDisplayOrder() + 1,
    };

    if (!newConfig.hasOwnProperty('maximizable')) {
      newConfig.maximizable = newConfig.resizable;
    }

    SetBoundingBox(newConfig);
    let left = panelConfig.left ?? (document.body.clientWidth - newConfig.width) / 2;
    let top = panelConfig.top ?? Math.max(0, (document.body.clientHeight - newConfig.height) / 4);
    state.list.push({ ...newConfig, ...getShiftPosition(left, top) });
  },

  close(state, id) {
    const index = _.findIndex(state.list, { id });
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },

  moveToTop(state, id) {
    const panel = _.find(state.list, { id });
    if (panel) {
      const alreadyTop = panel === _.maxBy(state.list, 'displayOrder');
      if (alreadyTop) {
        return;
      }
      checkDisplayOrder();
      panel.displayOrder = getMaxDisplayOrder() + 1;
    } else {
      console.error('moveToTop error, no panel found, id = ', id);
    }
  },

  setAttrs(state, panelConfig) {
    const index = _.findIndex(state.list, { id: panelConfig.id });
    if (index > -1) {
      Object.assign(state.list[index], panelConfig);
      SetBoundingBox(state.list[index]);
    } else {
      console.error('setAttrs error, no panel found, id = ', panelConfig.id);
    }
  },
};

const actions = {
  createDealingPanel({ commit, getters }, { coSymbolId, bookId } = {}) {
    // Dealing Panel
    const MAX_COUNT = 6;
    if (getters.dealingPanelCount + WindowManager.list.length >= MAX_COUNT) {
      Vue.prototype.$Message.warning(i18n.t('warn.reachDealingPanelCountLimitation', { count: MAX_COUNT }));
      return;
    }

    const config = { ...PanelConfig };
    if (coSymbolId > 0) {
      const companyId = getCompanyIdByCoSymbolId(coSymbolId);
      const symbolId = getSymbolIdByCoSymbolId(coSymbolId);
      config.extra = { companyId, symbolId };
      if (bookId > 0) {
        config.extra.bookId = bookId;
      }
    }
    commit('create', config);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
