export default {
  label: {
    no: '否',
    ok: 'OK',
    yes: '是',
    back: '返回',
    hide: '隐藏',
    save: '保存',
    show: '显示',
    close: '关闭',
    cancel: '取消',
    confirm: '确认',
    preview: '预览',
    refresh: '刷新',
    fullScreen: '全屏',
    columnFilter: '列过滤',
    listIsEmpty: '列表为空',
  },
};
