import moment from 'moment';
import 'moment-timezone';
import { DATE_FORMAT } from '@/constant/format';
import store from '@/store';
import { isEmpty } from '@/helpers/utils';
import _ from 'lodash-es';

// Ken 2019-12-17 20:26 这个默认时区, 只是为了测试方便, 不是为了在全局用, 所以不定义在constant下
// (在测试时, store中没有数据, 以后看下, 是不是可以引入一个mock store)
const DEFAULT_TIME_ZONE = 9; // Japan

export function formatDate(timestamp, format) {
  if (!timestamp) return '';
  if (!format) format = DATE_FORMAT.YMD_HMS_3.MOMENT;
  return moment(Number(timestamp)).format(format);
}

export function formatDuration(duration) {
  var days = moment.duration(duration).days();
  var suffix = days > 1 ? " days " : days === 1 ? " day " : "";
  return (days > 0 ? (moment.duration(duration).days() + suffix) : "") +
      moment.utc(moment.duration(duration).asMilliseconds()).format("HH:mm:ss");
}

export function formatDateTimezone(timestamp, format, timezone) {
  if (!timestamp) return '';
  if (!format) format = DATE_FORMAT.YMD_HMS_3.MOMENT;
  if (!timezone) timezone = _.get(store, `state.selection.data['market.market'][0].extra.timezone`, DEFAULT_TIME_ZONE);
  return moment(Number(timestamp))
    .utcOffset(timezone)
    .format(format);
}

export function isWeekend(date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

export function formatFromNow(timestamp, timezone) {
  if (!timestamp) return '';
  if (!timezone) timezone = _.get(store, `state.selection.data['market.market'][0].extra.timezone`, DEFAULT_TIME_ZONE);
  // return v ? moment.unix(v / 1000).utcOffset(timezone).fromNow() : '-';
  return moment
    .unix(Number(timestamp) / 1000)
    .utcOffset(timezone)
    .fromNow();
}

export function oneWeekAgoDatetime(time) {
  if (isEmpty(time)) return null;
  let t = typeof time === 'number' ? time : Number(time);
  return `${t - 1000 * 60 * 60 * 24 * 7}`;
}

export function halfYearAgoDatetime(time) {
  if (isEmpty(time)) return null;
  let t = typeof time === 'number' ? time : Number(time);
  return `${t - 1000 * 60 * 60 * 24 * 30 * 6}`;
}

export function formatStringDate(str, format) {
  if (isEmpty(str)) return '';
  if (!format) format = DATE_FORMAT.YMD_2.MOMENT;
  return moment(str).format(format);
}

export function getDateFormat(str) {
  if (isEmpty(str)) return DATE_FORMAT.YMD_2;
  if (formatStringDate(str, DATE_FORMAT.YMD_1.MOMENT) === str) return DATE_FORMAT.YMD_1;
  if (formatStringDate(str, DATE_FORMAT.YMD_2.MOMENT) === str) return DATE_FORMAT.YMD_2;
  if (formatStringDate(str, DATE_FORMAT.YMD_3.MOMENT) === str) return DATE_FORMAT.YMD_3;
  return null;
}
