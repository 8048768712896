<template>
  <n-dropdown v-bind="attrs" v-on="listeners" />
</template>

<script>
export default {
  name: 'NbDropdown',
  props: {
    items: Array,
    text: { type: String, default: 'more' },
    type: { type: String, default: 'error' },
  },
  computed: {
    attrs() {
      return {
        ...this.$props,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>
