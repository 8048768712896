import Vue from 'vue';
import VueI18n from 'vue-i18n';
/** iView */
import en from 'view-design/dist/locale/en-US';
import ja from 'view-design/dist/locale/ja-JP';
import zh from 'view-design/dist/locale/zh-CN';
/** en */
import en_menu from '@/locale/en/menu';
import en_label from '@/locale/en/label';
import en_enums from '@/locale/en/enums';
import en_message from '@/locale/en/message';
/** ja */
import ja_menu from '@/locale/ja/menu';
import ja_label from '@/locale/ja/label';
import ja_enums from '@/locale/ja/enums';
import ja_message from '@/locale/ja/message';
/** zh */
import zh_menu from '@/locale/zh/menu';
import zh_label from '@/locale/zh/label';
import zh_enums from '@/locale/zh/enums';
import zh_message from '@/locale/zh/message';

Vue.use(VueI18n);
Vue.locale = () => {};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: Object.assign(en, en_menu, en_label, en_enums, en_message),
    ja: Object.assign(ja, ja_menu, ja_label, ja_enums, ja_message),
    zh: Object.assign(zh, zh_menu, zh_label, zh_enums, zh_message),
  },
  silentTranslationWarn: true,
});

export function translateError(error = '') {
  if (error.startsWith('typeMismatch')) {
    error = 'error.invalid';
  }
  error = i18n.t(error.includes('.') ? error : `error.${error.toLowerCase()}`);
  return error;
}

export default i18n;
export const I18N_LABEL = 'label';
export const I18N_POPTIP = 'poptip';
