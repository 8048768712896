<template>
  <table class="n-tree-table">
    <colgroup>
      <col width="50px" />
      <col v-for="(item, index) in columns" :key="index" :width="item.width" />
    </colgroup>
    <thead>
      <th>
        <i class="c-extend-icon fas" :class="hasExtended ? 'fa-minus' : 'fa-plus'" @click="onToggleAllExtended()" />
      </th>
      <template v-for="(item, index) in columns">
        <th :key="index" v-bind="item.attrs">
          <template v-if="item.titleSlot">
            <slot :name="item.titleSlot" :colume="item" :index="index" />
          </template>
          <template v-else>
            {{ $t(`label.${item.title}`) }}
          </template>
        </th>
      </template>
    </thead>
    <n-draggable v-model="mRecords" :disabled="disabled" draggable=".c-parent-tr" @change="doSort">
      <template v-for="(row, rowIndex) in mRecords">
        <tr :key="'row' + row.id" class="c-parent-tr" :data-sortable="!disabled">
          <td :colspan="columns.length + 1">
            <table style="width: 100%">
              <colgroup>
                <col width="50px" />
                <col v-for="(item, index) in columns" :key="index" :width="item.width" />
              </colgroup>
              <tr>
                <td>
                  <i
                    class="c-extend-icon fas"
                    :class="row.extendSubs.length > 0 ? 'fa-angle-down' : 'fa-angle-right'"
                    @click="onToggleMenu(row)"
                  />
                </td>
                <td v-for="(col, colIndex) in columns" :key="'col' + colIndex">
                  <template v-if="col.contentSlot">
                    <slot :name="col.contentSlot" :row="row" :text="get(row, col.dataIndex)" :index="rowIndex" />
                  </template>
                  <template v-else>
                    {{ get(row, col.dataIndex) }}
                  </template>
                </td>
              </tr>
            </table>

            <table style="width: 100%">
              <colgroup>
                <col width="50px" />
                <col v-for="(item, index) in columns" :key="index" :width="item.width" />
              </colgroup>
              <n-draggable v-model="row.extendSubs" :disabled="disabled" draggable=".sub-tr" @change="doSort('sub', row)">
                <tr
                  v-for="(subMenu, subMenuIndex) in row.extendSubs"
                  :key="'sub-' + subMenu.id"
                  class="sub-tr"
                  :data-sortable="!disabled"
                >
                  <td></td>
                  <td v-for="(col, colIndex) in columns" :key="'col' + colIndex">
                    <template v-if="col.subContentSlot">
                      <slot
                        :name="col.subContentSlot"
                        :row="subMenu"
                        :parent="row"
                        :text="get(subMenu, col.dataIndex)"
                        :index="subMenuIndex"
                      />
                    </template>
                    <template v-else>
                      {{ get(subMenu, col.dataIndex) | hyphen }}
                    </template>
                  </td>
                </tr>
              </n-draggable>
            </table>
          </td>
        </tr>
      </template>
    </n-draggable>
  </table>
</template>

<script>
import _ from 'lodash-es';

export default {
  name: 'NTreeTable',

  props: {
    columns: { type: Array, default: () => [] },
    records: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: true },
  },

  data() {
    return {
      get: _.get,
      mRecords: [], // mRecords may be modified, so defined here
    };
  },

  computed: {
    hasExtended() {
      return this.mRecords.find(o => o.extendSubs.length > 0);
    },
  },

  watch: {
    records: {
      handler(to, from) {
        this.mRecords = _.map(to, o => {
          return { ...o, extendSubs: [] };
        });
      },
      immediate: true,
    },
  },

  methods: {
    onToggleMenu(row) {
      row.extendSubs = row.extendSubs.length > 0 ? [] : row.subs;
    },

    onToggleAllExtended() {
      const hasExtended = this.hasExtended;
      this.mRecords.forEach(o => {
        o.extendSubs = hasExtended ? [] : o.subs;
      });
    },

    doSort(level, row) {
      // Ken: extendSubs will not equals subs after sorting. (v-model emits new value)
      if (level === 'sub') row.subs = row.extendSubs;
      this.$emit('sort', this.mRecords);
    },
  },
};
</script>

<style lang="less">
.n-tree-table {
  width: 100%;
  text-align: center;

  thead {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    background-color: rgb(238, 238, 238);
  }

  th {
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 100;
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: 0 -1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
  }

  .c-parent-tr {
    border-top: 1px solid #dee2e6;
    background-color: #f8f8f9;

    & table:first-of-type {
      position: sticky;
      top: 35px;
      background-color: #f8f8f9;
      z-index: 90;
    }
  }

  .c-extend-icon {
    padding: 10px;
    cursor: pointer;
  }

  .sub-tr {
    border-top: 1px solid #dee2e6;
    background-color: white;

    & > td {
      padding: 5px 0;
    }
  }

  [data-sortable='true'] {
    cursor: move;
  }
}
</style>
