<template>
  <div class="n-col-sort" @click="doSort(defaultIcon ? 'asc' : ascIcon ? 'desc' : 'asc')">
    <span class="mr-1">{{ $t(label ? label : field) }}</span>
    <div :class="iconClass">
      <Row>
        <i v-show="defaultIcon || ascIcon" class="fas fa-caret-up" />
      </Row>
      <Row>
        <i v-show="defaultIcon || descIcon" class="fas fa-caret-down" />
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NColSort',

  props: {
    value: Object,
    field: String,
    label: String,
  },

  data() {
    return {
      n_type: this.value.type ? this.value.type : 'default',
    };
  },

  computed: {
    defaultIcon() {
      return this.value.field !== this.field || this.n_type === 'default';
    },

    ascIcon() {
      return this.value.field === this.field && this.n_type === 'asc';
    },

    descIcon() {
      return this.value.field === this.field && this.n_type === 'desc';
    },

    iconClass() {
      return this.defaultIcon || (this.ascIcon && this.descIcon) ? 'all' : this.ascIcon ? 'asc' : 'desc';
    },
  },

  methods: {
    doSort(type) {
      this.n_type = type;
      const v = { field: this.field, type };
      this.$emit('input', v);
      this.$emit('change', v);
    },
  },
};
</script>

<style lang="less">
.n-col-sort {
  cursor: pointer;
  user-select: none;

  & > div {
    display: inline-block;
    vertical-align: sub;
    opacity: 0.5;

    &.asc {
      vertical-align: middle;
    }

    .ivu-row {
      margin: 0;
      padding: 0;
      height: 6px;
      line-height: 6px;

      & > i {
        height: 6px;
        line-height: 6px;
      }
    }
  }

  &:hover {
    & > div {
      opacity: 0.8;
    }
  }
}
</style>
