<template>
  <div class="layout">
    <Layout>
      <index-header></index-header>
      <Layout v-if="!passwordExpired" class="admin-center-layout ivu-layout-has-sider">
        <index-menu :menus="sideMenus"></index-menu>
        <Layout class="content-layout">
          <index-tabs />
          <keep-alive :include="cacheList">
            <router-view />
          </keep-alive>
        </Layout>
      </Layout>
    </Layout>
    <n-panel-box />
  </div>
</template>

<script>
import _ from 'lodash-es';
import store from '@/store';
import { realtime } from '@nextop/admin-utils';
import NoticeMixin from '@/mixins/notice-mixin';
import '@/components/common/index/index-content';
import { mapGetters, mapState, mapMutations } from 'vuex';
import IndexMenu from '@/components/common/index/index-menu';
import IndexTabs from '@/components/common/index/index-tabs';
import { WindowManager } from '@/services';
import { bootPromise, resetBoot } from '@/api/utility/utility';
import IndexHeader from '@/components/common/index/index-header';
import { destroyModal, MODAL_LEVEL } from '@/helpers/modal';
import { getPrivilege } from '@/store/module/menu';
import { routerReplace } from '@/router';

export default {
  components: { IndexHeader, IndexMenu, IndexTabs },
  mixins: [NoticeMixin],

  computed: {
    ...mapGetters({
      sideMenus: 'menu/menus',
      activeRoute: 'access/activeRoute',
      cacheList: 'access/cacheList',
    }),
    ...mapState('access', ['tabs']),
    ...mapState('profile', ['passwordExpired']),
  },

  beforeRouteEnter(to, from, next) {
    bootPromise()
      .then(async () => {
        store
          .dispatch('access/checkPrivilege', { to, from })
          .then(toUrl => {
            store.dispatch('profile/checkPassword').then(expired => {
              if (expired) {
                next();
                store.dispatch('profile/showPasswordModal');
              } else {
                if (toUrl) {
                  next(toUrl);
                } else {
                  realtime.connect();
                  store.commit('access/onRouterChange', to);
                  next();
                }
              }
            });
          })
          .catch(() => {});
      })
      .catch((error = {}) => {
        if (error.code === 'ECONNABORTED') return;
        next(false);
        routerReplace('/login');
      });
  },

  async beforeRouteUpdate(to, from, next) {
    await store
      .dispatch('access/checkPrivilege', { to, from })
      .then(toUrl => {
        if (toUrl) {
          next(toUrl);
        } else {
          this.onRouterChange(to);
          next();
        }
      })
      .catch(() => {});
  },

  created() {
    this.resizeHandler = _.debounce(this.resize.bind(this), 150);
    window.addEventListener('resize', this.resizeHandler);
  },

  destroyed() {
    destroyModal(MODAL_LEVEL.INDEX);
    this.$Notice.destroy();
    this.$Message.destroy();
    resetBoot();
    window.removeEventListener('resize', this.resizeHandler);
    WindowManager.destroy();
    realtime.disconnect();
    this.$Sentry?.close();
  },

  methods: {
    ...mapMutations('layout', ['resize']),
    ...mapMutations('access', ['onRouterChange']),
    ...mapMutations({ createPanel: 'panel/create' }),
  },
};
</script>
