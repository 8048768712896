<template>
  <!-- prettier-ignore -->
  <FormItem :error="error" :prop="fieldName">
    <span v-if="label !== undefined" slot="label" class="n-field-label">{{ $t(`label.${label}`) }}</span>
    <span v-if="customLabel !== undefined" slot="label">{{ customLabel }}</span>
    <div :class="modifiedIcon ? 'n-input-item-icon' : 'n-input-item'">
      <vue-editor v-model="n_value" :editor-toolbar="editorToolbar" :placeholder="placeholder" :disabled="disabled" :style="{ border: hasError ? '1px solid #ed4014' : '', cursor: disabled ? 'no-drop' : '', color: disabled ? '#666' : '', opacity: disabled ? '0.7' : '', height: `${height}px` }" @text-change="textChange" />
    </div>
    <Tooltip v-if="isModified" placement="right-start" :max-width="500" :content="initValue" transfer class="ml-2">
      <Icon custom="fas fa-pen" size="14" style="cursor: pointer;" />
    </Tooltip>
  </FormItem>
</template>

<script>
import Base from '../base';
import { VueEditor } from 'vue2-editor';
import { isEmpty } from '@/helpers/utils';

export default Base.extend({
  name: 'NFieldEditor',
  components: { VueEditor },
  props: {
    value: String,
    height: {
      type: Number,
      default: 324,
    },
  },
  data() {
    return {
      n_value: null,
      isInit: false,
      editorToolbar: [
        ['bold', 'italic', 'underline', 'clean'],
        ['strike', { script: 'super' }, { script: 'sub' }],
        ['link', 'blockquote', 'code-block'],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ color: [] }, { background: [] }],
      ],
    };
  },
  computed: {
    hasError() {
      return !isEmpty(this.error);
    },
    isModified() {
      return this.showModifyIcon && this.initValue !== this.value;
    },
  },
  watch: {
    value(to) {
      this.n_value = to;
    },
  },
  created() {
    this.isInit = true;
    this.initValue = this.value;
    this.n_value = this.value;
  },
  methods: {
    textChange(delta) {
      if (this.isInit) {
        this.isInit = false;
        return;
      }
      if (!isEmpty(this.n_value)) this.n_value = this.n_value.replace(/<p><br><\/p>/g, '<br>');
      this.$emit('input', this.n_value);
      this.$emit('on-change', this.n_value);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vue2-editor/dist/vue2-editor.css';
@import '../base';
@import 'style';
</style>
