import i18n from '@/locale/i18n';
import { $request } from '@/services/axios';

export default {
  model: {},
  error: {},
  rules: {
    loginId: [{ required: true, message: i18n.t('validation.required') }],
    password: [{ required: true, message: i18n.t('validation.required') }],
  },
};

export function login(loginId, password) {
  return $request.post('/utility/login', { loginId, password });
}
