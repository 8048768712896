<template>
  <n-field-selection
    v-model="n_value"
    selection-name="company.company"
    field-name="companyId"
    label="company"
    :error="error"
    :clearable="clearable"
    :all-option="allOption"
    :disabled="n_disabled"
    :no-modified-icon="noModifiedIcon"
    @on-change="onChange"
    @on-select="
      v => {
        this.$emit('on-select', v);
      }
    "
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getSelection } from '@/store';
import _ from 'lodash-es';
import { isEmpty } from '@/helpers/utils';

export default {
  name: 'NsCompany',

  inject: {
    nModal: { from: 'nModal', default: null },
  },

  props: {
    value: Number,
    clearable: Boolean,
    allOption: Boolean,
    error: String,
    notModal: Boolean,
    disabled: Boolean,
    noModifiedIcon: Boolean,
  },

  data() {
    return {
      n_value: this.value,
      n_disabled: this.disabled,
    };
  },

  computed: {
    ...mapGetters('profile', ['isManager']),

    ...mapState('profile', ['companyId']),

    firstCompanyId() {
      return getSelection('company.company')[0].value;
    },

    isCreate() {
      return _.get(this.nModal, 'isCreate', false);
    },

    isEdit() {
      return _.get(this.nModal, 'isEdit', false);
    },

    isInModal() {
      return !!this.nModal && !this.notModal;
    },
  },

  watch: {
    value(to) {
      if (to !== this.n_value) this.initValue();
    },

    disabled() {
      this.initDisabled();
    },
  },

  created() {
    this.initValue();
    this.initDisabled();
  },

  methods: {
    initValue() {
      if (this.isInModal && this.isCreate) this.n_value = this.isManager ? this.companyId : this.value;
      else if (this.isInModal && this.isEdit) this.n_value = this.value;
      else {
        if (this.isManager) this.n_value = this.companyId;
        else if (this.allOption) this.n_value = isEmpty(this.value) ? 0 : this.value;
        else this.n_value = isEmpty(this.value) || this.value === 0 ? this.firstCompanyId : this.value;
      }
      if (this.n_value !== this.value) this.$emit('input', this.n_value);
    },

    initDisabled() {
      this.n_disabled = this.isManager ? true : this.disabled;
    },

    onChange(value) {
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
  },
};
</script>
