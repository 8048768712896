<template>
  <div class="n-panel-minimized-window">
    <Row type="flex" style="align-items: center;">
      <Col style="flex: 1">
        <slot></slot>
      </Col>
      <Col>
        <Row type="flex" :gutter="4">
          <Col>
            <div class="n-panel-menu-action" @click="onWindowShow">
              <Icon type="md-share-alt" />
            </div>
          </Col>
          <Col>
            <div class="n-panel-menu-action" @click.stop="onWindowClose">
              <Icon type="md-close" />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'NPanelMinimizedWindow',

  props: {
    id: String,
  },

  methods: {
    ...mapMutations('panel', ['setAttrs', 'moveToTop', 'close']),

    onWindowShow() {
      this.setAttrs({ id: this.id, minimized: false });
      this.moveToTop(this.id);
    },

    onWindowClose() {
      this.close(this.id);
      this.$emit('on-window-close', this.id);
    },
  },
};
</script>

<style scoped lang="scss">
.n-panel-minimized-window {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 200px;
  height: 25px;
  border: 1px solid #aaa;
  border-bottom: 0;
  background-color: #f4f3f3;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  margin-right: 0.1rem;
  padding: 0 0.3rem;
}
</style>
