<template>
  <n-field-select v-bind="attrs" v-on="listeners" />
</template>

<script>
import BaseCompany from './BaseCompany';
import { getSelection } from '@/store';
import { filter } from 'lodash-es';

const SELECTION_NAME = 'company.pp';

export default BaseCompany.extend({
  name: 'NsPp',
  computed: {
    attrs() {
      return {
        label: 'pp',
        options: this.options,
        ...this.$attrs,
      };
    },
    options() {
      return filter(getSelection(SELECTION_NAME), item => item.extra.$group === this.companyId);
    },
  },
});
</script>
