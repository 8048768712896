import Vue from 'vue';

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'info',
    },
    size: {
      type: String,
      default: 'default',
    },
    icon: String,
    customIcon: String,
    text: String,
    disabled: Boolean,
    ghost: Boolean,
  },
  data() {
    return {
      n_size: 'small',
    };
  },
  computed: {
    buttonClass() {
      return this.ghost ? `n-button-ghost-${this.type}` : '';
    },
  },
  watch: {
    size() {
      this.initSize();
    },
  },
  mounted() {
    this.initSize();
  },
  methods: {
    initSize() {
      if (this.size === 'default') this.n_size = 'small';
      else if (this.size === 'large') this.n_size = 'default';
      else this.n_size = 'large';
    },
  },
});
