import { forEach } from 'lodash-es';
import {
  ignoredMessageHandler,
  stringErrorHandler,
  jsErrorHandler,
  messageHandler,
  sentryMessageHandler,
  navigatorHandler,
  validationErrorHandler,
  defaultHandler,
} from './handlers';

/**
 * Ken 2021-10-28 12:48
 * handler design:
 * return: boolean.  true: run next handler; false: stop iterator
 * */
const defaultHandlers = [
  ignoredMessageHandler,
  stringErrorHandler,
  jsErrorHandler,
  messageHandler,
  sentryMessageHandler,
  navigatorHandler,
  validationErrorHandler,
  defaultHandler,
];

export function errorHandler(err, vm, info) {
  forEach(defaultHandlers, handler => handler(err, vm, info));
}
