import _, { get, filter, startsWith } from 'lodash-es';
import router from '@/router';

const state = {
  data: [],
};
const getters = {
  menus(state, getters) {
    let menus = [];
    let registered = getRegisteredRoute();

    state.data.forEach(m => {
      let subs = [];
      m.subs.forEach(s => {
        const route = registered.find(router => get(router, 'meta.name') === s.name);
        if (route) {
          let sub = objectify(s);
          sub.active = false;
          // Ken 2019-10-29 21:54 忽略server端的url属性, 完全按vue router定义. [与家正讨论过]
          sub.url = route.path;
          sub.parent = m.name;
          sub.modal = get(route, 'meta.modal');
          if (sub.modal) {
            sub.component = route.component;
          }
          sub.meta = route.meta;
          sub.tabName = get(route, 'meta.tabName', s.name);
          subs.push(sub);
        }
      });

      // Ken 2019-10-27 16:00 当有二级菜单的时候, 才显示一级菜单
      if (subs.length > 0) {
        let p = objectify(m);
        p.subs = subs;
        p.icon = m.icon;
        menus.push(p);
      }
    });

    if (process.env.NODE_ENV === 'development') {
      menus.push(lab());
    }

    return menus;
  },
  hasOperatorNoticeReadPrivilege(state, getters) {
    return hasReadPrivilege('operator.operator_notice');
  },
};
const mutations = {
  setup(state, value) {
    state.data = value;
    // Ken 2019-12-24 22:28 重新登陆->bootstrap->here: 清空缓存, 以免有不同权限的人在一个网页上使用而导致的权限混乱问题
    PRIVILEGE_CACHE = {};
  },
};

function objectify(menu) {
  return {
    name: menu.name,
    // url: startsWith(menu.url, '#') ? menu.url.slice(1) : menu.url,
    readable: menu.readable,
    writable: menu.writable,
    readChecked: menu.readChecked,
    writeChecked: menu.writeChecked,
    readPrivilege: menu.readPrivilege,
    writePrivilege: menu.writePrivilege,
  };
}

function getRegisteredRoute() {
  let index = router.options.routes.find(r => r.name === 'index');
  return filter(index.children, r => !get(r, 'menu.submenu'));
}

// Ken 2019-12-12 15:40 给定menu name, 可以查读写权限
let PRIVILEGE_CACHE = {};
export function getPrivilege(menuName) {
  if (PRIVILEGE_CACHE[menuName]) {
    return PRIVILEGE_CACHE[menuName];
  }
  let ret = {};
  _.forEach(state.data, menu => {
    _.forEach(menu.subs, subMenu => {
      if (subMenu.name === menuName) {
        PRIVILEGE_CACHE[menuName] = {
          readable: subMenu.readable,
          writable: subMenu.writable,
          hasRO: subMenu.readable,
          hasRW: subMenu.readable && subMenu.writable,
        };
        ret = PRIVILEGE_CACHE[menuName];
        return false;
      }
    });
    return _.isEmpty(ret);
  });
  return ret;
}

export function hasReadPrivilege(menuName) {
  const privilege = getPrivilege(menuName);
  return !!_.get(privilege, 'hasRO');
}

export function hasWritePrivilege(menuName) {
  const privilege = getPrivilege(menuName);
  return !!_.get(privilege, 'hasRW');
}

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};

function lab() {
  return {
    icon: 'fas fa-flask',
    name: 'lab',
    readable: true,
    writable: true,
    readChecked: true,
    writeChecked: true,
    readPrivilege: 0,
    writePrivilege: 0,
    subs: [
      {
        name: 'lab.document',
        url: '/lab/document',
        parent: 'lab',
        active: false,
        readable: true,
        writable: true,
        readChecked: true,
        writeChecked: true,
        readPrivilege: 0,
        writePrivilege: 0,
      },
      {
        name: 'lab.example',
        url: '/lab/example',
        parent: 'lab',
        active: false,
        readable: true,
        writable: true,
        readChecked: true,
        writeChecked: true,
        readPrivilege: 0,
        writePrivilege: 0,
      },
    ],
  };
}
