<template>
  <div ref="container">
    <draggable
      v-model="tabs"
      tag="div"
      v-bind="dragOptions"
      class="n-menu-tabs n-no-select"
      @start="dragging = true"
      @end="dragging = false"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="tab.name"
        class="n-menu-tab"
        :class="{ active: tab.name === activeRoute.name }"
        @click="onClickTab(tab)"
        @mousedown="onMousedown($event, index)"
        @contextmenu.prevent="onContextMenu($event, index)"
      >
        <div class="n-menu-tab-title">{{ $t(`menu.${tab.name}`) }}</div>
        <Icon v-if="showCloseIcon" type="md-close" class="n-menu-tab-close" @click.stop="close(index)" />
      </div>
    </draggable>

    <n-context-menu :context-event="contextEvent" style="width: 175px;" @click="handleContextMenu">
      <DropdownItem name="close" :disabled="disableMenuItem">
        {{ $t('label.close') }}
      </DropdownItem>
      <DropdownItem name="closeOthers" :disabled="disableMenuItem">
        {{ $t('label.closeOthers') }}
      </DropdownItem>
      <DropdownItem name="closeToRight" :disabled="disableCloseToRight">
        {{ $t('label.closeToRight') }}
      </DropdownItem>
      <!-- <DropdownItem name="closeTabsRight">Close Tabs to the Right</DropdownItem> -->
    </n-context-menu>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapGetters, mapMutations } from 'vuex';
import elementResizeDetectorMaker from 'element-resize-detector';

export default {
  components: { draggable },

  data() {
    return {
      dragging: false,
      targetTabIndex: 0,
      contextEvent: {},
      dragOptions: {
        draggable: '.n-menu-tab',
        group: 'description',
        disabled: false,
      },
      lastHeight: 0,
    };
  },

  computed: {
    ...mapGetters('access', ['activeRoute', 'cacheList']),
    tabs: {
      get() {
        return this.$store.state.access.tabs;
      },

      set(tabs) {
        this.$store.commit('access/setTabs', tabs);
      },
    },
    showCloseIcon() {
      return this.tabs.length > 1;
    },
    disableMenuItem() {
      return this.tabs.length < 2;
    },
    disableCloseToRight() {
      return this.targetTabIndex === this.tabs.length - 1;
    },
  },

  mounted() {
    this.observer = elementResizeDetectorMaker();
    this.observer.listenTo(this.$refs.container, this.handleResize);
  },

  beforeDestroy() {
    this.observer.uninstall(this.$refs.container);
  },

  methods: {
    ...mapMutations('access', ['close', 'closeOthers', 'closeToRight', 'pushMenu']),
    ...mapMutations('layout', ['setTabsHeight']),
    onContextMenu(event, index) {
      this.targetTabIndex = index;
      this.contextEvent = event;
    },

    handleContextMenu(name) {
      this[name](this.targetTabIndex);
    },
    onClickTab(tab) {
      this.pushMenu({ url: tab.url });
    },
    onMousedown(event, index) {
      // Ken 2019-12-01 22:31 点击鼠标滚轮键, 关闭tab
      if (event.which === 2 && this.tabs.length > 1) {
        this.close(index);
      }
    },
    handleResize(element) {
      if (element.offsetHeight !== this.lastHeight) {
        this.setTabsHeight(element.offsetHeight);
        this.lastHeight = element.offsetHeight;
      }
    },
  },
};
</script>
<style lang="scss">
@import 'style';
</style>
