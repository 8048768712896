<template>
  <Header class="admin-header">
    <Menu mode="horizontal" theme="light" active-name="1">
      <Row type="flex" :class="{ 'nav-prod': isProd }">
        <Col class="nav-brand">
          <div class="nav-brand-name">
            MyExpense Admin
          </div>
          <div class="nav-brand-build-id">
            <div>[ {{ environment }} ] build: {{ X_VERSION }}</div>
          </div>
        </Col>
        <Col class="nav-actions">
          <div class="fas fa-bars nav-action nav-action-side-menu n-mobile-hide" @click="onClickSideMenu"></div>
          <div class="fas fa-coffee nav-action nav-action-side-menu" @click="onClickResetTabs"></div>
          <!-- prettier-ignore -->
          <div v-if="hasOperatorNoticeReadPrivilege" class="fas fa-envelope nav-action nav-action-notice" @click="onClickNotice"></div>
          <div class="fas fa-cog nav-action nav-action-setting" @click="onClickSetting"></div>
          <ws-indicator />
          <!--          <div class="nav-action nav-action-lang">-->
          <!--            <language-dropdown />-->
          <!--          </div>-->
          <div class="nav-action nav-action-user">
            <user-info-dropdown />
          </div>
        </Col>
      </Row>
    </Menu>
  </Header>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import ModalSetting from './ModalSetting';
import UserInfoDropdown from './UserInfoDropDown';
import WsIndicator from './WsIndicator';
import { createModal } from '@/helpers/modal';

export default {
  name: 'IndexHeader',

  components: {
    UserInfoDropdown,
    WsIndicator,
  },

  props: {},

  data() {
    return {
      settingDlgVisible: false,
      X_VERSION: window.X_VERSION,
      contextEvent: {},
    };
  },

  computed: {
    ...mapState('config', ['environment']),
    ...mapGetters('config', ['isProd']),
    ...mapGetters('menu', ['hasOperatorNoticeReadPrivilege']),
  },

  methods: {
    ...mapMutations('access', ['pushMenu', 'reset']),
    onClickSideMenu() {
      this.$eventBus.$emit('side-menu-collapse');
    },
    onClickResetTabs() {
      this.reset();
    },
    onClickNotice() {
      this.pushMenu({ url: '/operator/operator/notice' });
    },
    onClickSetting() {
      createModal(ModalSetting, { props: { escapable: true, privilegeSensitive: false } });
    },
    onContextMenu(event) {
      this.contextEvent = event;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'style';
</style>
