import FingerprintJS from '@fingerprintjs/fingerprintjs';

export async function getFingerprint() {
  try {
    const promise = FingerprintJS.load();
    const fp = await promise;
    const result = await fp.get();
    return result;
  } catch (e) {}
}
