const state = {
  timezone: 9,
  environment: 'DEV',
  timezoneOffset: 32400000,
  imageServer: 'https://dev-balrog.nextop.cn/image',
  sentryServer: '',
};

const getters = {
  isProd: state => state.environment === 'PROD',
};

const mutations = {
  setup(state, data) {
    Object.getOwnPropertyNames(data).forEach(key => {
      if (!state.hasOwnProperty(key)) {
        console.error(`unknown config attribute: [${key}] (maybe added by backend)`);
      } else {
        state[key] = data[key];
      }
    });
    state.imageServer = state.imageServer?.replace(/\/?$/, '');
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
