<template>
  <div></div>
</template>

<script>
export default {
  props: {
    labelXs: {
      type: Object,
      default: () => {
        return {
          span: 8,
        };
      },
    },
    labelSm: {
      type: Object,
      default: () => {
        return {
          span: 3,
        };
      },
    },
    labelMd: {
      type: Object,
      default: () => {
        return {
          span: 3,
        };
      },
    },
    labelLg: {
      type: Object,
      default: () => {
        return {
          span: 3,
        };
      },
    },
    contentXs: {
      type: Object,
      default: () => {
        return {
          span: 16,
        };
      },
    },
    contentSm: {
      type: Object,
      default: () => {
        return {
          span: 5,
        };
      },
    },
    contentMd: {
      type: Object,
      default: () => {
        return {
          span: 5,
        };
      },
    },
    contentLg: {
      type: Object,
      default: () => {
        return {
          span: 3,
        };
      },
    },
  },
};
</script>
