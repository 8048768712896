<template>
  <span :class="{ 'n-link': hasRO }" :title="label" @click="onClick">{{ label }}</span>
</template>

<script>
import store, { getSelection } from '@/store';
import { hasReadPrivilege } from '@/store/module/menu';
import { RESULT } from '@/constant/enums';
import _ from 'lodash-es';
import { getProfile } from '@/api/customer/customer-profile';
import { getAccount } from '@/api/customer/customer-account';
import { one as getGroup } from '@/api/company/company-group';
import { createModal } from '@/helpers/modal';
import ModalCompanyGroup from '@/views/index/company/ModalCompanyGroup';
import ModalAccount from '@/views/index/customer/ModalAccount';
import ModalProfile from '@/views/index/customer/ModalProfile';

const MODAL_MAP = {
  'company.group': { menu: 'company.group', get: getGroup, name: ModalCompanyGroup, subName: 'group' },
  'customer.account': {
    menu: 'customer.account',
    get: params => {
      return getAccount(params.id);
    },
    name: ModalAccount,
    subName: 'account',
  },
  'customer.profile': {
    menu: 'customer.profile',
    get: params => {
      return getProfile(params.id);
    },
    name: ModalProfile,
    subName: 'profile',
  }
};

export default {
  name: 'NModalLink',
  props: {
    // menu.url, selection-name
    name: String,
    value: [String, Number],
    // 是否显示selection-name
    selection: { type: Boolean, default: false },
  },
  computed: {
    modal() {
      return MODAL_MAP[this.name];
    },
    label() {
      let label = this.value;
      if (this.selection) {
        label = _.get(getSelection(this.name, parseInt(this.value)), 'name', '-');
      }
      if (!this.modal) {
        label = '[WRONG NAME]';
      }
      // Ken 2019-12-29 00:07 sometimes, accountId = '0' [Dealing/Allocation]
      if (!label || label === '0') {
        label = '-';
      }
      return label;
    },
    hasRO() {
      const hasRO = this.modal ? hasReadPrivilege(this.modal.menu) : false;
      return hasRO && this.label !== '-';
    },
  },
  methods: {
    async onClick() {
      if (!this.hasRO) {
        return;
      }
      const model = await this.modal.get({ id: this.value });
      if (!this.isResponseError(model)) {
        createModal(this.modal.name, { model, props: { detail: true, subName: this.modal.subName } });
      }
    },
    isResponseError(res) {
      // response prototype
      if (_.get(res, 'status') === 200) {
        return _.get(res, 'data.type') !== RESULT.DATA;
      }
      return false;
    },
  },
};
</script>
