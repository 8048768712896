<!-- 暂时忽略此mixin，没有地方引用 by guoqiang -->
<script>
import { isEmpty } from '@/helpers/utils';
import _ from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
  name: 'TableMixin',
  computed: {
    ...mapGetters('layout', ['collapsed', 'innerWidth', 'innerHeight']),
  },
  watch: {
    collapsed() {
      this.handleDataTable();
    },
    innerWidth() {
      this.handleDataTable();
    },
    innerHeight() {
      this.handleDataTable();
    },
  },
  methods: {
    handleDataTable() {
      this.$nextTick(() => {
        let table = document.getElementsByClassName('n-table');
        if (isEmpty(table)) return;
        let thead = table[0].children[0];
        let tbody = table[0].children[1];
        if (tbody.children.length === 0) return;
        let contentEl = document.getElementsByClassName('p-content-card')[0];
        let tBodyHeight = contentEl.offsetHeight - 60 - thead.offsetHeight;

        /* tbody css */
        tbody.style.cssText = `display: block; width: ${thead.offsetWidth}px; height: ${tBodyHeight}px;`;

        /* append scrollbar th */
        let hasScrollbar = false;
        if (tbody.offsetHeight < tbody.scrollHeight) {
          hasScrollbar = true;
          let els = document.getElementsByClassName('scrollbar-placeholder');
          if (els.length > 0) {
            _.forEach(els, el => {
              el.parentNode.removeChild(el);
            });
          }
          let scrollbarTh = document.createElement('th');
          scrollbarTh.style.width = '3px';
          scrollbarTh.style.border = '0';
          scrollbarTh.className = 'scrollbar-placeholder';
          thead.firstChild.appendChild(scrollbarTh);
        }

        /* tbody td width */
        let theadThsWidth = [];
        let theadThs = [];
        if (thead.children.length === 2) {
          _.forEach(thead.children, (tr, index) => {
            _.forEach(tr.children, th => {
              if (index === 0 && th.rowSpan === 2 && th.className.indexOf('scrollbar-placeholder') === -1) {
                theadThsWidth.push(th.offsetWidth);
                theadThs.push(th);
              } else if (index === 1 && th.className.indexOf('scrollbar-placeholder') === -1) {
                theadThsWidth.push(th.offsetWidth);
                theadThs.push(th);
              }
            });
          });
        } else {
          _.forEach(thead.firstChild.children, th => {
            theadThsWidth.push(th.offsetWidth);
          });
        }
        let tbodyFirstTr = tbody.firstChild;
        _.forEach(tbodyFirstTr.children, (tbodyTd, index) => {
          let offsetWidth = theadThsWidth[index];
          tbodyTd.style.cssText = `width: ${offsetWidth}px !important;`;
        });
      });
    },
  },
};
</script>
